/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
import {
  Abc,
  CalendarMonth,
  Code,
  Dataset,
  FormatBold,
  FormatClear,
  FormatItalic,
  FormatStrikethrough,
  FormatUnderlined,
  Functions,
  LocationOn,
  Pin,
} from '@mui/icons-material';
import {
  Box,
  FormControl,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { blue, green, purple, red } from '@mui/material/colors';
import isHotkey from 'is-hotkey';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createEditor, Editor, Range, Transforms } from 'slate';
import { withHistory } from 'slate-history';
import {
  Editable,
  ReactEditor,
  Slate,
  useFocused,
  useSelected,
  useSlate,
  withReact,
} from 'slate-react';

const initialValue = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+s': 'strikethrough',
  'mod+u': 'underline',
  'mod+`': 'code',
};

const icons = {
  bold: <FormatBold></FormatBold>,
  strikethrough: <FormatStrikethrough></FormatStrikethrough>,
  italic: <FormatItalic></FormatItalic>,
  code: <Code></Code>,
  clear: <FormatClear></FormatClear>,
  underline: <FormatUnderlined></FormatUnderlined>,
};
const marksList = ['bold', 'italic', 'strikethrough', 'code', 'underline'];

const withfields = (editor) => {
  const { isInline, isVoid, markableVoid } = editor;

  editor.isInline = (element) =>
    element.type === 'function' ||
    element.type === 'field' ||
    isInline(element);

  editor.isVoid = (element) =>
    element.type === 'function' || element.type === 'field' || isVoid(element);

  editor.markableVoid = (element) =>
    element.type === 'function' ||
    element.type === 'field' ||
    markableVoid(element);

  return editor;
};

export default function MessageEditor({
  onChange,
  fields = [],
  initialMessage,
  aggregateFields,
  small = false,
  lines = 1,
}) {
  const editor = useMemo(
    () => withfields(withReact(withHistory(createEditor()))),
    []
  );
  const ref = useRef();
  const [target, setTarget] = useState();
  const [search, setSearch] = useState('');
  const [index, setIndex] = useState(0);
  const theme = useTheme();
  const renderElement = useCallback(
    (props) => <Element {...props} />,
    [JSON.stringify(fields)]
  );
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  };
  const toggleMark = (editor, format) => {
    if (small) {
      return;
    }
    const isActive = isMarkActive(editor, format);
    if (format === 'clear') {
      marksList.forEach((mark) => {
        Editor.removeMark(editor, mark);
      });
      return;
    }
    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  };

  const functions = ['SUM', 'FIRST', 'LAST', 'AVG', 'MIN', 'MAX', 'COUNT'];

  const dateTypes = ['YEAR_MONTH_DAY', 'YEAR_MONTH_DAY_SECOND'];
  const dateFormats = [
    'YYYY-MM-DD',
    'DD/MM/YYYY',
    'dddd D MMMM YYYY',
    'x',
    'YYYY-MM-DDThh:mm:ssz',
  ];

  const searchElems = aggregateFields
    ? functions.filter((c) => c.toLowerCase().startsWith(search))
    : fields.filter((c) => c.name.toLowerCase().startsWith(search));

  const Element = ({ attributes, children, element }) => {
    const selected = useSelected();
    const focused = useFocused();
    const style = { textAlign: element.align };
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectOpen, setSelectOpen] = useState(false);
    console.log(selectOpen);
    switch (element.type) {
      case 'function':
        return (
          <>
            <span
              contentEditable={false}
              style={{
                ...style,
                display: 'inline-flex',
                verticalAlign: 'middle',
              }}
              {...attributes}
            >
              <Paper
                sx={{
                  padding: '1px 4px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : null,
                  backgroundColor: theme.palette.background.default,
                  mr: '1px',
                  my: 1,
                  fontSize: '1rem',
                  userSelect: 'none',
                }}
              >
                <Functions
                  sx={{
                    borderRadius: `${theme.shape.borderRadius}px`,
                    fontSize: '1.2rem',
                  }}
                />
                {element.operation}
                <FormControl
                  sx={{
                    ml: 1,
                    p: 0,
                  }}
                  variant="standard"
                >
                  <Select
                    SelectDisplayProps={{
                      style: {
                        padding: 0,
                        paddingRight: '24px',
                        backgroundColor: 'transparent',
                      },
                    }}
                    label="Field"
                    onChange={(event) => {
                      const newField = fields.find(
                        (f) => f.id === event.target.value
                      );

                      Transforms.setNodes(
                        editor,
                        { field: newField },
                        { at: ReactEditor.findPath(editor, element) }
                      );
                      onChange(editor.children);
                    }}
                    sx={{
                      ':before, :after': {
                        borderBottom: 'none !important',
                      },
                    }}
                    value={element.field.id}
                  >
                    {fields.map((field) => (
                      <MenuItem key={field.id} value={field.id}>
                        <ListItem
                          sx={{
                            p: 0,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 'unset',
                              mr: 1,
                            }}
                          >
                            {field.type === 'NUMBER' ? (
                              <Pin
                                htmlColor={
                                  field.conceptType === 'DIMENSION'
                                    ? green[400]
                                    : field.conceptType === 'METRIC'
                                      ? blue[400]
                                      : purple[400]
                                }
                              />
                            ) : field.type === 'TEXT' ? (
                              <Abc
                                htmlColor={
                                  field.conceptType === 'DIMENSION'
                                    ? green[400]
                                    : field.conceptType === 'METRIC'
                                      ? blue[400]
                                      : purple[400]
                                }
                              />
                            ) : field.type === 'YEAR_MONTH_DAY' ? (
                              <CalendarMonth
                                htmlColor={
                                  field.conceptType === 'DIMENSION'
                                    ? green[400]
                                    : field.conceptType === 'METRIC'
                                      ? blue[400]
                                      : purple[400]
                                }
                              />
                            ) : field.type === 'LATITUDE_LONGITUDE' ? (
                              <LocationOn
                                htmlColor={
                                  field.conceptType === 'DIMENSION'
                                    ? green[400]
                                    : field.conceptType === 'METRIC'
                                      ? blue[400]
                                      : purple[400]
                                }
                              />
                            ) : (
                              <Dataset
                                htmlColor={
                                  field.conceptType === 'DIMENSION'
                                    ? green[400]
                                    : field.conceptType === 'METRIC'
                                      ? blue[400]
                                      : purple[400]
                                }
                              />
                            )}
                          </ListItemIcon>
                          <ListItemText>{field.name}</ListItemText>
                        </ListItem>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Paper>
              {children}
            </span>
          </>
        );
      case 'field':
        // eslint-disable-next-line no-case-declarations
        const { field } = element;
        // eslint-disable-next-line no-case-declarations
        const isValid = fields.find((f) => field.id === f.id);

        // eslint-disable-next-line no-case-declarations
        const isDate = dateTypes.includes(field.type, field);
        if (!isDate) {
          return (
            <>
              <span
                contentEditable={false}
                style={{
                  ...style,
                  display: 'inline-flex',
                  verticalAlign: 'middle',
                }}
                {...attributes}
              >
                <Box
                  sx={{
                    backgroundColor: !isValid
                      ? red[100]
                      : field.conceptType === 'DIMENSION'
                        ? green[100]
                        : field.conceptType === 'METRIC'
                          ? blue[100]
                          : purple[100],
                    display: 'inline-flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : null,
                    py: '4px',
                    px: 2,
                    my: 1,
                    borderRadius: `1000px`,
                    userSelect: 'none',
                  }}
                >
                  {field.type === 'NUMBER' ? (
                    <Pin />
                  ) : field.type === 'TEXT' ? (
                    <Abc />
                  ) : isDate ? (
                    <CalendarMonth />
                  ) : field.type === 'LATITUDE_LONGITUDE' ? (
                    <LocationOn />
                  ) : (
                    <Dataset />
                  )}

                  <Typography
                    sx={{
                      ml: 1,
                    }}
                  >
                    {field.name}
                  </Typography>
                </Box>
                {children}
              </span>
            </>
          );
        }
        return (
          <>
            <span
              contentEditable={false}
              style={{
                ...style,
                display: 'inline-flex',
                verticalAlign: 'middle',
              }}
              {...attributes}
            >
              <Paper
                sx={{
                  padding: '1px 4px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
                  // backgroundColor: theme.palette.background.default,
                  mr: '1px',
                  my: 1,
                  fontSize: '1rem',
                  userSelect: 'none',
                  backgroundColor:
                    field.conceptType === 'DIMENSION'
                      ? green[100]
                      : field.conceptType === 'METRIC'
                        ? blue[100]
                        : purple[100],
                }}
              >
                {field.type === 'NUMBER' ? (
                  <Pin />
                ) : field.type === 'TEXT' ? (
                  <Abc />
                ) : isDate ? (
                  <CalendarMonth />
                ) : field.type === 'LATITUDE_LONGITUDE' ? (
                  <LocationOn />
                ) : (
                  <Dataset />
                )}

                <Typography
                  sx={{
                    ml: 1,
                  }}
                >
                  {field.name}
                </Typography>
                <FormControl
                  sx={{
                    ml: 1,
                    p: 0,
                  }}
                  variant="standard"
                >
                  <Select
                    SelectDisplayProps={{
                      style: {
                        padding: 0,
                        paddingRight: '24px',
                        backgroundColor: 'transparent',
                      },
                    }}
                    label="Field"
                    onChange={(event) => {
                      const format = event.target.value;
                      Transforms.setNodes(
                        editor,
                        { format, value: element?.value },
                        { at: ReactEditor.findPath(editor, element) }
                      );
                      onChange(editor.children);
                    }}
                    onClick={() => {
                      setSelectOpen(true);
                      console.log('open');
                    }}
                    onClose={(event) => {
                      console.log(event);
                      event.stopPropagation();
                      event.preventDefault();

                      setSelectOpen(false);
                    }}
                    open={selectOpen || anchorEl !== null}
                    sx={{
                      ':before, :after': {
                        borderBottom: 'none !important',
                      },
                    }}
                    value={element.format ?? dateFormats[0]}
                  >
                    {dateFormats.map((format) => (
                      <MenuItem key={format} value={format}>
                        <ListItem
                          sx={{
                            p: 0,
                          }}
                        >
                          <ListItemText>{format}</ListItemText>
                        </ListItem>
                      </MenuItem>
                    ))}
                    <MenuItem
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setAnchorEl(event.currentTarget);
                      }}
                      value="CUSTOM"
                    >
                      <ListItem
                        sx={{
                          p: 0,
                        }}
                      >
                        <ListItemText>
                          {element?.value
                            ? `Custom: ${element.value}`
                            : 'Custom'}
                        </ListItemText>
                      </ListItem>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Paper>
              {children}
            </span>
            <Menu
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              open={anchorEl !== null}
            >
              <TextField
                onChange={(event) => {
                  const newField = event.target.value;
                  Transforms.setNodes(
                    editor,
                    { value: newField },
                    { at: ReactEditor.findPath(editor, element) }
                  );
                  onChange(editor.children);
                }}
                placeholder="YYYY-MM-DD"
                sx={{
                  p: 1,
                }}
                value={element?.value}
              ></TextField>
            </Menu>
          </>
        );

      default:
        return (
          <p
            style={{
              ...style,
              margin: 0,
            }}
            {...attributes}
          >
            {children}
          </p>
        );
    }
  };
  const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
      children = <strong>{children}</strong>;
    }

    if (leaf.code) {
      children = <code>{children}</code>;
    }

    if (leaf.italic) {
      children = <em>{children}</em>;
    }

    if (leaf.strikethrough) {
      children = <s>{children}</s>;
    }

    if (leaf.underline) {
      children = <u>{children}</u>;
    }

    return <span {...attributes}>{children}</span>;
  };

  const MarkButton = ({ format }) => {
    useSlate(); // required for the editor to re-render on mark change
    return (
      <IconButton
        color={isMarkActive(editor, format) ? 'secondary' : 'default'}
        onMouseDown={(event) => {
          event.preventDefault();
          toggleMark(editor, format);
        }}
      >
        {icons[format]}
      </IconButton>
    );
  };

  const instertFunction = (editor, operation) => {
    const f = [
      {
        type: 'function',
        operation,
        field: fields[0],
        children: [{ text: '' }],
      },
    ];
    Transforms.insertNodes(editor, f);
    Transforms.move(editor);
  };

  const insertField = (editor, field) => {
    const f = [
      {
        type: 'field',
        field,
        children: [{ text: '' }],
      },
    ];
    Transforms.insertNodes(editor, f);
    Transforms.move(editor);
  };

  useEffect(() => {
    if (target && searchElems.length > 0) {
      const el = ref.current;
      try {
        const domRange = ReactEditor.toDOMRange(editor, target);
        const rect = domRange.getBoundingClientRect();
        const { height, width } = el.getBoundingClientRect();

        el.style.top = `${Math.min(
          rect.top + 24,
          window.innerHeight - height - 10
        )}px`;
        el.style.left = `${Math.min(
          rect.left,
          window.innerWidth - width - 10
        )}px`;
      } catch (e) {
        console.log(e);
      }
    }
  }, [searchElems.length, editor, target, index, search]);

  useEffect(() => {
    try {
      if (!initialMessage || initialMessage === JSON.stringify(editor.children))
        return;
      const value = JSON.parse(initialMessage) ?? [
        {
          type: 'paragraph',
          children: [{ text: '' }],
        },
      ];

      const point = { path: [0, 0], offset: 0 };
      editor.selection = { anchor: point, focus: point };
      editor.history = { redos: [], undos: [] };
      editor.children = [
        {
          type: 'paragraph',
          children: [{ text: '' }],
        },
      ];
      Transforms.removeNodes(editor, {
        at: [0],
      });

      // Insert array of children nodes
      Transforms.insertNodes(editor, value);
    } catch (e) {
      console.log(e);
      // pass
    }
  }, [initialMessage]);

  return (
    <Box
      sx={{
        py: 1,
      }}
    >
      <Slate
        editor={editor}
        initialValue={
          Array.isArray(initialMessage) ? initialMessage : initialValue
        }
        onChange={() => {
          const { selection } = editor;

          if (selection && Range.isCollapsed(selection)) {
            const [start] = Range.edges(selection);
            const wordBefore = Editor.before(editor, start, { unit: 'word' });
            const characterBefore = Editor.before(editor, start, {
              unit: 'character',
            });

            const before = wordBefore && Editor.before(editor, wordBefore);
            const characterBeforeRange =
              characterBefore && Editor.range(editor, characterBefore, start);

            let beforeRange = before && Editor.range(editor, before, start);
            const beforeText =
              beforeRange && Editor.string(editor, beforeRange);
            const characterBeforeText =
              characterBeforeRange &&
              Editor.string(editor, characterBeforeRange);
            const beforeMatch = beforeText && beforeText.match(/^[/](\w*)$/);

            if (beforeMatch === null) {
              beforeRange = characterBeforeRange;
            }
            const after = Editor.after(editor, start);
            const afterRange = Editor.range(editor, start, after);
            const afterText = Editor.string(editor, afterRange);
            const afterMatch = afterText.match(/^(\s|$)/);

            if ((beforeMatch || characterBeforeText === '/') && afterMatch) {
              setTarget(
                Editor.range(
                  editor,
                  Editor.before(editor, Range.start(selection), {
                    distance: 1 + (beforeMatch?.[1] ?? '').length,
                  }),
                  Range.end(selection)
                )
              );
              setSearch(beforeMatch?.[1] ?? '');
              setIndex(0);
              return;
            }
            setTarget(null);
          }
        }}
      >
        {!small && (
          <Box>
            <MarkButton format="bold" />
            <MarkButton format="italic" />
            <MarkButton format="underline" />
            <MarkButton format="strikethrough" />
            <MarkButton format="code" />
            <MarkButton format="clear" />
          </Box>
        )}
        <Editable
          onBlur={() => {
            onChange(editor.children);
          }}
          onFocus={() => {
            if (!editor.selection) {
              // workaround omdat de cursor verdwijnt op click
              Transforms.select(editor, {
                anchor: { path: [0, 0], offset: 0 },
                focus: { path: [0, 0], offset: 0 },
              });
            }
          }}
          onKeyDown={(event) => {
            // eslint-disable-next-line no-restricted-syntax
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event)) {
                event.preventDefault();
                const mark = HOTKEYS[hotkey];
                toggleMark(editor, mark);
              }
            }
            if (!target && event.key === 'Tab') {
              event.preventDefault();
              Editor.insertText(editor, '\u2003'.toString()); // insert tab
            }

            if (target && searchElems.length > 0) {
              // eslint-disable-next-line default-case
              switch (event.key) {
                case 'ArrowDown':
                  event.preventDefault();
                  // eslint-disable-next-line no-case-declarations
                  const prevIndex =
                    index >= searchElems.length - 1 ? index : index + 1;
                  ref.current.scrollBy(0, 26);
                  setIndex(prevIndex);
                  break;
                case 'ArrowUp':
                  event.preventDefault();
                  // eslint-disable-next-line no-case-declarations
                  const nextIndex = index <= 0 ? index : index - 1;
                  ref.current.scrollBy(0, -26);
                  setIndex(nextIndex);
                  break;
                case 'Tab':
                case 'Enter':
                  event.preventDefault();
                  Transforms.select(editor, target);
                  if (aggregateFields) {
                    instertFunction(editor, searchElems[index]);
                  } else {
                    insertField(editor, searchElems[index]);
                  }

                  setTarget(null);
                  break;
                case 'Escape':
                  event.preventDefault();
                  setTarget(null);
                  break;
              }
            }
          }}
          placeholder='Use "/" to reference a field'
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          renderPlaceholder={({ children, attributes }) => (
            <p
              {...attributes}
              style={{
                ...attributes.style,
                margin: 0,
                lineHeight: '2.5em',
                height: '100%',
              }}
            >
              {children}
            </p>
          )}
          style={{
            outline: `1px solid ${theme.palette.action.disabled}`,
            padding: `${theme.spacing(1)}`,
            borderRadius: `${theme.shape.borderRadius}px`,
            minHeight: `${lines * 2}em`, // close enough
          }}
        ></Editable>
        {target && searchElems.length > 0 && (
          <Box
            onClick={() => setTarget(null)}
            sx={{
              position: 'fixed',
              top: '0px',
              left: '0px',
              right: '0px',
              bottom: '0px',
              zIndex: 1300,
              background: 'transparent',
            }}
          >
            <div
              data-cy="fields-portal"
              ref={ref}
              style={{
                top: '-9999px',
                left: '-9999px',
                right: '10px',
                width: '200px',
                maxWidth: '200px',
                bottom: '10px',
                position: 'fixed',
                zIndex: 1301,
                padding: '3px',
                background: 'white',
                borderRadius: '4px',
                boxShadow: '0 1px 5px rgba(0,0,0,.2)',
                overflow: 'auto',
                maxHeight: '250px',
                scrollbarWidth: 'none',
                height: 'fit-content',
              }}
            >
              {searchElems.map((el, i) => (
                <div
                  key={el?.id ?? el}
                  onClick={() => {
                    Transforms.select(editor, target);

                    if (aggregateFields) {
                      instertFunction(editor, el);
                    } else {
                      insertField(editor, el);
                    }

                    setTarget(null);
                    ReactEditor.blur(editor);
                    onChange(editor.children);
                  }}
                  onMouseEnter={() => setIndex(i)}
                  style={{
                    padding: '1px 3px',
                    borderRadius: '3px',
                    background: i === index ? '#B4D5FF' : 'transparent',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                  }}
                >
                  {aggregateFields ? (
                    <Functions
                      sx={{
                        borderRadius: `${theme.shape.borderRadius}px`,
                        fontSize: '1.2rem',
                      }}
                    />
                  ) : el.type === 'NUMBER' ? (
                    <Pin
                      htmlColor={
                        el.conceptType === 'DIMENSION'
                          ? green[400]
                          : el.conceptType === 'METRIC'
                            ? blue[400]
                            : purple[400]
                      }
                    />
                  ) : el.type === 'TEXT' ? (
                    <Abc
                      htmlColor={
                        el.conceptType === 'DIMENSION'
                          ? green[400]
                          : el.conceptType === 'METRIC'
                            ? blue[400]
                            : purple[400]
                      }
                    />
                  ) : el.type === 'YEAR_MONTH_DAY' ? (
                    <CalendarMonth
                      htmlColor={
                        el.conceptType === 'DIMENSION'
                          ? green[400]
                          : el.conceptType === 'METRIC'
                            ? blue[400]
                            : purple[400]
                      }
                    />
                  ) : el.type === 'LATITUDE_LONGITUDE' ? (
                    <LocationOn
                      htmlColor={
                        el.conceptType === 'DIMENSION'
                          ? green[400]
                          : el.conceptType === 'METRIC'
                            ? blue[400]
                            : purple[400]
                      }
                    />
                  ) : (
                    <Dataset
                      htmlColor={
                        el.conceptType === 'DIMENSION'
                          ? green[400]
                          : el.conceptType === 'METRIC'
                            ? blue[400]
                            : purple[400]
                      }
                    />
                  )}
                  <div
                    style={{
                      textOverflow: 'ellipsis',
                      overflowX: 'hidden',
                      display: 'block',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {el?.name ?? el}
                  </div>
                </div>
              ))}
            </div>
          </Box>
        )}
      </Slate>
    </Box>
  );
}
