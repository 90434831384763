import {
  ArrowDropDown,
  AutoAwesome,
  Autorenew,
  BugReport,
  Check,
  DataObject,
  Error,
  InfoOutlined,
  Label,
  Language,
  PunchClock,
  QueryStats,
  Settings,
  Speed,
  StackedBarChart,
  SwapHoriz,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Fade,
  Icon,
  IconButton,
  List,
  ListItemIcon,
  Menu,
  MenuItem,
  Popover,
  Popper,
  Typography,
} from '@mui/material';
import { grey, red } from '@mui/material/colors';
import { alpha } from '@mui/material/styles';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import _find from 'lodash/find';
import PopupState, {
  bindMenu,
  bindPopover,
  bindTrigger,
} from 'material-ui-popup-state';
import { bindToggle, usePopupState } from 'material-ui-popup-state/hooks';
import querystring from 'querystring';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useSWRMutation from 'swr/mutation';
import updateDataSourceObject from '../../api/updateDataSourceObject';
import * as types from '../../constants/ActionTypes';
import * as dp from '../../constants/DataProviders';
import * as mutations from '../../graphql/mutations';

import { useTranslation } from 'react-i18next';
import useDataProviderData from '../../hooks/useDataProviderData';
import dataStudioLogo from '../../look-studio-white.svg';
import microsoftTeamsLogo from '../../microsoft-teams.svg';
import powerBiLogo from '../../power-bi-logo.png';
import avatarCredentialColors from '../../utilities/avatarCredentialColors';
import filterGraphQLFields from '../../utilities/filterGraphQLFields';
import getInitials from '../../utilities/getInitials';
import numberFromText from '../../utilities/numberFromText';
import ReactJoin from '../../utilities/react-join';
import DataSourceLabel from '../labels/DataSourceLabel';
import SelectLabel from '../labels/SelectLabel';
import CreateJobFeedDialog from './CreateJobFeedDialog';
import DataProviderChip from './DataProviderChip';
import DataSourceListItem from './DataSourceListItem';
import FeedEditor from './queryComposer/custom-feed/FeedEditor';

async function postRequestToDataProviderApi(url, { arg }) {
  const tokens = await Auth.currentSession();
  return API.post('DataProviderApi', url, {
    body: arg,
    headers: {
      Authorization: `${tokens.getIdToken().getJwtToken()}`,
    },
    response: true,
    queryStringParameters: {},
  }).then((response) => response.data);
}

function EditDataSourceDialog() {
  const { dataSourceId, workspaceId } = useParams();
  const { t } = useTranslation('common');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [recentAnchorEl, setRecentAnchorEl] = useState(null);
  const [showJobBoardDialog, setJobBoardDialog] = useState(false);
  const [showFeedEditor, setFeedEditor] = useState(false);

  const { trigger: triggerReset, isMutating: resetIsMutating } = useSWRMutation(
    '/data-providers/reset-rate-limiter',
    postRequestToDataProviderApi
  );

  // redux selectors
  const selectedDataSource = useSelector((state) => state.selectedDataSource);
  const userObject = useSelector((state) => state.userObject);
  const dialogs = useSelector((state) => state.dialogs);
  const open = dialogs.showEditDataSourceDialog;
  const dataSourcesByTeam = useSelector((state) => state.dataSourcesByTeam);
  const selectedUserTeam = useSelector((state) => state.selectedUserTeam);
  const currentAuthenticatedUser = useSelector(
    (state) => state.currentAuthenticatedUser
  );
  const dataProviderSettings = useSelector(
    (state) => state.dataProviderSettings
  );

  const groups =
    currentAuthenticatedUser.signInUserSession.accessToken.payload[
      'cognito:groups'
    ];

  const IS_ATS =
    groups.includes('Gladior_ATS') &&
    selectedDataSource?.dataProvider === 'GLADIOR_ATS';

  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'labelPopper',
  });

  function onClose() {
    if (dialogs.showEditDataSourceDialog === false) {
      dispatch({
        type: types.SET_SELECTED_DATA_SOURCE,
        dataSource: null,
      });
    }
  }

  const { items: dataSources } = dataSourcesByTeam[selectedUserTeam] || {
    items: [],
  };

  const configSettings =
    dataProviderSettings?.config?.[selectedDataSource?.dataProvider];

  const { data: fields = [] } = useDataProviderData(
    configSettings && `/${configSettings.route}/fields/`,
    {
      dataSourceId,
    }
  );

  useEffect(() => {
    if (dataSourceId && dataSources.length > 0) {
      const updatedDataSource = _find(
        dataSources,
        (o) => o.id === dataSourceId
      );

      if (updatedDataSource) {
        batch(() => {
          dispatch({
            type: types.SET_SELECTED_DATA_SOURCE,
            dataSource: updatedDataSource,
          });

          dispatch({
            type: types.SHOW_EDIT_DATA_SOURCE_DIALOG,
            isVisible: true,
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSourceId, dataSources]);

  // Local state

  function closeDialog() {
    dispatch({
      type: types.SET_SELECTED_DATA_SOURCE,
      dataSource: null,
    });

    dispatch({
      type: types.SHOW_EDIT_DATA_SOURCE_DIALOG,
      isVisible: false,
    });

    navigate({
      pathname: `/workspace/${workspaceId}/data-sources`,
      search: location.search,
    });

    onClose();
  }

  useEffect(() => {
    const qs = querystring.parse(location.search.slice(1));
    if (qs.queryId) {
      dispatch({
        type: types.SHOW_QUERY_COMPOSER_DIALOG,
        isVisible: true,
      });
    }
  }, []);
  if (!selectedDataSource) {
    return <></>;
  }

  let hasEditRights =
    selectedDataSource.owner === userObject.id ||
    (selectedDataSource.editors &&
      selectedDataSource.editors.includes(userObject.id));

  if (groups) {
    if (groups.includes(selectedDataSource.groups)) {
      hasEditRights = true;
    }
  }

  async function updateDataFreshness(dataFreshness) {
    const excludeArray = [
      'credential',
      'sets',
      'labels.items.dataSource',
      'team.avatar',
      'team.name',
      'team.owner',
      'team.members',
      'team.dataSources',
      'team.teamMembers',
      'team.credentials',
      'team.dataSourceLabels',
      'team.themes',
    ];
    const updateDataSourceFilter = (path) => {
      const re = new RegExp(`updateDataSource.(${excludeArray.join('|')})$`);
      return !re.test(path);
    };

    const updateDataSourceObjectObj = await API.graphql(
      graphqlOperation(
        filterGraphQLFields(mutations.updateDataSource, updateDataSourceFilter),
        { input: { id: selectedDataSource.id, dataFreshness } }
      )
    );

    // get the latest version with also the correct label
    const updatedDataSource = await updateDataSourceObject({
      id: selectedDataSource.id,
    });

    batch(() => {
      dispatch({
        type: types.UPDATE_TEAM_DATA_SOURCE,
        dataSource: updatedDataSource,
        teamId: updatedDataSource.team.id,
      });
    });

    return updateDataSourceObjectObj.data.updateDataSource;
  }

  let dataFreshness = 'realtime';

  if (selectedDataSource.dataFreshness) {
    const selectedDataFreshness = selectedDataSource.dataFreshness;

    if (selectedDataFreshness.startsWith('HOUR_')) {
      dataFreshness = `${
        selectedDataSource.dataFreshness.split('HOUR_')[1]
      } hours`;
    }
  }

  let dataFreshnessIsEnabled = false;

  dataFreshnessIsEnabled =
    dataProviderSettings?.config[selectedDataSource?.dataProvider]
      ?.dataFreshnessIsEnabled || false;

  const preCachingIsEnabled =
    dataProviderSettings?.config[selectedDataSource?.dataProvider]
      ?.preCachingIsEnabled || false;

  const disableGetData =
    dataProviderSettings?.config[selectedDataSource?.dataProvider]
      ?.disableGetData || false;

  const enableRateLimit =
    dataProviderSettings?.config[selectedDataSource?.dataProvider]
      ?.enableRateLimit || false;

  return (
    <>
      <Helmet>
        <title>
          {selectedDataSource.displayName
            ? selectedDataSource.displayName
            : selectedDataSource.name}
        </title>
      </Helmet>

      <Dialog
        aria-describedby="edit-data-source-dialog-description"
        aria-labelledby="edit-data-source-dialog-title"
        disableEnforceFocus
        fullWidth
        maxWidth="sm"
        onClose={closeDialog}
        open={open}
      >
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              my: 1,
              width: '100%',
              mb: 3,
            }}
          >
            <DataProviderChip dataProvider={selectedDataSource.dataProvider} />
          </Box>
          <>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
            >
              <Box sx={{ width: '100%' }}>
                <Typography align="center" component="h2" variant="h6">
                  {selectedDataSource.displayName
                    ? selectedDataSource.displayName
                    : selectedDataSource.name}
                </Typography>
              </Box>
            </Box>
            {selectedDataSource.displayName && (
              <Box mb={1}>
                <Typography
                  align="center"
                  color="textSecondary"
                  component="h3"
                  gutterBottom
                  variant="caption"
                >
                  {selectedDataSource.name}{' '}
                </Typography>
              </Box>
            )}
          </>
          <Typography align="center" color="textSecondary" variant="subtitle1">
            <ReactJoin
              separator={
                <Icon
                  style={{
                    fontSize: 'inherit',
                    lineHeight: 'inherit',
                  }}
                >
                  navigate_next
                </Icon>
              }
            >
              {selectedDataSource.tags}
            </ReactJoin>
          </Typography>
          {!hasEditRights && (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              marginTop="20px"
            >
              <Chip
                color="primary"
                icon={<InfoOutlined />}
                label="You only have read rights"
                variant="outlined"
              />
            </Box>
          )}

          {false && (
            <Box marginTop="20px" width="100%">
              <Typography
                color="textSecondary"
                gutterBottom
                variant="subtitle2"
              >
                Label
              </Typography>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
              >
                <Box alignItems="center" display="flex">
                  {selectedDataSource.labels.items.length === 0 && (
                    <Button
                      disabled={!hasEditRights}
                      onClick={(event) => {
                        setRecentAnchorEl(
                          recentAnchorEl ? null : event.currentTarget
                        );
                      }}
                      size="small"
                      startIcon={<Label color="secondary" fontSize="small" />}
                      variant="outlined"
                    >
                      Add a label
                    </Button>
                  )}

                  {selectedDataSource.labels.items.map((lO) => {
                    const { label } = lO;
                    return (
                      <DataSourceLabel
                        deletable={hasEditRights === true}
                        deleteAction={async () => {
                          await API.graphql(
                            graphqlOperation(
                              mutations.removeLabelFromDataSource,
                              {
                                labelId: label.id,
                                dataSourceId,
                                teamId: selectedUserTeam,
                              }
                            )
                          );

                          const updatedDataSource =
                            await updateDataSourceObject({
                              id: dataSourceId,
                            });

                          dispatch({
                            type: types.UPDATE_TEAM_DATA_SOURCE,
                            dataSource: updatedDataSource,
                            teamId: updatedDataSource.team.id,
                          });
                        }}
                        key={label.id}
                        name={label.name}
                        type={label.type}
                      />
                    );
                  })}
                </Box>

                <Box>
                  <IconButton
                    color="default"
                    disabled={!hasEditRights}
                    variant="outlined"
                    {...bindToggle(popupState)}
                    onClick={(event) => {
                      setRecentAnchorEl(
                        recentAnchorEl ? null : event.currentTarget
                      );
                    }}
                    size="large"
                  >
                    <Settings />
                  </IconButton>

                  <Popper
                    anchorEl={recentAnchorEl}
                    id={recentAnchorEl ? 'recent-popper' : undefined}
                    open={Boolean(recentAnchorEl)}
                    placement="bottom-end"
                    sx={(theme) => ({
                      zIndex: theme.zIndex.modal,
                    })}
                  >
                    {({ TransitionProps }) => (
                      // <ClickAwayListener
                      //   onClickAway={() => {
                      //     setRecentAnchorEl(null);
                      //   }}
                      // >
                      <Fade {...TransitionProps} timeout={350}>
                        <SelectLabel
                          closePopper={() => {
                            setRecentAnchorEl(null);
                          }}
                          dataSourceId={selectedDataSource.id}
                          labelIds={selectedDataSource.labels.items.map(
                            (labelObj) => labelObj.label.id
                          )}
                          teamId={selectedUserTeam}
                        />
                      </Fade>
                      // </ClickAwayListener>
                    )}
                  </Popper>
                </Box>
              </Box>
            </Box>
          )}
          {selectedDataSource.dataProvider === dp.MULTI_DATA_PROVIDER && (
            <Box marginTop="20px" width="100%">
              <Typography
                color="textSecondary"
                gutterBottom
                variant="subtitle2"
              >
                Connected Data Sources
              </Typography>

              <Box display="flex" flexDirection="row" flexWrap="wrap">
                <List>
                  {selectedDataSource.view.dataSourceIds.map((id) => (
                    <DataSourceListItem dataSourceId={id} key={id} />
                  ))}
                </List>
              </Box>
            </Box>
          )}
          {selectedDataSource.dataProvider !== dp.MULTI_DATA_PROVIDER &&
            !IS_ATS && (
              <Box marginTop="20px" width="100%">
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="subtitle2"
                >
                  Credential
                </Typography>

                <Box
                  display="flex"
                  flexDirection="row"
                  flexWrap="wrap"
                  justifyContent="space-between"
                >
                  <Box display="flex" flexDirection="row">
                    <Box
                      alignItems="center"
                      display="flex"
                      flexDirection="row"
                      marginRight="12px"
                    >
                      {selectedDataSource.credential ? (
                        <Avatar
                          src={selectedDataSource.credential.picture}
                          sx={(theme) => ({
                            color: theme.palette.getContrastText(
                              avatarCredentialColors[
                                numberFromText(
                                  getInitials(
                                    selectedDataSource.credential.name
                                  )
                                ) % avatarCredentialColors.length
                              ]
                            ),
                            bgcolor:
                              avatarCredentialColors[
                                numberFromText(
                                  getInitials(
                                    selectedDataSource.credential.name
                                  )
                                ) % avatarCredentialColors.length
                              ],
                          })}
                        >
                          {getInitials(selectedDataSource.credential.name)}
                        </Avatar>
                      ) : (
                        <>
                          <Error htmlColor={red[500]} />
                        </>
                      )}
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      flexGrow={2}
                      maxWidth="300px"
                    >
                      {selectedDataSource.credential ? (
                        <>
                          <Typography
                            color="textPrimary"
                            component="span"
                            noWrap
                            sx={{ display: 'inline' }}
                            variant="body1"
                          >
                            {selectedDataSource.credential.name}
                          </Typography>
                          <Typography
                            color="textSecondary"
                            component="span"
                            noWrap
                            sx={{ display: 'inline' }}
                            variant="body2"
                          >
                            {selectedDataSource.credential.email}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography
                            color="textPrimary"
                            component="span"
                            noWrap
                            sx={{ display: 'inline' }}
                            variant="body1"
                          >
                            Credential removed
                          </Typography>
                          <Typography
                            color="textSecondary"
                            component="span"
                            noWrap
                            sx={{ display: 'inline' }}
                            variant="body2"
                          >
                            Please reconnect the credential
                          </Typography>
                        </>
                      )}
                    </Box>
                  </Box>

                  <Box alignItems="flex-end" display="flex" flexDirection="row">
                    <Button
                      color="inherit"
                      disabled={hasEditRights === false}
                      onClick={() => {
                        batch(() => {
                          dispatch({
                            type: types.SET_SELECTED_DATA_SOURCE,
                            dataSource: selectedDataSource,
                          });

                          dispatch({
                            type: types.SHOW_CHANGE_CREDENTIAL_FOR_DATA_SOURCE_DIALOG,
                            isVisible: true,
                          });
                        });
                      }}
                      size="small"
                      startIcon={<SwapHoriz />}
                      sx={(theme) => ({
                        whiteSpace: 'nowrap',
                        textTransform: 'none',
                        border: `1px solid ${alpha(
                          theme.palette.common.black,
                          0.23
                        )}`,
                      })}
                      variant="outlined"
                    >
                      {t('switchCredential')}
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
          {selectedDataSource.dataBlendingViews &&
            selectedDataSource.dataBlendingViews.length > 0 && (
              <>
                <Box marginTop="20px" width="100%">
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="subtitle2"
                  >
                    Data Bending
                  </Typography>
                </Box>

                {selectedDataSource.dataBlendingViews.map((view) => (
                  <Box
                    display="flex"
                    flexDirection="column"
                    flexGrow={2}
                    key={view.connectorId}
                    my={1}
                  >
                    <Typography
                      color="textPrimary"
                      component="span"
                      sx={{ display: 'inline' }}
                      variant="body1"
                    >
                      {view.viewName}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      component="span"
                      sx={{ display: 'inline' }}
                      variant="body2"
                    >
                      {view.connectorId}
                    </Typography>
                  </Box>
                ))}
              </>
            )}

          <Box marginTop="20px" width="100%">
            <Typography color="textSecondary" gutterBottom variant="subtitle2">
              {t('Settings')}
            </Typography>
            {IS_ATS ? (
              <>
                <Box
                  display="flex"
                  flexDirection="column"
                  marginBottom="0.35em"
                >
                  <Button
                    disabled={hasEditRights === false}
                    onClick={() => {
                      setJobBoardDialog(true);
                    }}
                    sx={(theme) => ({
                      textTransform: 'none',
                      border: `1px solid ${alpha(
                        theme.palette.common.black,
                        0.23
                      )}`,
                      textAlign: 'left',
                    })}
                    variant="outlined"
                  >
                    {t('connectJobBoard')}
                  </Button>
                  <CreateJobFeedDialog
                    open={showJobBoardDialog}
                    setOpen={setJobBoardDialog}
                  ></CreateJobFeedDialog>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  marginBottom="0.35em"
                >
                  <Button
                    disabled={hasEditRights === false}
                    onClick={() => {
                      setFeedEditor(true);
                    }}
                    sx={(theme) => ({
                      textTransform: 'none',
                      border: `1px solid ${alpha(
                        theme.palette.common.black,
                        0.23
                      )}`,
                      textAlign: 'left',
                    })}
                    variant="outlined"
                  >
                    {t('connectOwn')}
                  </Button>
                  <FeedEditor
                    close={() => {
                      setFeedEditor(false);
                    }}
                    dataSourceId={selectedDataSource.id}
                    open={showFeedEditor}
                    queryObj={{
                      id: undefined,
                      selectedDimensions: fields.filter(
                        (v) => v.conceptType === 'DIMENSION'
                      ),
                      selectedMetrics: fields.filter(
                        (v) => v.conceptType === 'METRIC'
                      ),
                    }}
                  ></FeedEditor>
                </Box>
              </>
            ) : (
              <>
                {groups.includes('gladior') && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    marginBottom="0.35em"
                  >
                    <PopupState popupId="popup-popover" variant="popover">
                      {(popupState2) => (
                        <>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Button
                              fullWidth
                              startIcon={<BugReport color="info" />}
                              sx={(theme) => ({
                                textTransform: 'none',
                                border: `1px solid ${alpha(
                                  theme.palette.common.black,
                                  0.23
                                )}`,
                                textAlign: 'left',
                              })}
                              variant="outlined"
                              {...bindTrigger(popupState2)}
                            >
                              Debug Window <ArrowDropDown fontSize="small" />
                            </Button>
                          </Box>
                          <Popover
                            {...bindPopover(popupState2)}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                          >
                            <Box sx={{ maxHeight: 300, maxWidth: 400 }}>
                              <Typography variant="caption">
                                <JSONPretty
                                  data={selectedDataSource}
                                ></JSONPretty>
                              </Typography>
                            </Box>
                          </Popover>
                        </>
                      )}
                    </PopupState>
                  </Box>
                )}

                <Box
                  display="flex"
                  flexDirection="column"
                  marginBottom="0.35em"
                >
                  <Button
                    disabled={hasEditRights === false}
                    onClick={() => {
                      batch(() => {
                        dispatch({
                          type: types.SHOW_UPDATE_DATA_SOURCE_DISPLAY_NAME,
                          isVisible: true,
                        });
                      });
                    }}
                    sx={(theme) => ({
                      textTransform: 'none',
                      border: `1px solid ${alpha(
                        theme.palette.common.black,
                        0.23
                      )}`,
                      textAlign: 'left',
                    })}
                    variant="outlined"
                  >
                    {t('updateDisplayName')}
                  </Button>
                </Box>

                <Box
                  display="flex"
                  flexDirection="column"
                  marginBottom="0.35em"
                >
                  <Button
                    disabled={hasEditRights === false}
                    onClick={() => {
                      batch(() => {
                        dispatch({
                          type: types.SET_SELECTED_DATA_SOURCE,
                          dataSource: selectedDataSource,
                        });

                        dispatch({
                          type: types.SHOW_RE_CONNECT_DATA_SOURCE_DIALOG,
                          isVisible: true,
                        });
                      });
                    }}
                    sx={(theme) => ({
                      textTransform: 'none',
                      border: `1px solid ${alpha(
                        theme.palette.common.black,
                        0.23
                      )}`,
                      textAlign: 'left',
                    })}
                    variant="outlined"
                  >
                    {t('reconnectDataSource')}
                  </Button>
                </Box>
                {groups.includes('gladior') && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    marginBottom="0.35em"
                  >
                    <Button
                      disabled={hasEditRights === false}
                      onClick={() => {
                        batch(() => {
                          dispatch({
                            type: types.SET_SELECTED_DATA_SOURCE,
                            dataSource: selectedDataSource,
                          });

                          dispatch({
                            type: types.SHOW_EDITING_RIGHTS_DATA_SOURCE_DIALOG,
                            isVisible: true,
                          });
                        });
                      }}
                      sx={(theme) => ({
                        textTransform: 'none',
                        border: `1px solid ${alpha(
                          theme.palette.common.black,
                          0.23
                        )}`,
                        textAlign: 'left',
                      })}
                      variant="outlined"
                    >
                      Permissions & Roles
                    </Button>
                  </Box>
                )}

                <Box
                  display="flex"
                  flexDirection="column"
                  marginBottom="0.35em"
                >
                  <Button
                    disabled={hasEditRights === false}
                    onClick={() => {
                      batch(() => {
                        dispatch({
                          type: types.SET_SELECTED_DATA_SOURCE,
                          dataSource: selectedDataSource,
                        });

                        dispatch({
                          type: types.SHOW_DELETE_DATA_SOURCE_DIALOG,
                          isVisible: true,
                        });
                      });
                    }}
                    sx={(theme) => ({
                      textTransform: 'none',
                      border: `1px solid ${alpha(
                        theme.palette.common.black,
                        0.23
                      )}`,
                      textAlign: 'left',
                    })}
                    variant="outlined"
                  >
                    {t('deleteDataSource')}
                  </Button>
                </Box>
              </>
            )}
          </Box>

          {enableRateLimit && (
            <Box marginTop="20px" width="100%">
              <Typography
                color="textSecondary"
                gutterBottom
                variant="subtitle2"
              >
                Rate Limiter
              </Typography>
              <Box display="flex" flexDirection="column" marginBottom="0.35em">
                <Box
                  display="flex"
                  flexDirection="column"
                  marginBottom="0.35em"
                >
                  <Button
                    disabled={hasEditRights === false}
                    onClick={() => {
                      batch(() => {
                        dispatch({
                          type: types.SHOW_USAGE,
                          isVisible: true,
                        });
                      });
                    }}
                    sx={(theme) => ({
                      textTransform: 'none',
                      border: `1px solid ${alpha(
                        theme.palette.common.black,
                        0.23
                      )}`,
                      textAlign: 'left',
                    })}
                    variant="outlined"
                  >
                    View Usage
                  </Button>
                </Box>
                <Button
                  disabled={hasEditRights === false}
                  onClick={() => {
                    batch(() => {
                      dispatch({
                        type: types.SHOW_UPDATE_MAX_REQUEST,
                        isVisible: true,
                      });
                    });
                  }}
                  sx={(theme) => ({
                    textTransform: 'none',
                    border: `1px solid ${alpha(
                      theme.palette.common.black,
                      0.23
                    )}`,
                    textAlign: 'left',
                  })}
                  variant="outlined"
                >
                  Set Max Requests
                  <Box sx={{ ml: 1, color: grey[500] }}>
                    (
                    {selectedDataSource?.rateLimit?.max
                      ? `${
                          selectedDataSource?.rateLimit?.max
                        }/${selectedDataSource?.rateLimit?.interval.toLowerCase()}`
                      : `0/month`}
                    )
                  </Box>{' '}
                </Button>
              </Box>
              <Box display="flex" flexDirection="column" marginBottom="0.35em">
                <LoadingButton
                  disabled={hasEditRights === false}
                  loading={resetIsMutating}
                  onClick={() => {
                    triggerReset({ dataSourceId: selectedDataSource?.id });
                  }}
                  sx={(theme) => ({
                    textTransform: 'none',
                    border: `1px solid ${alpha(
                      theme.palette.common.black,
                      0.23
                    )}`,
                    textAlign: 'left',
                  })}
                  variant="outlined"
                >
                  Reset Rate Limiter
                </LoadingButton>
              </Box>
            </Box>
          )}

          {(selectedDataSource.dataProvider === 'OPEN_AI' ||
            selectedDataSource.dataProvider === 'AFAS_INTEGRATION_PAGE') && (
            <Box marginTop="20px" width="100%">
              <Typography
                color="textSecondary"
                gutterBottom
                variant="subtitle2"
              >
                {t('Integrations')}
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}>
                <Button
                  color="info"
                  disabled={hasEditRights === false}
                  onClick={() => {
                    batch(() => {
                      dispatch({
                        type: types.SET_SELECTED_DATA_SOURCE,
                        dataSource: selectedDataSource,
                      });

                      dispatch({
                        type: types.SHOW_WEBSITE_INTEGRATION_DIALOG,
                        isVisible: true,
                      });
                    });
                  }}
                  startIcon={<Language />}
                  sx={(theme) => ({
                    textTransform: 'none',
                    border: `1px solid ${alpha(
                      theme.palette.common.black,
                      0.23
                    )}`,
                    textAlign: 'left',
                    borderRadius: '4px',
                  })}
                  variant="outlined"
                >
                  Instructions
                </Button>
              </Box>
            </Box>
          )}

          {groups.includes('Ask-Data-Hub') && disableGetData === false && (
            <Box sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}>
              <Button
                color="info"
                disabled={hasEditRights === false}
                onClick={() => {
                  batch(() => {
                    dispatch({
                      type: types.SET_SELECTED_DATA_SOURCE,
                      dataSource: selectedDataSource,
                    });

                    dispatch({
                      type: types.SHOW_ASK_DATA_HUB_DIALOG,
                      isVisible: true,
                    });
                  });
                }}
                startIcon={
                  <>
                    <>
                      <svg height={0} width={0}>
                        <linearGradient
                          id="linearColors"
                          x1="0%"
                          x2="100%"
                          y1="0%"
                          y2="0"
                        >
                          <stop offset="0%" stopColor="#8983ba" />
                          <stop offset="20%" stopColor="#9e98cc" />
                          <stop offset="38%" stopColor="#c29abe" />
                          <stop offset="56%" stopColor="#e197b0" />
                          <stop offset="74%" stopColor="#ff9a7d" />
                          <stop offset="84%" stopColor="#ffb467" />
                          <stop offset="100%" stopColor="#ffda8e" />
                        </linearGradient>
                      </svg>
                      <AutoAwesome sx={{ fill: 'url(#linearColors)' }} />
                    </>
                  </>
                }
                sx={() => ({
                  '--angle': '0deg',
                  animation: '10s rotate linear infinite',
                  textTransform: 'none',
                  border: '1px solid',
                  textAlign: 'left',
                  background:
                    'linear-gradient(75deg, rgba(137, 131, 186, 0.1), rgba(158, 152, 204, 0.1) 20%, rgba(194, 154, 190, 0.1) 38%, rgba(225, 151, 176, 0.1) 56%, rgba(255, 154, 125, 0.1) 74%, rgba(255, 180, 103, 0.1) 84%, rgba(255, 218, 142, 0.1))',
                  borderImage:
                    'conic-gradient(from var(--angle), #8983ba, #9e98cc,  #c29abe, #e197b0, #ff9a7d, #8983ba, #9e98cc ) 1',
                })}
                variant="outlined"
              >
                <Box
                  component={'span'}
                  sx={{
                    background:
                      'linear-gradient(to right, #f06844 0%, #ee4c54 25%, #d45e95 50%, #9c6ca6 75%, #6583c1 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  Ask Data Hub
                </Box>
              </Button>
            </Box>
          )}
          {(groups.includes('gladior') || groups.includes('GBP-Plus')) &&
            dataFreshnessIsEnabled && (
              <Box marginTop="20px" width="100%">
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="subtitle2"
                >
                  Caching
                </Typography>

                <Box
                  display="flex"
                  flexDirection="column"
                  marginBottom="0.35em"
                >
                  <PopupState popupId="demo-popup-menu" variant="popover">
                    {(popupStateMenu) => (
                      <React.Fragment>
                        <Button
                          disabled={hasEditRights === false}
                          sx={(theme) => ({
                            textTransform: 'none',
                            border: `1px solid ${alpha(
                              theme.palette.common.black,
                              0.23
                            )}`,
                            textAlign: 'left',
                          })}
                          variant="outlined"
                          {...bindTrigger(popupStateMenu)}
                        >
                          Data Freshness{' '}
                          <Box sx={{ ml: 1, color: grey[500] }}>
                            ({dataFreshness})
                          </Box>{' '}
                          <ArrowDropDown fontSize="small" />
                        </Button>

                        <Menu
                          PaperProps={{
                            style: {
                              // width: '40ch',
                            },
                          }}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                          variant="selectedMenu"
                          {...bindMenu(popupStateMenu)}
                        >
                          <MenuItem
                            onClick={() => {
                              updateDataFreshness('REALTIME');
                              popupStateMenu.close();
                            }}
                            selected={
                              selectedDataSource?.dataFreshness ===
                                'REALTIME' || !selectedDataSource?.dataFreshness
                            }
                          >
                            <ListItemIcon>
                              {selectedDataSource?.dataFreshness ===
                                'REALTIME' ||
                              !selectedDataSource?.dataFreshness ? (
                                <Check color="info" />
                              ) : (
                                <Speed fontSize="small" />
                              )}
                            </ListItemIcon>
                            <Box display="flex" flexDirection="column">
                              <Typography
                                color="textPrimary"
                                component="span"
                                sx={{ display: 'inline' }}
                                variant="body1"
                              >
                                Realtime
                              </Typography>
                              <Typography
                                color="textSecondary"
                                component="span"
                                sx={{ display: 'inline' }}
                                variant="body2"
                              >
                                Disable the cache
                              </Typography>
                            </Box>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              updateDataFreshness('HOUR_12');
                              popupStateMenu.close();
                            }}
                            selected={
                              selectedDataSource?.dataFreshness === 'HOUR_12'
                            }
                          >
                            <ListItemIcon>
                              {selectedDataSource?.dataFreshness ===
                              'HOUR_12' ? (
                                <Check color="info" />
                              ) : (
                                <PunchClock fontSize="small" />
                              )}
                            </ListItemIcon>
                            <Box display="flex" flexDirection="column">
                              <Typography
                                color="textPrimary"
                                component="span"
                                sx={{ display: 'inline' }}
                                variant="body1"
                              >
                                12 hours
                              </Typography>
                              <Typography
                                color="textSecondary"
                                component="span"
                                sx={{ display: 'inline' }}
                                variant="body2"
                              >
                                New request will be cached for 12 hours
                              </Typography>
                            </Box>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              updateDataFreshness('HOUR_24');
                              popupStateMenu.close();
                            }}
                            selected={
                              selectedDataSource?.dataFreshness === 'HOUR_24'
                            }
                          >
                            <ListItemIcon>
                              {selectedDataSource?.dataFreshness ===
                              'HOUR_24' ? (
                                <Check color="info" />
                              ) : (
                                <PunchClock fontSize="small" />
                              )}
                            </ListItemIcon>
                            <Box display="flex" flexDirection="column">
                              <Typography
                                color="textPrimary"
                                component="span"
                                sx={{ display: 'inline' }}
                                variant="body1"
                              >
                                24 hours
                              </Typography>
                              <Typography
                                color="textSecondary"
                                component="span"
                                sx={{ display: 'inline' }}
                                variant="body2"
                              >
                                New request will be cached for 24 hours
                              </Typography>
                            </Box>
                          </MenuItem>
                        </Menu>
                      </React.Fragment>
                    )}
                  </PopupState>
                </Box>
                {groups.includes('gladior') && preCachingIsEnabled && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}>
                    <Button
                      color="info"
                      disabled={hasEditRights === false}
                      onClick={() => {
                        batch(() => {
                          dispatch({
                            type: types.SET_SELECTED_DATA_SOURCE,
                            dataSource: selectedDataSource,
                          });

                          dispatch({
                            type: types.SHOW_PRE_CACHE_DIALOG,
                            isVisible: true,
                          });
                        });
                      }}
                      startIcon={<Autorenew />}
                      sx={(theme) => ({
                        textTransform: 'none',
                        border: `1px solid ${alpha(
                          theme.palette.common.black,
                          0.23
                        )}`,
                        textAlign: 'left',
                      })}
                      variant="outlined"
                    >
                      Pre-Caching
                    </Button>
                  </Box>
                )}
              </Box>
            )}
          {disableGetData === false && (
            <Box marginTop="20px" width="100%">
              <Typography
                color="textSecondary"
                gutterBottom
                variant="subtitle2"
              >
                {t('Integrations')}
              </Typography>

              {groups.includes('gladior') && (
                <Box sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}>
                  <Button
                    color="info"
                    disabled={hasEditRights === false}
                    onClick={() => {
                      batch(() => {
                        dispatch({
                          type: types.SET_SELECTED_DATA_SOURCE,
                          dataSource: selectedDataSource,
                        });

                        dispatch({
                          type: types.SHOW_DATA_EXPLORER_DIALOG,
                          isVisible: true,
                        });
                      });
                    }}
                    startIcon={<QueryStats />}
                    sx={(theme) => ({
                      textTransform: 'none',
                      border: `1px solid ${alpha(
                        theme.palette.common.black,
                        0.23
                      )}`,
                      textAlign: 'left',
                    })}
                    variant="outlined"
                  >
                    Data Explorer
                  </Button>
                </Box>
              )}

              {(groups.includes('gladior') ||
                groups.includes('GBP-Plus') ||
                groups.includes('Gladior_ATS')) && (
                <Box sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}>
                  <Button
                    color="info"
                    disabled={hasEditRights === false}
                    onClick={() => {
                      batch(() => {
                        dispatch({
                          type: types.SET_SELECTED_DATA_SOURCE,
                          dataSource: selectedDataSource,
                        });

                        dispatch({
                          type: types.SHOW_DATA_API_DIALOG,
                          isVisible: true,
                        });
                      });
                    }}
                    startIcon={<DataObject />}
                    sx={(theme) => ({
                      textTransform: 'none',
                      border: `1px solid ${alpha(
                        theme.palette.common.black,
                        0.23
                      )}`,
                      textAlign: 'left',
                    })}
                    variant="outlined"
                  >
                    JSON/CSV/XML data
                  </Button>
                </Box>
              )}

              {groups.includes('gladior') && (
                <Box sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}>
                  <Button
                    color="info"
                    disabled={hasEditRights === false}
                    onClick={() => {
                      batch(() => {
                        dispatch({
                          type: types.SET_SELECTED_DATA_SOURCE,
                          dataSource: selectedDataSource,
                        });

                        dispatch({
                          type: types.SHOW_MICROSOFT_TEAMS_DIALOG,
                          isVisible: true,
                        });
                      });
                    }}
                    startIcon={
                      <Avatar
                        src={microsoftTeamsLogo}
                        sx={{ width: 22, height: 20 }}
                        variant="square"
                      />
                    }
                    sx={(theme) => ({
                      textTransform: 'none',
                      border: `1px solid ${alpha(
                        theme.palette.common.black,
                        0.23
                      )}`,
                      textAlign: 'left',
                    })}
                    variant="outlined"
                  >
                    Microsoft Teams
                  </Button>
                </Box>
              )}
              {(groups.includes('gladior') ||
                groups.includes('Gladior_ATS')) && (
                <Box sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}>
                  <Button
                    color="info"
                    disabled={hasEditRights === false}
                    onClick={() => {
                      batch(() => {
                        dispatch({
                          type: types.SET_SELECTED_DATA_SOURCE,
                          dataSource: selectedDataSource,
                        });

                        dispatch({
                          type: types.SHOW_QUERY_COMPOSER_DIALOG,
                          isVisible: true,
                        });
                      });
                    }}
                    startIcon={<StackedBarChart />}
                    sx={(theme) => ({
                      textTransform: 'none',
                      border: `1px solid ${alpha(
                        theme.palette.common.black,
                        0.23
                      )}`,
                      textAlign: 'left',
                    })}
                    variant="outlined"
                  >
                    Query Builder
                  </Button>
                </Box>
              )}
              {(groups.includes('gladior') || IS_ATS) && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    mb: 1,
                    backgroundColor: 'black',
                    borderRadius: '5px',
                  }}
                >
                  <Button
                    color="info"
                    disabled={hasEditRights === false}
                    onClick={() => {
                      batch(() => {
                        dispatch({
                          type: types.SET_SELECTED_DATA_SOURCE,
                          dataSource: selectedDataSource,
                        });

                        dispatch({
                          type: types.SHOW_POWER_BI_DIALOG,
                          isVisible: true,
                        });
                      });
                    }}
                    startIcon={
                      <Avatar
                        src={powerBiLogo}
                        sx={{
                          objectFit: 'contain',
                          width: 15,
                          height: 21,
                          overflow: 'visible',
                        }}
                        variant="square"
                      />
                    }
                    sx={{
                      '--powerBIcol1': '#ffe375',
                      '--powerBIcol2': '#ffd741',
                      '--powerBIcol3': '#ffd53c',
                      '--powerBIcol4': '#f9b100',
                      '--powerBIcol5': '#f6a900',
                      '--powerBIcol6': '#e08200',
                      textTransform: 'none',
                      border: '1px solid #FAB200',
                      textAlign: 'left',

                      background:
                        'linear-gradient(75deg, var(--powerBIcol1), var(--powerBIcol2) 10%, var(--powerBIcol2) 20%, var(--powerBIcol3) 30%, var(--powerBIcol4) 50%, var(--powerBIcol5) 70%, var(--powerBIcol6))',
                      '&:hover': {
                        '--powerBIcol1': '#ffe375cc',
                        '--powerBIcol2': '#ffd741cc',
                        '--powerBIcol3': '#ffd53ccc',
                        '--powerBIcol4': '#f9b100cc',
                        '--powerBIcol5': '#f6a900cc',
                        '--powerBIcol6': '#e08200cc',
                      },
                      transition:
                        '--powerBIcol1 0.3s, --powerBIcol2 0.3s, --powerBIcol3 0.3s, --powerBIcol4 0.3s, --powerBIcol5 0.3s, --powerBIcol6 0.3s',
                    }}
                    variant="contained"
                  >
                    Power BI
                  </Button>
                </Box>
              )}

              <Box sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}>
                <Button
                  color="info"
                  disabled={hasEditRights === false}
                  onClick={() => {
                    batch(() => {
                      dispatch({
                        type: types.SET_SELECTED_DATA_SOURCE,
                        dataSource: selectedDataSource,
                      });

                      dispatch({
                        type: types.SHOW_GOOGLE_DATA_STUDIO_DIALOG,
                        isVisible: true,
                      });
                    });
                  }}
                  startIcon={
                    <Avatar
                      src={dataStudioLogo}
                      sx={{ width: 12, height: 20 }}
                      variant="square"
                    />
                  }
                  sx={(theme) => ({
                    textTransform: 'none',
                    border: `1px solid ${alpha(
                      theme.palette.common.black,
                      0.23
                    )}`,
                    textAlign: 'left',
                  })}
                  variant="contained"
                >
                  Looker Studio
                </Button>
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>{t('Close')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EditDataSourceDialog;
