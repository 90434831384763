/* eslint-disable no-nested-ternary */

import {
  AccountCircleTwoTone,
  ArrowBack,
  ArrowForward,
  ArrowForwardIos,
  Error,
  InsertEmoticon,
  Refresh,
} from '@mui/icons-material';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import { green, red } from '@mui/material/colors';
import { makeStyles, useTheme } from '@mui/styles';
import { API, Auth } from 'aws-amplify';
import clsx from 'clsx';
import _filter from 'lodash/filter';
import _pickBy from 'lodash/pickBy';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import getAllCredentials from '../../api/getAllCredentials';
import updateDataSourceObject from '../../api/updateDataSourceObject';

import { useTranslation } from 'react-i18next';
import * as types from '../../constants/ActionTypes';
import shouldFetchCredentials from '../../utilities/shouldFetchCredentials';

const useStyles = makeStyles(() => ({
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
  },
}));

const NOT_SEARCHED_YET = 'NOT_SEARCHED_YET';
const FOUND_PROFILE = 'FOUND_PROFILE';
const NOT_FOUND_PROFILE = 'NOT_FOUND_PROFILE';

function ChangeCredentialForDataSource({ onClose = () => {} }) {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [activeConnectStep, setActiveConnectStep] = useState(0);
  const [selectedCredential, setSelectedCredential] = useState(null);
  const [foundProfile, setFoundProfile] = useState(NOT_SEARCHED_YET);
  const [profileIsLoading, setProfileIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const selectedDataSource = useSelector((state) => state.selectedDataSource);
  const dialogs = useSelector((state) => state.dialogs);
  const open = dialogs.showChangeCredentialForDataSourceDialog;
  const dataProviderSettings = useSelector(
    (state) => state.dataProviderSettings
  );

  function closeDialog() {
    dispatch({
      type: types.SHOW_CHANGE_CREDENTIAL_FOR_DATA_SOURCE_DIALOG,
      isVisible: false,
    });
    setActiveConnectStep(0);
    setSelectedCredential(null);
    setFoundProfile(NOT_SEARCHED_YET);
    setProfileIsLoading(false);
    setSuccess(false);
    setIsSubmitting(false);
    onClose();
  }

  const selectedUserTeam = useSelector((state) => state.selectedUserTeam);
  const credentialsByTeam = useSelector((state) => state.credentialsByTeam);
  const userObject = useSelector((state) => state.userObject);

  useEffect(() => {
    async function fetchALlCredentials() {
      if (shouldFetchCredentials(credentialsByTeam, selectedUserTeam)) {
        dispatch({
          type: types.REQUEST_TEAM_CREDENTIALS,
          teamId: selectedUserTeam,
        });
        const { credentials } = await getAllCredentials(
          selectedUserTeam,
          undefined
        );

        dispatch({
          type: types.SET_TEAM_CREDENTIALS,
          teamId: selectedUserTeam,
          credentials,
          nextToken: undefined,
        });
      }
    }

    fetchALlCredentials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserTeam]);

  async function hasAccess(fetchSelectedCredential) {
    setProfileIsLoading(true);
    const tokens = await Auth.currentSession();

    try {
      const response = await API.get(
        'DataProviderApi',
        `/${selectedDataSource.dataProvider
          .toLowerCase()
          .split('_')
          .join('-')}/access`,
        {
          headers: {
            Authorization: `${tokens.getIdToken().getJwtToken()}`,
          },
          response: true,
          queryStringParameters: {
            credentialId: fetchSelectedCredential.id,
            ..._pickBy(selectedDataSource.view),
          },
        }
      );

      if (response.data && response.data.hasAccess === true) {
        setTimeout(() => {
          setFoundProfile(FOUND_PROFILE);
          setProfileIsLoading(false);
        }, 500);
      } else {
        setFoundProfile(NOT_FOUND_PROFILE);
        setProfileIsLoading(false);
      }
    } catch (e) {
      setFoundProfile(NOT_FOUND_PROFILE);
      setProfileIsLoading(false);
    }
  }

  useEffect(() => {
    async function fetchViews(fetchSelectedCredential) {
      setProfileIsLoading(true);

      await hasAccess(fetchSelectedCredential);
    }
    if (activeConnectStep === 0) {
      setSelectedCredential(null);
      setFoundProfile(NOT_SEARCHED_YET);
    }

    if (activeConnectStep === 1 && selectedCredential) {
      fetchViews(selectedCredential);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeConnectStep]);

  const { items: credentials } = credentialsByTeam[selectedUserTeam] || {
    isFetching: true,
    items: [],
    nextToken: undefined,
  };

  const handleBack = () => {
    setActiveConnectStep((prevActiveStep) => prevActiveStep - 1);
  };

  async function switchCredential(switchSelectedCredential) {
    setIsSubmitting(true);

    const updatedDataSource = await updateDataSourceObject({
      id: selectedDataSource.id,
      credentialId: switchSelectedCredential.id,
    });

    dispatch({
      type: types.UPDATE_TEAM_DATA_SOURCE,
      dataSource: updatedDataSource,
      teamId: updatedDataSource.team.id,
    });

    setIsSubmitting(false);
    setSuccess(true);

    setTimeout(() => {
      closeDialog();
    }, 500);
  }

  useEffect(() => {
    if (profileIsLoading === true) {
      closeSnackbar('data_source_not_found');
      closeSnackbar('data_source_found');
      enqueueSnackbar(`${t(`searchingForDS`)}...`, {
        variant: 'info',
        key: 'find_data_source',
        autoHideDuration: 3000,
        persist: true,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    } else {
      closeSnackbar('find_data_source');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileIsLoading]);

  useEffect(() => {
    if (foundProfile === FOUND_PROFILE) {
      closeSnackbar('find_data_source');
      enqueueSnackbar(t(`DSFound`), {
        variant: 'success',
        key: 'data_source_found',
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    } else if (foundProfile === NOT_FOUND_PROFILE) {
      closeSnackbar('find_data_source');
      enqueueSnackbar(t(`DSNotFound`), {
        variant: 'error',
        key: 'data_source_not_found',
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foundProfile]);

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  if (!selectedDataSource) {
    return <></>;
  }

  return (
    <div>
      <Dialog
        aria-describedby="change-credential-data-source-dialog-description"
        aria-labelledby="change-credential-data-source-dialog-title"
        fullWidth
        maxWidth="sm"
        onClose={closeDialog}
        open={open}
      >
        <DialogTitle id="change-credential-data-source-dialog-title">
          {t('switchCredential')}
        </DialogTitle>
        <DialogContent>
          <Card>
            <AppBar position="static">
              <Toolbar variant="dense">
                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Box>
                    {activeConnectStep > 0 && (
                      <IconButton
                        className={classes.menuButton}
                        color="inherit"
                        edge="start"
                        onClick={handleBack}
                        size="large"
                      >
                        <ArrowBack />
                      </IconButton>
                    )}
                  </Box>
                  <Typography align="center" color="inherit" variant="h6">
                    {activeConnectStep === 0 && t('chooseNewCredential')}
                    {activeConnectStep === 1 && t('switchCredential')}
                  </Typography>
                  <Box />
                </Box>
              </Toolbar>
            </AppBar>
            <CardContent>
              {activeConnectStep === 0 && (
                <>
                  {_filter(credentials, (o) =>
                    o.dataProvider.includes(selectedDataSource.dataProvider)
                  ).length === 0 && (
                    <>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        sx={{ mt: 4 }}
                      >
                        <Box display="flex" justifyContent="center">
                          <AccountCircleTwoTone
                            color="disabled"
                            fontSize="large"
                          />
                        </Box>
                        <Typography
                          align="center"
                          color="textPrimary"
                          variant="subtitle1"
                        >
                          No credentials found for{' '}
                          {
                            dataProviderSettings?.config[
                              selectedDataSource?.dataProvider
                            ]?.name
                          }
                        </Typography>

                        <Box
                          display="flex"
                          justifyContent="center"
                          sx={{ mt: 2 }}
                        >
                          <Button
                            color="secondary"
                            component={Link}
                            to={{
                              pathname: `/workspace/${
                                selectedUserTeam.split('-')[0]
                              }/data-sources/create/data-source`,
                              search: `?dataProvider=${selectedDataSource.dataProvider.toLowerCase()}`,
                              state: {
                                dataProvider:
                                  selectedDataSource.dataProvider.toLowerCase(),
                              },
                            }}
                            variant="contained"
                          >
                            Add credential
                          </Button>
                        </Box>
                      </Box>
                    </>
                  )}
                  <List>
                    {_filter(credentials, (o) =>
                      o.dataProvider.includes(selectedDataSource.dataProvider)
                    ).map((credential) => {
                      const hasCredentialAccess =
                        credential.owners.includes(userObject.id) ||
                        credential.editors.includes(userObject.id);

                      return (
                        <ListItem
                          button
                          disabled={
                            hasCredentialAccess === false ||
                            (selectedDataSource.credential &&
                              selectedDataSource.credential.id ===
                                credential.id)
                          }
                          key={credential.id}
                          onClick={() => {
                            setSelectedCredential(credential);
                            setActiveConnectStep(
                              (prevActiveStep) => prevActiveStep + 1
                            );
                          }}
                        >
                          <ListItemAvatar>
                            <Avatar src={credential.picture} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={credential.name}
                            secondary={
                              <>
                                <Typography
                                  className={classes.inline}
                                  color="textSecondary"
                                  component="span"
                                  variant="body2"
                                >
                                  {credential.email}
                                </Typography>
                                {hasCredentialAccess === false && (
                                  <>
                                    <br />
                                    <Typography
                                      className={classes.inline}
                                      color="textSecondary"
                                      component="span"
                                      variant="caption"
                                    >
                                      No access, request access or add
                                      credential again
                                    </Typography>
                                  </>
                                )}

                                {selectedDataSource.credential &&
                                  selectedDataSource.credential.id ===
                                    credential.id && (
                                    <>
                                      <br />
                                      <Typography
                                        className={classes.inline}
                                        color="textSecondary"
                                        component="span"
                                        variant="caption"
                                      >
                                        {t('dataSourceIsUsingCredential')}
                                      </Typography>
                                    </>
                                  )}
                              </>
                            }
                          />
                          <ListItemSecondaryAction>
                            <ArrowForwardIos
                              fontSize="small"
                              htmlColor={theme.palette.text.secondary}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                  </List>
                </>
              )}

              {activeConnectStep === 1 && (
                <>
                  <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    justifyItems="center"
                    margin="10px 0px"
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Card>
                        <Box display="flex" flexDirection="row" padding="8px">
                          <Box
                            alignItems="center"
                            display="flex"
                            flexDirection="row"
                            marginRight="12px"
                          >
                            {selectedDataSource.credential ? (
                              <Avatar
                                src={selectedDataSource.credential.picture}
                              />
                            ) : (
                              <Error htmlColor={red[500]} />
                            )}
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            flexGrow={2}
                          >
                            {selectedDataSource.credential ? (
                              <>
                                <Typography
                                  className={classes.inline}
                                  color="textPrimary"
                                  component="span"
                                  variant="body1"
                                >
                                  {selectedDataSource.credential.name}
                                </Typography>
                                <Typography
                                  className={classes.inline}
                                  color="textSecondary"
                                  component="span"
                                  variant="body2"
                                >
                                  {selectedDataSource.credential.email}
                                </Typography>
                              </>
                            ) : (
                              <>
                                <Typography
                                  className={classes.inline}
                                  color="textPrimary"
                                  component="span"
                                  noWrap
                                  variant="body1"
                                >
                                  No Credential
                                </Typography>
                                <Typography
                                  className={classes.inline}
                                  color="textSecondary"
                                  component="span"
                                  variant="body2"
                                >
                                  {t('switchCredential')}
                                </Typography>
                              </>
                            )}
                          </Box>
                        </Box>
                      </Card>

                      <Box
                        alignItems="center"
                        display="flex"
                        justifyItems="center"
                        margin="10px"
                      >
                        {profileIsLoading === true ? (
                          <CircularProgress size={20} />
                        ) : foundProfile === FOUND_PROFILE ? (
                          <>
                            <ArrowForward />
                            <InsertEmoticon htmlColor={green[500]} />
                          </>
                        ) : (
                          <>
                            <ArrowForward />
                            <Error htmlColor={red[500]} />
                          </>
                        )}
                      </Box>
                      <Card>
                        <Box display="flex" flexDirection="row" padding="8px">
                          <Box
                            alignItems="center"
                            display="flex"
                            flexDirection="row"
                            marginRight="12px"
                          >
                            <Avatar src={selectedCredential.picture} />
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            flexGrow={2}
                          >
                            <Typography
                              className={classes.inline}
                              color="textPrimary"
                              component="span"
                              variant="body1"
                            >
                              {selectedCredential.name}
                            </Typography>
                            <Typography
                              className={classes.inline}
                              color="textSecondary"
                              component="span"
                              variant="body2"
                            >
                              {selectedCredential.email}
                            </Typography>
                          </Box>
                        </Box>
                      </Card>
                    </Box>
                    {foundProfile === NOT_FOUND_PROFILE && (
                      <Box margin="10px">
                        <Box
                          alignItems="center"
                          display="flex"
                          flexDirection="column"
                        >
                          <Typography color="textSecondary" variant="caption">
                            {t('DSNotFound')}
                          </Typography>
                          <Box>
                            <IconButton
                              onClick={async () => {
                                await hasAccess(selectedCredential);
                              }}
                              size="large"
                            >
                              <Refresh />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </>
              )}
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={closeDialog}>
            {t('Close')}
          </Button>

          <Button
            className={buttonClassname}
            color="primary"
            disabled={foundProfile !== FOUND_PROFILE}
            onClick={() => {
              switchCredential(selectedCredential);
            }}
            type="submit"
            variant="contained"
          >
            {success && <Icon>check</Icon>}

            {isSubmitting === true ? (
              <CircularProgress className={classes.buttonProgress} size={24} />
            ) : (
              t('switchCredential')
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ChangeCredentialForDataSource;
