import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material';
import { green } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import updateTeamObject from '../../api/updateTeamObject';
import * as types from '../../constants/ActionTypes';
import * as mutations from '../../graphql-custom/mutations';
import TeamAvatar from './TeamAvatar';
import TeamUploadAvatarDialog from './TeamUploadAvatarDialog';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  actionsContainer: {
    padding: '18px 24px',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },

  buttonProgress: {
    color: green[500],
  },
}));

function UpdateTeamDialog({ onClose = () => {} }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation('common');

  // redux selectors
  const selectedTeam = useSelector((state) => state.selectedTeam);
  const dialogs = useSelector((state) => state.dialogs);

  const open = dialogs.showUpdateTeamDialog;

  // local state
  const [success, setSuccess] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [avatarImageSrc, setAvatarImageSrc] = useState('');

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  function closeDialog() {
    dispatch({
      type: types.SHOW_UPDATE_TEAM_DIALOG,
      isVisible: false,
    });
    onClose();
  }

  useEffect(() => {
    async function getS3AvatarLink() {
      const result = await Storage.get(selectedTeam.avatar.file.key);
      setAvatarImageSrc(result);
    }

    if (selectedTeam && selectedTeam.avatar) {
      getS3AvatarLink();
    }

    if (selectedTeam) {
      if (!selectedTeam.avatar) {
        setAvatarImageSrc('');
      }
    }
  }, [selectedTeam]); // Onl

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  if (!selectedTeam) {
    return <></>;
  }

  return (
    <>
      {openDialog === true && (
        <TeamUploadAvatarDialog
          closeDialog={handleDialogClose}
          handleClose={handleDialogClose}
          open={openDialog}
          selectedTeam={selectedTeam}
        />
      )}

      <Dialog
        aria-describedby="update-team-dialog-description"
        aria-labelledby="update-team-dialog-title"
        fullWidth
        maxWidth="xs"
        onClose={closeDialog}
        open={open}
      >
        <DialogTitle id="update-team-dialog-title">
          {t('editWorkspace')}
        </DialogTitle>
        <DialogContent>
          <Box width="100%">
            <Typography color="textSecondary" gutterBottom variant="subtitle2">
              {t('workspaceIcon')}
            </Typography>
          </Box>

          <Box display="flex" flexDirection="row">
            <Box p={2}>
              {avatarImageSrc && (
                <TeamAvatar
                  avatar={selectedTeam.avatar}
                  id={selectedTeam.id}
                  name={selectedTeam.name}
                  size="big"
                />
              )}
            </Box>

            <Box display="flex" flexDirection="column" justifyContent="center">
              <Box>
                <Button
                  className={classes.avatarButtons}
                  fullWidth
                  onClick={handleDialogOpen}
                  variant="outlined"
                >
                  {t('uploadNewIcon')}
                </Button>
              </Box>
              {avatarImageSrc && (
                <Box>
                  <Button
                    className={classes.avatarButtons}
                    fullWidth
                    onClick={async () => {
                      const updateTeamObjectNoAvatar = await API.graphql(
                        graphqlOperation(mutations.updateTeam, {
                          input: {
                            id: selectedTeam.id,
                            avatar: null,
                          },
                        })
                      );

                      // update redux with the updated team
                      dispatch({
                        type: types.UPDATE_USER_TEAM,
                        team: updateTeamObjectNoAvatar.data.updateTeam,
                      });

                      dispatch({
                        type: types.SET_SELECTED_TEAM,
                        team: updateTeamObjectNoAvatar.data.updateTeam,
                      });
                    }}
                    variant="outlined"
                  >
                    {t('deleteIcon')}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </DialogContent>

        <Formik
          initialValues={{
            name: selectedTeam.name,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const updatedTeam = await updateTeamObject(
              selectedTeam.id,
              values.name
            );
            dispatch({
              type: types.UPDATE_USER_TEAM,
              team: updatedTeam,
            });

            dispatch({
              type: types.SET_SELECTED_TEAM,
              team: updatedTeam,
            });

            setSubmitting(false);
            setSuccess(true);
            setTimeout(() => {
              setSuccess(false);
            }, 3000);
          }}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = 'Required field';
            }
            return errors;
          }}
        >
          {(props) => (
            <Form>
              <DialogContent className={classes.container}>
                <TextField
                  error={props.errors.name !== undefined}
                  helperText={props.errors.name}
                  label={t('workspaceName')}
                  margin="dense"
                  name="name"
                  onBlur={props.handleBlur}
                  onChange={props.handleChange}
                  type="name"
                  value={props.values.name}
                  variant="filled"
                />

                {props.isSubmitting && <LinearProgress />}

                <Box mt={2}>
                  <Button
                    className={buttonClassname}
                    color="primary"
                    disabled={
                      props.isSubmitting ||
                      props.values.name === selectedTeam.name
                    }
                    onClick={props.submitForm}
                    type="submit"
                    variant="contained"
                  >
                    {success && <Icon>check</Icon>}
                    {props.isSubmitting === true ? (
                      <CircularProgress
                        className={classes.buttonProgress}
                        size={24}
                      />
                    ) : (
                      t('updateWorkspace')
                    )}
                  </Button>
                </Box>
              </DialogContent>
              <DialogActions className={classes.actionsContainer}>
                <Button
                  color="primary"
                  onClick={() => {
                    closeDialog();
                  }}
                >
                  {t('Close')}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default UpdateTeamDialog;
