import { Language } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { green } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import createLabelIfNotExist from '../../api/createLabelIfNotExist';
import * as lTypes from '../../constants/LabelTypes';
import getHostName from '../../utilities/getHostName';
import isValidUrl from '../../utilities/isValidUrl';
import useDebounce from '../../utilities/useDebounce';
import DataSourceLabel from './DataSourceLabel';

const useStyles = makeStyles(() => ({
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
  },
}));

function NewLabelDialog({
  showNewDialog,
  setShowNewDialog,
  selectedUserTeam,
  closePopper = () => {},
  createdCallBack = async () => {},
}) {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const [urlLabel, setUrlLabel] = useState('');
  const [label, setLabel] = useState('');
  const [urlIsValid, setUrlIsValid] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const debouncedUrlLabel = useDebounce(urlLabel, 1000);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (
      !debouncedUrlLabel.startsWith('http://') &&
      !debouncedUrlLabel.startsWith('https://')
    ) {
      if (isValidUrl(`https://${debouncedUrlLabel}`)) {
        setUrlLabel(`https://${debouncedUrlLabel}`);
      }
    }

    if (isValidUrl(debouncedUrlLabel)) {
      setUrlIsValid(true);
      setLabel(getHostName(debouncedUrlLabel));
    } else {
      setUrlIsValid(false);
    }
  }, [debouncedUrlLabel]);

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={() => {
        setShowNewDialog(false);
      }}
      open={showNewDialog}
    >
      <DialogTitle>{t('newDomainLabel')}</DialogTitle>
      <DialogContent>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Language />
              </InputAdornment>
            ),
          }}
          error={urlIsValid === false}
          fullWidth
          label="Please enter a url of a website"
          onChange={(event) => {
            setLabel('');
            setUrlIsValid(true);
            setUrlLabel(event.target.value);
          }}
          placeholder="https://www.example.com"
          value={urlLabel}
          variant="filled"
        />

        {label && (
          <Box mt={2} width="100%">
            <Paper variant="outlined">
              <Box display="flex" flexDirection="column" p={2} width="100%">
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="overline"
                >
                  Preview
                </Typography>
                <Box>
                  <DataSourceLabel
                    deletable={false}
                    name={label}
                    type="WEBSITE"
                  />
                </Box>
              </Box>
            </Paper>
          </Box>
        )}
        <Box mt={2}>
          <Alert severity="info">
            <b>Custom Labels</b> will be available at later date
          </Alert>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            setShowNewDialog(false);
          }}
        >
          Cancel
        </Button>
        <Button
          autoFocus
          className={buttonClassname}
          color="primary"
          disabled={label === ''}
          onClick={async () => {
            setIsSubmitting(true);
            const labelObj = await createLabelIfNotExist(
              label,
              label,
              lTypes.WEBSITE,
              [],
              selectedUserTeam
            );

            await createdCallBack(labelObj);

            setIsSubmitting(false);
            setSuccess(true);

            setTimeout(() => {
              setShowNewDialog(false);
              closePopper();
            }, 500);
          }}
          variant="contained"
        >
          {success && <Icon>check</Icon>}

          {isSubmitting === true ? (
            <CircularProgress className={classes.buttonProgress} size={24} />
          ) : (
            'Create'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NewLabelDialog;
