/* eslint-disable no-nested-ternary */
import { InfoOutlined, MoreVert, Search } from '@mui/icons-material';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Stack,
  Switch,
  Toolbar,
  Typography,
} from '@mui/material';

import { alpha } from '@mui/material/styles';
import { API, graphqlOperation } from 'aws-amplify';
import FuzzySearch from 'fuzzy-search';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _groupBy from 'lodash/groupBy';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import querystring from 'querystring';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch, useSelector } from 'react-redux';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import deleteCredentialObject from '../../api/deleteCredentialObject';
import * as types from '../../constants/ActionTypes';
import dataSourceIcon from '../../data-source-icon.svg';
import avatarCredentialColors from '../../utilities/avatarCredentialColors';
import getInitials from '../../utilities/getInitials';
import numberFromText from '../../utilities/numberFromText';
import AddCredentialButton from '../data/AddCredentialButton';
import DataProviderChip from '../data/DataProviderChip';

const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      credentials(limit: 300) {
        items {
          id
          name
          email
          dataProvider
          editors
          owners
          status
          picture
          dataSources {
            items {
              name
              displayName
              tags
              id
            }
          }
        }
      }
    }
  }
`;

function ManageCredentialsDialog() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { workspaceId } = useParams();
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [query, setQuery] = useState('');
  const [credentialsGrouped, setCredentialsGrouped] = useState({});
  const [credentials, setCredentials] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [filterInvalid, setFilterInvalid] = useState(
    searchParams.get('invalidCredentials') === 'true'
  );

  // redux selectors
  const userObject = useSelector((state) => state.userObject);
  const selectedUserTeam = useSelector((state) => state.selectedUserTeam);
  const userTeams = useSelector((state) => state.userTeams);
  const credentialsByTeam = useSelector((state) => state.credentialsByTeam);

  async function getAllCredentials() {
    let selectedUserTeamId = selectedUserTeam;

    if (searchParams.get('workspaceId')) {
      if (
        _find(userTeams, {
          id: searchParams.get('workspaceId'),
        })
      ) {
        selectedUserTeamId = searchParams.get('workspaceId');
      }
      const selectedUserTeamNew = _find(userTeams, {
        id: selectedUserTeamId,
      });
      setSelectedTeam(selectedUserTeamNew);
    }

    const credentialsQueryObject = await API.graphql(
      graphqlOperation(getTeam, {
        id: selectedUserTeamId,
        limit: 300,
        nextToken: undefined,
      })
    );

    const credentials2 = credentialsQueryObject.data.getTeam.credentials.items;
    setCredentials(credentials2);
  }

  useEffect(() => {
    getAllCredentials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentialsByTeam]);

  useEffect(() => {
    let filteredCredentials = [...credentials];

    if (
      filterInvalid &&
      _filter(credentials, (o) => o.status === 'INVALID').length > 0
    ) {
      filteredCredentials = _filter(credentials, (o) => o.status === 'INVALID');
    }

    const fuse = new FuzzySearch(
      filteredCredentials,
      ['name', 'email', 'dataProvider'],
      {
        includeScore: true,
      }
    );

    const result = fuse.search(query);

    const groupedBysDataProvider = _groupBy(result, 'dataProvider');
    setCredentialsGrouped(groupedBysDataProvider);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials, query, filterInvalid]);

  function onClose() {
    searchParams.delete('workspaceId');
    searchParams.delete('invalidCredentials');
    navigate({
      pathname: `/workspace/${workspaceId}/data-sources`,
      search: searchParams.toString(),
    });
  }

  function closeDialog() {
    onClose();
  }

  if (!selectedTeam) {
    return null;
  }

  if (userTeams.length === 0) {
    return null;
  }

  const userIsAdmin = selectedTeam.admins.includes(userObject.id);

  return (
    <Dialog fullWidth maxWidth="sm" onClose={closeDialog} open>
      <AppBar
        color="default"
        elevation={0}
        position="static"
        sx={(theme) => ({
          bgcolor: theme.palette.background.paper,
          borderBottom: `1px solid ${theme.palette.action.disabled}`,
        })}
      >
        <Toolbar variant="regular">
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
          >
            <Box />

            <Typography align="center" color="inherit" variant="h6">
              {t('manageCredentials')}
            </Typography>
            <Box />
          </Box>
        </Toolbar>
      </AppBar>

      <Box>
        <AppBar
          color="default"
          elevation={0}
          position="static"
          sx={(theme) => ({
            borderBottom: `1px solid ${theme.palette.action.disabled}`,
          })}
        >
          <Toolbar
            sx={(theme) => ({
              height: '50px',
              minHeight: '50px',
              paddingLeft: theme.spacing(1),
              paddingRight: theme.spacing(1),
            })}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              width="100%"
            >
              <InputBase
                autoFocus
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
                placeholder={t('filterCredentials')}
                sx={(theme) => ({
                  marginLeft: theme.spacing(1),
                  flex: 1,
                })}
                type="search"
                value={query}
              />

              <Box p={2}>
                <Search color="action" />
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>

      {!userIsAdmin && (
        <Box display="flex" flexDirection="row" justifyContent="center">
          <Chip
            color="primary"
            icon={<InfoOutlined />}
            label="You are not an Admin"
            variant="outlined"
          />
        </Box>
      )}

      <DialogContent>
        {query !== '' && Object.keys(credentialsGrouped).length === 0 && (
          <Box sx={{ mt: 4 }}>
            <Typography align="center" color="textPrimary" variant="subtitle1">
              No credential found with the name "{query}"
            </Typography>
            <Typography
              align="center"
              color="textSecondary"
              display="block"
              gutterBottom
              variant="caption"
            >
              Clear or remove a filter
            </Typography>
          </Box>
        )}

        {_filter(credentials, (o) => o.status === 'INVALID').length > 0 && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              my: 1,
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={filterInvalid}
                  color="secondary"
                  onChange={(event) => {
                    searchParams.set(
                      'invalidCredentials',
                      event.target.checked
                    );
                    setSearchParams(searchParams);
                    setFilterInvalid(event.target.checked);
                  }}
                />
              }
              disabled={
                _filter(credentials, (o) => o.status === 'INVALID').length === 0
              }
              label="Show only invalid credentials"
              labelPlacement="end"
              value="end"
            />
          </Box>
        )}

        <List
          subheader={<li />}
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
            position: 'relative',
            overflow: 'auto',
            maxHeight: 500,
            '& ul': { padding: 0 },
          }}
        >
          {Object.keys(credentialsGrouped)
            .sort()
            .map((dataProviderKey) => {
              const credentialItems = credentialsGrouped[dataProviderKey];

              const dataProviders = dataProviderKey.split(',');

              return (
                <li key={`section-${dataProviderKey}`}>
                  <ul>
                    <ListSubheader sx={{ mt: 2, p: 1 }}>
                      <Stack direction="row" spacing={1}>
                        {dataProviders.map((dp) => (
                          <DataProviderChip dataProvider={dp} key={dp} />
                        ))}
                      </Stack>
                    </ListSubheader>

                    {credentialItems.map((credential) => (
                      <ListItem
                        key={credential.id}
                        secondaryAction={
                          <PopupState
                            popupId="demo-popup-menu"
                            variant="popover"
                          >
                            {(popupState) => (
                              <>
                                <IconButton
                                  edge="end"
                                  {...bindTrigger(popupState)}
                                >
                                  <MoreVert />
                                </IconButton>
                                <Menu {...bindMenu(popupState)}>
                                  <MenuItem
                                    disabled={
                                      credential.dataSources.items.length === 0
                                    }
                                    onClick={() => {
                                      const queryObj = querystring.parse(
                                        location.search.slice(1)
                                      );
                                      const newWorkspaceId =
                                        queryObj.workspaceId.split('-')[0];
                                      if (queryObj.workspaceId) {
                                        delete queryObj.workspaceId;
                                      }

                                      queryObj.credentials = credential.id;

                                      navigate(
                                        {
                                          pathname: `/workspace/${newWorkspaceId}/data-sources`,
                                          search:
                                            querystring.stringify(queryObj),
                                        },
                                        { replace: true }
                                      );
                                      popupState.close();
                                    }}
                                  >
                                    <Box display="flex" flexDirection="column">
                                      <Box component="span">
                                        View Connected Data Sources
                                      </Box>
                                      {credential.dataSources.items.length ===
                                        0 && (
                                        <Box component="span">
                                          <Typography variant="caption">
                                            No Data Sources connected
                                          </Typography>
                                        </Box>
                                      )}
                                    </Box>
                                  </MenuItem>

                                  {process.env.REACT_APP_USER_BRANCH !==
                                    'prod' && (
                                    <MenuItem
                                      disabled={userIsAdmin === false}
                                      onClick={async () => {
                                        // eslint-disable-next-line no-alert
                                        prompt('Credential ID:', credential.id);
                                      }}
                                    >
                                      <Box
                                        display="flex"
                                        flexDirection="column"
                                      >
                                        <Box component="span">
                                          Copy Credential Id
                                        </Box>
                                        <Box component="span">
                                          <Typography
                                            color={'textSecondary'}
                                            variant="caption"
                                          >
                                            Only available in Develop Mode
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </MenuItem>
                                  )}
                                  <MenuItem
                                    disabled={userIsAdmin === false}
                                    onClick={async () => {
                                      const deletedCredential =
                                        await deleteCredentialObject(
                                          credential
                                        );

                                      batch(() => {
                                        dispatch({
                                          type: types.DELETE_TEAM_CREDENTIAL,
                                          credential: deletedCredential,
                                          teamId: selectedTeam.id,
                                        });
                                      });
                                      popupState.close();
                                    }}
                                  >
                                    Revoke Access
                                  </MenuItem>
                                </Menu>
                              </>
                            )}
                          </PopupState>
                        }
                        sx={(theme) => ({
                          background:
                            credential.status === 'INVALID'
                              ? alpha(theme.palette.error.light, 0.2)
                              : 'inherit',
                        })}
                      >
                        <ListItemAvatar>
                          <Avatar
                            src={credential.picture}
                            sx={(theme) => ({
                              color: theme.palette.getContrastText(
                                avatarCredentialColors[
                                  numberFromText(getInitials(credential.name)) %
                                    avatarCredentialColors.length
                                ]
                              ),
                              bgcolor:
                                avatarCredentialColors[
                                  numberFromText(getInitials(credential.name)) %
                                    avatarCredentialColors.length
                                ],
                            })}
                          >
                            {getInitials(credential.name)}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={credential.name}
                          secondary={`${credential.email}`}
                          sx={(theme) => ({
                            color:
                              credential.status === 'INVALID'
                                ? theme.palette.error.main
                                : 'inherit',
                          })}
                        />

                        {credential.status === 'INVALID' ? (
                          <>
                            <AddCredentialButton
                              email={credential.email}
                              reAuthenticate={true}
                              selectedDataProvider={dataProviders[0]}
                              variant="error"
                            />
                          </>
                        ) : (
                          <Chip
                            avatar={<Avatar src={dataSourceIcon} />}
                            disabled={credential.dataSources.items.length === 0}
                            label={credential.dataSources.items.length}
                            sx={{
                              ml: 2,
                              fontWeight: 700,
                            }}
                            variant="outlined"
                          />
                        )}
                      </ListItem>
                    ))}
                  </ul>
                </li>
              );
            })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={closeDialog}>
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ManageCredentialsDialog;
