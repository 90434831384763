import { InfoOutlined, Search } from '@mui/icons-material';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import {
  AppBar,
  Avatar,
  Box,
  CardContent,
  Chip,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import FuzzySearch from 'fuzzy-search';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import dataSourceIcon from '../../data-source-icon.svg';

function DataProviderPicker({
  setSelectedDataProvider,
  setActiveConnectStep,
  integration = false,
}) {
  const theme2 = useTheme();
  const { t } = useTranslation('common');

  const [query, setQuery] = useState('');

  const dataProviderSettings = useSelector(
    (state) => state.dataProviderSettings
  );

  const fuse = new FuzzySearch(
    dataProviderSettings.availableDataProviders.filter((dp) => {
      if (integration) {
        return dataProviderSettings.config[dp].supportIntegrations ?? false;
      }
      return (
        dataProviderSettings.config[dp].supportDataSources !== false ||
        dataProviderSettings.config[dp].supportIntegrations
      );
    }),
    {
      includeScore: true,
    }
  );

  const result = fuse.search(query);

  return (
    <>
      <Box>
        <AppBar
          color="default"
          elevation={0}
          position="static"
          sx={(theme) => ({
            borderBottom: `1px solid ${theme.palette.action.disabled}`,
          })}
        >
          <Toolbar
            sx={(theme) => ({
              height: '50px',
              minHeight: '50px',
              paddingLeft: theme.spacing(1),
              paddingRight: theme.spacing(1),
            })}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              width="100%"
            >
              <InputBase
                autoFocus
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
                placeholder={t('filterConnectors')}
                sx={(theme) => ({
                  marginLeft: theme.spacing(1),
                  flex: 1,
                })}
                type="search"
                value={query}
              />

              <Box p={2}>
                <Search color="action" />
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
      <Box maxHeight="350px" overflow="auto">
        <CardContent>
          {result.length === 0 ? (
            <Box sx={{ mt: 4 }}>
              <Box display="flex" justifyContent="center">
                <Avatar src={dataSourceIcon} sx={{ width: 30, height: 30 }} />
              </Box>
              <Typography
                align="center"
                color="textPrimary"
                variant="subtitle1"
              >
                No Connectors found with the name "{query}"
              </Typography>
              <Typography
                align="center"
                color="textSecondary"
                display="block"
                gutterBottom
                variant="caption"
              >
                Clear or remove a filter
              </Typography>
            </Box>
          ) : (
            <List>
              {result.map((resultItem) => (
                <ListItem
                  button
                  key={resultItem}
                  onClick={() => {
                    setSelectedDataProvider(resultItem);
                    setActiveConnectStep(
                      (prevActiveStep) => prevActiveStep + 1
                    );
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      src={dataProviderSettings.config[resultItem].image}
                      variant="rounded"
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={dataProviderSettings.config[resultItem].name}
                  />

                  <ListItemSecondaryAction>
                    <ArrowForwardIos
                      fontSize="small"
                      htmlColor={theme2.palette.text.secondary}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          )}
        </CardContent>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        sx={{ m: 2 }}
      >
        <Tooltip title="Tutorial: How to create a Data Source">
          <>
            <Chip
              clickable
              color="info"
              component="a"
              href="https://datahub.gladior.com/tutorial"
              icon={<InfoOutlined />}
              label="Tutorial"
              size="small"
              target="_blank"
              variant="outlined"
            />
          </>
        </Tooltip>
      </Box>
    </>
  );
}

export default DataProviderPicker;
