/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const generateLoginCode = /* GraphQL */ `
  mutation GenerateLoginCode {
    generateLoginCode {
      code
      __typename
    }
  }
`;
export const revokeAccessWorkspace = /* GraphQL */ `
  mutation RevokeAccessWorkspace($teamId: String, $memberId: String) {
    revokeAccessWorkspace(teamId: $teamId, memberId: $memberId)
  }
`;
export const adminPanel = /* GraphQL */ `
  mutation AdminPanel($action: String) {
    adminPanel(action: $action)
  }
`;
export const joinTeam = /* GraphQL */ `
  mutation JoinTeam($teamId: String, $inviteId: String, $action: String) {
    joinTeam(teamId: $teamId, inviteId: $inviteId, action: $action)
  }
`;
export const inviteTeamMember = /* GraphQL */ `
  mutation InviteTeamMember(
    $email: String
    $teamId: String
    $message: String
    $notifyPeople: Boolean
    $permission: TeamPermission
  ) {
    inviteTeamMember(
      email: $email
      teamId: $teamId
      message: $message
      notifyPeople: $notifyPeople
      permission: $permission
    )
  }
`;
export const removeWorkspace = /* GraphQL */ `
  mutation RemoveWorkspace($teamId: String) {
    removeWorkspace(teamId: $teamId)
  }
`;
export const removeDataSource = /* GraphQL */ `
  mutation RemoveDataSource($dataSourceId: String) {
    removeDataSource(dataSourceId: $dataSourceId)
  }
`;
export const removeCredential = /* GraphQL */ `
  mutation RemoveCredential($credentialId: String) {
    removeCredential(credentialId: $credentialId)
  }
`;
export const removeIntegration = /* GraphQL */ `
  mutation RemoveIntegration($integrationId: String) {
    removeIntegration(integrationId: $integrationId)
  }
`;
export const removeFeedTemplate = /* GraphQL */ `
  mutation RemoveFeedTemplate($feedId: String) {
    removeFeedTemplate(feedId: $feedId)
  }
`;
export const setUpNewTeam = /* GraphQL */ `
  mutation SetUpNewTeam($templateId: String) {
    setUpNewTeam(templateId: $templateId)
  }
`;
export const createNewTeam = /* GraphQL */ `
  mutation CreateNewTeam(
    $teamName: String
    $autoInviteDomains: Boolean
    $makeDefault: Boolean
  ) {
    createNewTeam(
      teamName: $teamName
      autoInviteDomains: $autoInviteDomains
      makeDefault: $makeDefault
    )
  }
`;
export const removeLabelFromDataSource = /* GraphQL */ `
  mutation RemoveLabelFromDataSource(
    $labelId: String
    $dataSourceId: String
    $teamId: String
  ) {
    removeLabelFromDataSource(
      labelId: $labelId
      dataSourceId: $dataSourceId
      teamId: $teamId
    )
  }
`;
export const addLabelToDataSource = /* GraphQL */ `
  mutation AddLabelToDataSource(
    $labelId: String
    $dataSourceId: String
    $teamId: String
  ) {
    addLabelToDataSource(
      labelId: $labelId
      dataSourceId: $dataSourceId
      teamId: $teamId
    )
  }
`;
export const createTeamInvite = /* GraphQL */ `
  mutation CreateTeamInvite(
    $input: CreateTeamInviteInput!
    $condition: ModelTeamInviteConditionInput
  ) {
    createTeamInvite(input: $input, condition: $condition) {
      id
      userId
      inviteType
      adminId
      admin {
        id
        avatar {
          visibility
          identityId
          file {
            bucket
            key
            region
            fileName
            __typename
          }
          __typename
        }
        name
        email
        __typename
      }
      email
      status
      inviteLink {
        id
        teamId
        admin
        createdAt
        updatedAt
        __typename
      }
      teamId
      team {
        name
        avatar {
          visibility
          identityId
          file {
            bucket
            key
            region
            fileName
            __typename
          }
          __typename
        }
        emailDomain
        membersCount
        members {
          id
          avatar {
            visibility
            identityId
            file {
              bucket
              key
              region
              fileName
              __typename
            }
            __typename
          }
          __typename
        }
        colorTheme {
          primaryColor
          secondaryColor
          __typename
        }
        __typename
      }
      permission
      createdAt
      updatedAt
      teamInviteInviteLinkId
      __typename
    }
  }
`;
export const updateTeamInvite = /* GraphQL */ `
  mutation UpdateTeamInvite(
    $input: UpdateTeamInviteInput!
    $condition: ModelTeamInviteConditionInput
  ) {
    updateTeamInvite(input: $input, condition: $condition) {
      id
      userId
      inviteType
      adminId
      admin {
        id
        avatar {
          visibility
          identityId
          file {
            bucket
            key
            region
            fileName
            __typename
          }
          __typename
        }
        name
        email
        __typename
      }
      email
      status
      inviteLink {
        id
        teamId
        admin
        createdAt
        updatedAt
        __typename
      }
      teamId
      team {
        name
        avatar {
          visibility
          identityId
          file {
            bucket
            key
            region
            fileName
            __typename
          }
          __typename
        }
        emailDomain
        membersCount
        members {
          id
          avatar {
            visibility
            identityId
            file {
              bucket
              key
              region
              fileName
              __typename
            }
            __typename
          }
          __typename
        }
        colorTheme {
          primaryColor
          secondaryColor
          __typename
        }
        __typename
      }
      permission
      createdAt
      updatedAt
      teamInviteInviteLinkId
      __typename
    }
  }
`;
export const deleteTeamInvite = /* GraphQL */ `
  mutation DeleteTeamInvite(
    $input: DeleteTeamInviteInput!
    $condition: ModelTeamInviteConditionInput
  ) {
    deleteTeamInvite(input: $input, condition: $condition) {
      id
      userId
      inviteType
      adminId
      admin {
        id
        avatar {
          visibility
          identityId
          file {
            bucket
            key
            region
            fileName
            __typename
          }
          __typename
        }
        name
        email
        __typename
      }
      email
      status
      inviteLink {
        id
        teamId
        admin
        createdAt
        updatedAt
        __typename
      }
      teamId
      team {
        name
        avatar {
          visibility
          identityId
          file {
            bucket
            key
            region
            fileName
            __typename
          }
          __typename
        }
        emailDomain
        membersCount
        members {
          id
          avatar {
            visibility
            identityId
            file {
              bucket
              key
              region
              fileName
              __typename
            }
            __typename
          }
          __typename
        }
        colorTheme {
          primaryColor
          secondaryColor
          __typename
        }
        __typename
      }
      permission
      createdAt
      updatedAt
      teamInviteInviteLinkId
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      avatar {
        visibility
        identityId
        file {
          bucket
          key
          region
          fileName
          __typename
        }
        __typename
      }
      userType
      defaultTeamId
      userSettings {
        onboardingCompleted
        releaseNotes
        __typename
      }
      teams {
        items {
          id
          teamMembershipUserId
          user {
            id
            username
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            userType
            defaultTeamId
            userSettings {
              onboardingCompleted
              releaseNotes
              __typename
            }
            teams {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          teamMembershipTeamId
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      avatar {
        visibility
        identityId
        file {
          bucket
          key
          region
          fileName
          __typename
        }
        __typename
      }
      userType
      defaultTeamId
      userSettings {
        onboardingCompleted
        releaseNotes
        __typename
      }
      teams {
        items {
          id
          teamMembershipUserId
          user {
            id
            username
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            userType
            defaultTeamId
            userSettings {
              onboardingCompleted
              releaseNotes
              __typename
            }
            teams {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          teamMembershipTeamId
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      avatar {
        visibility
        identityId
        file {
          bucket
          key
          region
          fileName
          __typename
        }
        __typename
      }
      userType
      defaultTeamId
      userSettings {
        onboardingCompleted
        releaseNotes
        __typename
      }
      teams {
        items {
          id
          teamMembershipUserId
          user {
            id
            username
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            userType
            defaultTeamId
            userSettings {
              onboardingCompleted
              releaseNotes
              __typename
            }
            teams {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          teamMembershipTeamId
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTeamMembership = /* GraphQL */ `
  mutation CreateTeamMembership(
    $input: CreateTeamMembershipInput!
    $condition: ModelTeamMembershipConditionInput
  ) {
    createTeamMembership(input: $input, condition: $condition) {
      id
      teamMembershipUserId
      user {
        id
        username
        avatar {
          visibility
          identityId
          file {
            bucket
            key
            region
            fileName
            __typename
          }
          __typename
        }
        userType
        defaultTeamId
        userSettings {
          onboardingCompleted
          releaseNotes
          __typename
        }
        teams {
          items {
            id
            teamMembershipUserId
            user {
              id
              username
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              userType
              defaultTeamId
              userSettings {
                onboardingCompleted
                releaseNotes
                __typename
              }
              teams {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            teamMembershipTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            owner
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      teamMembershipTeamId
      team {
        id
        avatar {
          visibility
          identityId
          file {
            bucket
            key
            region
            fileName
            __typename
          }
          __typename
        }
        name
        owner
        members
        admins
        editors
        emailDomains
        autoInviteDomains
        teamMembers {
          items {
            id
            teamMembershipUserId
            user {
              id
              username
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              userType
              defaultTeamId
              userSettings {
                onboardingCompleted
                releaseNotes
                __typename
              }
              teams {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            teamMembershipTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            owner
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dataSources {
          items {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          nextToken
          __typename
        }
        credentials {
          items {
            id
            name
            email
            picture
            status
            owners
            editors
            createdAt
            updatedAt
            credentialTeamId
            authType
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            dataProvider
            groups
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            credentialLabelId
            authDataId
            clientId
            authData {
              accessToken
              refreshToken
              scope
              tokenType
              idToken
              expiryDate
              username
              password
              token
              key
              environment
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        dataSourceLabels {
          items {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        defaultTemplateId
        workspaceType
        createdAt
        updatedAt
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTeamMembership = /* GraphQL */ `
  mutation UpdateTeamMembership(
    $input: UpdateTeamMembershipInput!
    $condition: ModelTeamMembershipConditionInput
  ) {
    updateTeamMembership(input: $input, condition: $condition) {
      id
      teamMembershipUserId
      user {
        id
        username
        avatar {
          visibility
          identityId
          file {
            bucket
            key
            region
            fileName
            __typename
          }
          __typename
        }
        userType
        defaultTeamId
        userSettings {
          onboardingCompleted
          releaseNotes
          __typename
        }
        teams {
          items {
            id
            teamMembershipUserId
            user {
              id
              username
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              userType
              defaultTeamId
              userSettings {
                onboardingCompleted
                releaseNotes
                __typename
              }
              teams {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            teamMembershipTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            owner
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      teamMembershipTeamId
      team {
        id
        avatar {
          visibility
          identityId
          file {
            bucket
            key
            region
            fileName
            __typename
          }
          __typename
        }
        name
        owner
        members
        admins
        editors
        emailDomains
        autoInviteDomains
        teamMembers {
          items {
            id
            teamMembershipUserId
            user {
              id
              username
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              userType
              defaultTeamId
              userSettings {
                onboardingCompleted
                releaseNotes
                __typename
              }
              teams {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            teamMembershipTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            owner
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dataSources {
          items {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          nextToken
          __typename
        }
        credentials {
          items {
            id
            name
            email
            picture
            status
            owners
            editors
            createdAt
            updatedAt
            credentialTeamId
            authType
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            dataProvider
            groups
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            credentialLabelId
            authDataId
            clientId
            authData {
              accessToken
              refreshToken
              scope
              tokenType
              idToken
              expiryDate
              username
              password
              token
              key
              environment
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        dataSourceLabels {
          items {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        defaultTemplateId
        workspaceType
        createdAt
        updatedAt
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
      avatar {
        visibility
        identityId
        file {
          bucket
          key
          region
          fileName
          __typename
        }
        __typename
      }
      name
      owner
      members
      admins
      editors
      emailDomains
      autoInviteDomains
      teamMembers {
        items {
          id
          teamMembershipUserId
          user {
            id
            username
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            userType
            defaultTeamId
            userSettings {
              onboardingCompleted
              releaseNotes
              __typename
            }
            teams {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          teamMembershipTeamId
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      dataSources {
        items {
          id
          owner
          editors
          admins
          createdAt
          updatedAt
          dataProvider
          displayName
          displayNameLowercase
          configParamsHash
          name
          nameLowercase
          tags
          tagsLowercase
          tagsLowercaseToString
          url
          hostname
          domain
          type
          view {
            viewId
            viewName
            propertyId
            websiteId
            loginCustomerId
            customerId
            site
            locationId
            channelId
            spreadsheetId
            accountId
            projectId
            dc
            connectorId
            dataSourceIds
            credentialIds
            merchantId
            isManager
            timeZone
            reportType
            url
            device
            type
            dataSourceId
            credentialId
            __typename
          }
          multipleViews {
            viewId
            viewName
            propertyId
            websiteId
            loginCustomerId
            customerId
            site
            locationId
            channelId
            spreadsheetId
            accountId
            projectId
            dc
            connectorId
            dataSourceIds
            credentialIds
            merchantId
            isManager
            timeZone
            reportType
            url
            device
            type
            dataSourceId
            credentialId
            __typename
          }
          dataUrls {
            items {
              id
              owner
              groups
              name
              nameLowercase
              status
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              createdAt
              updatedAt
              dataSourceDataUrlsId
              __typename
            }
            nextToken
            __typename
          }
          queries {
            items {
              id
              owner
              groups
              name
              nameLowercase
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              additionalFields {
                name
                value
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              feedTemplates {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  name
                  nameLowercase
                  root
                  default
                  conditions
                  queryId
                  iconUrl
                  publish
                  iconData
                  teamId
                  dataSourceId
                  parameters
                  __typename
                }
                nextToken
                __typename
              }
              queryTeamId
              iconUrl
              createdAt
              updatedAt
              dataSourceQueriesId
              __typename
            }
            nextToken
            __typename
          }
          preCacheRequests {
            items {
              id
              name
              nameLowercase
              fields
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              requestCount
              query
              TTL
              updatedAt
              createdAt
              dataSourcePreCacheRequestsId
              __typename
            }
            nextToken
            __typename
          }
          notificationActions {
            items {
              id
              owner
              groups
              name
              nameLowercase
              status
              notificationService
              template
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              schedule
              trigger
              notificationActionCredentialId
              createdAt
              updatedAt
              dataSourceNotificationActionsId
              __typename
            }
            nextToken
            __typename
          }
          integrations {
            items {
              id
              type
              dataProvider
              additionalSettings {
                name
                value
                __typename
              }
              query {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              integrationCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              integrationDataSourceId
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              createdAt
              updatedAt
              composerQueryIntegrationsId
              owner
              __typename
            }
            nextToken
            __typename
          }
          dataFreshness
          filter {
            isActive
            dimensionsFilters {
              fieldName
              values
              type
              operator
              __typename
            }
            segment {
              id
              name
              __typename
            }
            __typename
          }
          version
          rateLimit {
            max
            interval
            __typename
          }
          dataSourceCredentialId
          credential {
            id
            name
            email
            picture
            status
            owners
            editors
            createdAt
            updatedAt
            credentialTeamId
            authType
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            dataProvider
            groups
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            credentialLabelId
            authDataId
            clientId
            authData {
              accessToken
              refreshToken
              scope
              tokenType
              idToken
              expiryDate
              username
              password
              token
              key
              environment
              __typename
            }
            __typename
          }
          dataSourceTeamId
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          labels {
            items {
              id
              dataSourceID
              labelID
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              dataSourceLabelDataSourcesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          groups
          __typename
        }
        nextToken
        __typename
      }
      credentials {
        items {
          id
          name
          email
          picture
          status
          owners
          editors
          createdAt
          updatedAt
          credentialTeamId
          authType
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          dataProvider
          groups
          label {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          credentialLabelId
          authDataId
          clientId
          authData {
            accessToken
            refreshToken
            scope
            tokenType
            idToken
            expiryDate
            username
            password
            token
            key
            environment
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      dataSourceLabels {
        items {
          id
          name
          nameLowercase
          value
          type
          dataSourceLabelTeamId
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          createdAt
          dataProviders
          dataSources {
            items {
              id
              dataSourceID
              labelID
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              dataSourceLabelDataSourcesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      defaultTemplateId
      workspaceType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      avatar {
        visibility
        identityId
        file {
          bucket
          key
          region
          fileName
          __typename
        }
        __typename
      }
      name
      owner
      members
      admins
      editors
      emailDomains
      autoInviteDomains
      teamMembers {
        items {
          id
          teamMembershipUserId
          user {
            id
            username
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            userType
            defaultTeamId
            userSettings {
              onboardingCompleted
              releaseNotes
              __typename
            }
            teams {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          teamMembershipTeamId
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      dataSources {
        items {
          id
          owner
          editors
          admins
          createdAt
          updatedAt
          dataProvider
          displayName
          displayNameLowercase
          configParamsHash
          name
          nameLowercase
          tags
          tagsLowercase
          tagsLowercaseToString
          url
          hostname
          domain
          type
          view {
            viewId
            viewName
            propertyId
            websiteId
            loginCustomerId
            customerId
            site
            locationId
            channelId
            spreadsheetId
            accountId
            projectId
            dc
            connectorId
            dataSourceIds
            credentialIds
            merchantId
            isManager
            timeZone
            reportType
            url
            device
            type
            dataSourceId
            credentialId
            __typename
          }
          multipleViews {
            viewId
            viewName
            propertyId
            websiteId
            loginCustomerId
            customerId
            site
            locationId
            channelId
            spreadsheetId
            accountId
            projectId
            dc
            connectorId
            dataSourceIds
            credentialIds
            merchantId
            isManager
            timeZone
            reportType
            url
            device
            type
            dataSourceId
            credentialId
            __typename
          }
          dataUrls {
            items {
              id
              owner
              groups
              name
              nameLowercase
              status
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              createdAt
              updatedAt
              dataSourceDataUrlsId
              __typename
            }
            nextToken
            __typename
          }
          queries {
            items {
              id
              owner
              groups
              name
              nameLowercase
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              additionalFields {
                name
                value
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              feedTemplates {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  name
                  nameLowercase
                  root
                  default
                  conditions
                  queryId
                  iconUrl
                  publish
                  iconData
                  teamId
                  dataSourceId
                  parameters
                  __typename
                }
                nextToken
                __typename
              }
              queryTeamId
              iconUrl
              createdAt
              updatedAt
              dataSourceQueriesId
              __typename
            }
            nextToken
            __typename
          }
          preCacheRequests {
            items {
              id
              name
              nameLowercase
              fields
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              requestCount
              query
              TTL
              updatedAt
              createdAt
              dataSourcePreCacheRequestsId
              __typename
            }
            nextToken
            __typename
          }
          notificationActions {
            items {
              id
              owner
              groups
              name
              nameLowercase
              status
              notificationService
              template
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              schedule
              trigger
              notificationActionCredentialId
              createdAt
              updatedAt
              dataSourceNotificationActionsId
              __typename
            }
            nextToken
            __typename
          }
          integrations {
            items {
              id
              type
              dataProvider
              additionalSettings {
                name
                value
                __typename
              }
              query {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              integrationCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              integrationDataSourceId
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              createdAt
              updatedAt
              composerQueryIntegrationsId
              owner
              __typename
            }
            nextToken
            __typename
          }
          dataFreshness
          filter {
            isActive
            dimensionsFilters {
              fieldName
              values
              type
              operator
              __typename
            }
            segment {
              id
              name
              __typename
            }
            __typename
          }
          version
          rateLimit {
            max
            interval
            __typename
          }
          dataSourceCredentialId
          credential {
            id
            name
            email
            picture
            status
            owners
            editors
            createdAt
            updatedAt
            credentialTeamId
            authType
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            dataProvider
            groups
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            credentialLabelId
            authDataId
            clientId
            authData {
              accessToken
              refreshToken
              scope
              tokenType
              idToken
              expiryDate
              username
              password
              token
              key
              environment
              __typename
            }
            __typename
          }
          dataSourceTeamId
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          labels {
            items {
              id
              dataSourceID
              labelID
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              dataSourceLabelDataSourcesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          groups
          __typename
        }
        nextToken
        __typename
      }
      credentials {
        items {
          id
          name
          email
          picture
          status
          owners
          editors
          createdAt
          updatedAt
          credentialTeamId
          authType
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          dataProvider
          groups
          label {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          credentialLabelId
          authDataId
          clientId
          authData {
            accessToken
            refreshToken
            scope
            tokenType
            idToken
            expiryDate
            username
            password
            token
            key
            environment
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      dataSourceLabels {
        items {
          id
          name
          nameLowercase
          value
          type
          dataSourceLabelTeamId
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          createdAt
          dataProviders
          dataSources {
            items {
              id
              dataSourceID
              labelID
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              dataSourceLabelDataSourcesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      defaultTemplateId
      workspaceType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam(
    $input: DeleteTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    deleteTeam(input: $input, condition: $condition) {
      id
      avatar {
        visibility
        identityId
        file {
          bucket
          key
          region
          fileName
          __typename
        }
        __typename
      }
      name
      owner
      members
      admins
      editors
      emailDomains
      autoInviteDomains
      teamMembers {
        items {
          id
          teamMembershipUserId
          user {
            id
            username
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            userType
            defaultTeamId
            userSettings {
              onboardingCompleted
              releaseNotes
              __typename
            }
            teams {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          teamMembershipTeamId
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      dataSources {
        items {
          id
          owner
          editors
          admins
          createdAt
          updatedAt
          dataProvider
          displayName
          displayNameLowercase
          configParamsHash
          name
          nameLowercase
          tags
          tagsLowercase
          tagsLowercaseToString
          url
          hostname
          domain
          type
          view {
            viewId
            viewName
            propertyId
            websiteId
            loginCustomerId
            customerId
            site
            locationId
            channelId
            spreadsheetId
            accountId
            projectId
            dc
            connectorId
            dataSourceIds
            credentialIds
            merchantId
            isManager
            timeZone
            reportType
            url
            device
            type
            dataSourceId
            credentialId
            __typename
          }
          multipleViews {
            viewId
            viewName
            propertyId
            websiteId
            loginCustomerId
            customerId
            site
            locationId
            channelId
            spreadsheetId
            accountId
            projectId
            dc
            connectorId
            dataSourceIds
            credentialIds
            merchantId
            isManager
            timeZone
            reportType
            url
            device
            type
            dataSourceId
            credentialId
            __typename
          }
          dataUrls {
            items {
              id
              owner
              groups
              name
              nameLowercase
              status
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              createdAt
              updatedAt
              dataSourceDataUrlsId
              __typename
            }
            nextToken
            __typename
          }
          queries {
            items {
              id
              owner
              groups
              name
              nameLowercase
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              additionalFields {
                name
                value
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              feedTemplates {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  name
                  nameLowercase
                  root
                  default
                  conditions
                  queryId
                  iconUrl
                  publish
                  iconData
                  teamId
                  dataSourceId
                  parameters
                  __typename
                }
                nextToken
                __typename
              }
              queryTeamId
              iconUrl
              createdAt
              updatedAt
              dataSourceQueriesId
              __typename
            }
            nextToken
            __typename
          }
          preCacheRequests {
            items {
              id
              name
              nameLowercase
              fields
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              requestCount
              query
              TTL
              updatedAt
              createdAt
              dataSourcePreCacheRequestsId
              __typename
            }
            nextToken
            __typename
          }
          notificationActions {
            items {
              id
              owner
              groups
              name
              nameLowercase
              status
              notificationService
              template
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              schedule
              trigger
              notificationActionCredentialId
              createdAt
              updatedAt
              dataSourceNotificationActionsId
              __typename
            }
            nextToken
            __typename
          }
          integrations {
            items {
              id
              type
              dataProvider
              additionalSettings {
                name
                value
                __typename
              }
              query {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              integrationCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              integrationDataSourceId
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              createdAt
              updatedAt
              composerQueryIntegrationsId
              owner
              __typename
            }
            nextToken
            __typename
          }
          dataFreshness
          filter {
            isActive
            dimensionsFilters {
              fieldName
              values
              type
              operator
              __typename
            }
            segment {
              id
              name
              __typename
            }
            __typename
          }
          version
          rateLimit {
            max
            interval
            __typename
          }
          dataSourceCredentialId
          credential {
            id
            name
            email
            picture
            status
            owners
            editors
            createdAt
            updatedAt
            credentialTeamId
            authType
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            dataProvider
            groups
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            credentialLabelId
            authDataId
            clientId
            authData {
              accessToken
              refreshToken
              scope
              tokenType
              idToken
              expiryDate
              username
              password
              token
              key
              environment
              __typename
            }
            __typename
          }
          dataSourceTeamId
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          labels {
            items {
              id
              dataSourceID
              labelID
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              dataSourceLabelDataSourcesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          groups
          __typename
        }
        nextToken
        __typename
      }
      credentials {
        items {
          id
          name
          email
          picture
          status
          owners
          editors
          createdAt
          updatedAt
          credentialTeamId
          authType
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          dataProvider
          groups
          label {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          credentialLabelId
          authDataId
          clientId
          authData {
            accessToken
            refreshToken
            scope
            tokenType
            idToken
            expiryDate
            username
            password
            token
            key
            environment
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      dataSourceLabels {
        items {
          id
          name
          nameLowercase
          value
          type
          dataSourceLabelTeamId
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          createdAt
          dataProviders
          dataSources {
            items {
              id
              dataSourceID
              labelID
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              dataSourceLabelDataSourcesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      defaultTemplateId
      workspaceType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTheme = /* GraphQL */ `
  mutation CreateTheme(
    $input: CreateThemeInput!
    $condition: ModelThemeConditionInput
  ) {
    createTheme(input: $input, condition: $condition) {
      id
      owner
      primaryColor
      secondaryColor
      teamThemesId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTheme = /* GraphQL */ `
  mutation UpdateTheme(
    $input: UpdateThemeInput!
    $condition: ModelThemeConditionInput
  ) {
    updateTheme(input: $input, condition: $condition) {
      id
      owner
      primaryColor
      secondaryColor
      teamThemesId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTheme = /* GraphQL */ `
  mutation DeleteTheme(
    $input: DeleteThemeInput!
    $condition: ModelThemeConditionInput
  ) {
    deleteTheme(input: $input, condition: $condition) {
      id
      owner
      primaryColor
      secondaryColor
      teamThemesId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDataSourceSet = /* GraphQL */ `
  mutation CreateDataSourceSet(
    $input: CreateDataSourceSetInput!
    $condition: ModelDataSourceSetConditionInput
  ) {
    createDataSourceSet(input: $input, condition: $condition) {
      id
      name
      nameLowercase
      tags
      tagsLowercase
      tagsLowercaseToString
      createdAt
      updatedAt
      owner
      editors
      __typename
    }
  }
`;
export const updateDataSourceSet = /* GraphQL */ `
  mutation UpdateDataSourceSet(
    $input: UpdateDataSourceSetInput!
    $condition: ModelDataSourceSetConditionInput
  ) {
    updateDataSourceSet(input: $input, condition: $condition) {
      id
      name
      nameLowercase
      tags
      tagsLowercase
      tagsLowercaseToString
      createdAt
      updatedAt
      owner
      editors
      __typename
    }
  }
`;
export const deleteDataSourceSet = /* GraphQL */ `
  mutation DeleteDataSourceSet(
    $input: DeleteDataSourceSetInput!
    $condition: ModelDataSourceSetConditionInput
  ) {
    deleteDataSourceSet(input: $input, condition: $condition) {
      id
      name
      nameLowercase
      tags
      tagsLowercase
      tagsLowercaseToString
      createdAt
      updatedAt
      owner
      editors
      __typename
    }
  }
`;
export const createDataSourceLabel = /* GraphQL */ `
  mutation CreateDataSourceLabel(
    $input: CreateDataSourceLabelInput!
    $condition: ModelDataSourceLabelConditionInput
  ) {
    createDataSourceLabel(input: $input, condition: $condition) {
      id
      name
      nameLowercase
      value
      type
      dataSourceLabelTeamId
      team {
        id
        avatar {
          visibility
          identityId
          file {
            bucket
            key
            region
            fileName
            __typename
          }
          __typename
        }
        name
        owner
        members
        admins
        editors
        emailDomains
        autoInviteDomains
        teamMembers {
          items {
            id
            teamMembershipUserId
            user {
              id
              username
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              userType
              defaultTeamId
              userSettings {
                onboardingCompleted
                releaseNotes
                __typename
              }
              teams {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            teamMembershipTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            owner
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dataSources {
          items {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          nextToken
          __typename
        }
        credentials {
          items {
            id
            name
            email
            picture
            status
            owners
            editors
            createdAt
            updatedAt
            credentialTeamId
            authType
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            dataProvider
            groups
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            credentialLabelId
            authDataId
            clientId
            authData {
              accessToken
              refreshToken
              scope
              tokenType
              idToken
              expiryDate
              username
              password
              token
              key
              environment
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        dataSourceLabels {
          items {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        defaultTemplateId
        workspaceType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      dataProviders
      dataSources {
        items {
          id
          dataSourceID
          labelID
          dataSource {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          label {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          dataSourceLabelDataSourcesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateDataSourceLabel = /* GraphQL */ `
  mutation UpdateDataSourceLabel(
    $input: UpdateDataSourceLabelInput!
    $condition: ModelDataSourceLabelConditionInput
  ) {
    updateDataSourceLabel(input: $input, condition: $condition) {
      id
      name
      nameLowercase
      value
      type
      dataSourceLabelTeamId
      team {
        id
        avatar {
          visibility
          identityId
          file {
            bucket
            key
            region
            fileName
            __typename
          }
          __typename
        }
        name
        owner
        members
        admins
        editors
        emailDomains
        autoInviteDomains
        teamMembers {
          items {
            id
            teamMembershipUserId
            user {
              id
              username
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              userType
              defaultTeamId
              userSettings {
                onboardingCompleted
                releaseNotes
                __typename
              }
              teams {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            teamMembershipTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            owner
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dataSources {
          items {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          nextToken
          __typename
        }
        credentials {
          items {
            id
            name
            email
            picture
            status
            owners
            editors
            createdAt
            updatedAt
            credentialTeamId
            authType
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            dataProvider
            groups
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            credentialLabelId
            authDataId
            clientId
            authData {
              accessToken
              refreshToken
              scope
              tokenType
              idToken
              expiryDate
              username
              password
              token
              key
              environment
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        dataSourceLabels {
          items {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        defaultTemplateId
        workspaceType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      dataProviders
      dataSources {
        items {
          id
          dataSourceID
          labelID
          dataSource {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          label {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          dataSourceLabelDataSourcesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteDataSourceLabel = /* GraphQL */ `
  mutation DeleteDataSourceLabel(
    $input: DeleteDataSourceLabelInput!
    $condition: ModelDataSourceLabelConditionInput
  ) {
    deleteDataSourceLabel(input: $input, condition: $condition) {
      id
      name
      nameLowercase
      value
      type
      dataSourceLabelTeamId
      team {
        id
        avatar {
          visibility
          identityId
          file {
            bucket
            key
            region
            fileName
            __typename
          }
          __typename
        }
        name
        owner
        members
        admins
        editors
        emailDomains
        autoInviteDomains
        teamMembers {
          items {
            id
            teamMembershipUserId
            user {
              id
              username
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              userType
              defaultTeamId
              userSettings {
                onboardingCompleted
                releaseNotes
                __typename
              }
              teams {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            teamMembershipTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            owner
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dataSources {
          items {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          nextToken
          __typename
        }
        credentials {
          items {
            id
            name
            email
            picture
            status
            owners
            editors
            createdAt
            updatedAt
            credentialTeamId
            authType
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            dataProvider
            groups
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            credentialLabelId
            authDataId
            clientId
            authData {
              accessToken
              refreshToken
              scope
              tokenType
              idToken
              expiryDate
              username
              password
              token
              key
              environment
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        dataSourceLabels {
          items {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        defaultTemplateId
        workspaceType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      dataProviders
      dataSources {
        items {
          id
          dataSourceID
          labelID
          dataSource {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          label {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          dataSourceLabelDataSourcesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const createLabelSource = /* GraphQL */ `
  mutation CreateLabelSource(
    $input: CreateLabelSourceInput!
    $condition: ModelLabelSourceConditionInput
  ) {
    createLabelSource(input: $input, condition: $condition) {
      id
      dataSourceID
      labelID
      dataSource {
        id
        owner
        editors
        admins
        createdAt
        updatedAt
        dataProvider
        displayName
        displayNameLowercase
        configParamsHash
        name
        nameLowercase
        tags
        tagsLowercase
        tagsLowercaseToString
        url
        hostname
        domain
        type
        view {
          viewId
          viewName
          propertyId
          websiteId
          loginCustomerId
          customerId
          site
          locationId
          channelId
          spreadsheetId
          accountId
          projectId
          dc
          connectorId
          dataSourceIds
          credentialIds
          merchantId
          isManager
          timeZone
          reportType
          url
          device
          type
          dataSourceId
          credentialId
          __typename
        }
        multipleViews {
          viewId
          viewName
          propertyId
          websiteId
          loginCustomerId
          customerId
          site
          locationId
          channelId
          spreadsheetId
          accountId
          projectId
          dc
          connectorId
          dataSourceIds
          credentialIds
          merchantId
          isManager
          timeZone
          reportType
          url
          device
          type
          dataSourceId
          credentialId
          __typename
        }
        dataUrls {
          items {
            id
            owner
            groups
            name
            nameLowercase
            status
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            createdAt
            updatedAt
            dataSourceDataUrlsId
            __typename
          }
          nextToken
          __typename
        }
        queries {
          items {
            id
            owner
            groups
            name
            nameLowercase
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            additionalFields {
              name
              value
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            feedTemplates {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                name
                nameLowercase
                root
                default
                conditions
                queryId
                iconUrl
                publish
                iconData
                teamId
                dataSourceId
                parameters
                __typename
              }
              nextToken
              __typename
            }
            queryTeamId
            iconUrl
            createdAt
            updatedAt
            dataSourceQueriesId
            __typename
          }
          nextToken
          __typename
        }
        preCacheRequests {
          items {
            id
            name
            nameLowercase
            fields
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            requestCount
            query
            TTL
            updatedAt
            createdAt
            dataSourcePreCacheRequestsId
            __typename
          }
          nextToken
          __typename
        }
        notificationActions {
          items {
            id
            owner
            groups
            name
            nameLowercase
            status
            notificationService
            template
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            schedule
            trigger
            notificationActionCredentialId
            createdAt
            updatedAt
            dataSourceNotificationActionsId
            __typename
          }
          nextToken
          __typename
        }
        integrations {
          items {
            id
            type
            dataProvider
            additionalSettings {
              name
              value
              __typename
            }
            query {
              id
              owner
              groups
              name
              nameLowercase
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              additionalFields {
                name
                value
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              feedTemplates {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  name
                  nameLowercase
                  root
                  default
                  conditions
                  queryId
                  iconUrl
                  publish
                  iconData
                  teamId
                  dataSourceId
                  parameters
                  __typename
                }
                nextToken
                __typename
              }
              queryTeamId
              iconUrl
              createdAt
              updatedAt
              dataSourceQueriesId
              __typename
            }
            integrationCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            integrationDataSourceId
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            createdAt
            updatedAt
            composerQueryIntegrationsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        dataFreshness
        filter {
          isActive
          dimensionsFilters {
            fieldName
            values
            type
            operator
            __typename
          }
          segment {
            id
            name
            __typename
          }
          __typename
        }
        version
        rateLimit {
          max
          interval
          __typename
        }
        dataSourceCredentialId
        credential {
          id
          name
          email
          picture
          status
          owners
          editors
          createdAt
          updatedAt
          credentialTeamId
          authType
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          dataProvider
          groups
          label {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          credentialLabelId
          authDataId
          clientId
          authData {
            accessToken
            refreshToken
            scope
            tokenType
            idToken
            expiryDate
            username
            password
            token
            key
            environment
            __typename
          }
          __typename
        }
        dataSourceTeamId
        team {
          id
          avatar {
            visibility
            identityId
            file {
              bucket
              key
              region
              fileName
              __typename
            }
            __typename
          }
          name
          owner
          members
          admins
          editors
          emailDomains
          autoInviteDomains
          teamMembers {
            items {
              id
              teamMembershipUserId
              user {
                id
                username
                avatar {
                  visibility
                  identityId
                  __typename
                }
                userType
                defaultTeamId
                userSettings {
                  onboardingCompleted
                  releaseNotes
                  __typename
                }
                teams {
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              teamMembershipTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              owner
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          credentials {
            items {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          dataSourceLabels {
            items {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultTemplateId
          workspaceType
          createdAt
          updatedAt
          __typename
        }
        labels {
          items {
            id
            dataSourceID
            labelID
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            dataSourceLabelDataSourcesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        groups
        __typename
      }
      label {
        id
        name
        nameLowercase
        value
        type
        dataSourceLabelTeamId
        team {
          id
          avatar {
            visibility
            identityId
            file {
              bucket
              key
              region
              fileName
              __typename
            }
            __typename
          }
          name
          owner
          members
          admins
          editors
          emailDomains
          autoInviteDomains
          teamMembers {
            items {
              id
              teamMembershipUserId
              user {
                id
                username
                avatar {
                  visibility
                  identityId
                  __typename
                }
                userType
                defaultTeamId
                userSettings {
                  onboardingCompleted
                  releaseNotes
                  __typename
                }
                teams {
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              teamMembershipTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              owner
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          credentials {
            items {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          dataSourceLabels {
            items {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultTemplateId
          workspaceType
          createdAt
          updatedAt
          __typename
        }
        createdAt
        dataProviders
        dataSources {
          items {
            id
            dataSourceID
            labelID
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            dataSourceLabelDataSourcesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      dataSourceLabelDataSourcesId
      owner
      __typename
    }
  }
`;
export const updateLabelSource = /* GraphQL */ `
  mutation UpdateLabelSource(
    $input: UpdateLabelSourceInput!
    $condition: ModelLabelSourceConditionInput
  ) {
    updateLabelSource(input: $input, condition: $condition) {
      id
      dataSourceID
      labelID
      dataSource {
        id
        owner
        editors
        admins
        createdAt
        updatedAt
        dataProvider
        displayName
        displayNameLowercase
        configParamsHash
        name
        nameLowercase
        tags
        tagsLowercase
        tagsLowercaseToString
        url
        hostname
        domain
        type
        view {
          viewId
          viewName
          propertyId
          websiteId
          loginCustomerId
          customerId
          site
          locationId
          channelId
          spreadsheetId
          accountId
          projectId
          dc
          connectorId
          dataSourceIds
          credentialIds
          merchantId
          isManager
          timeZone
          reportType
          url
          device
          type
          dataSourceId
          credentialId
          __typename
        }
        multipleViews {
          viewId
          viewName
          propertyId
          websiteId
          loginCustomerId
          customerId
          site
          locationId
          channelId
          spreadsheetId
          accountId
          projectId
          dc
          connectorId
          dataSourceIds
          credentialIds
          merchantId
          isManager
          timeZone
          reportType
          url
          device
          type
          dataSourceId
          credentialId
          __typename
        }
        dataUrls {
          items {
            id
            owner
            groups
            name
            nameLowercase
            status
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            createdAt
            updatedAt
            dataSourceDataUrlsId
            __typename
          }
          nextToken
          __typename
        }
        queries {
          items {
            id
            owner
            groups
            name
            nameLowercase
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            additionalFields {
              name
              value
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            feedTemplates {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                name
                nameLowercase
                root
                default
                conditions
                queryId
                iconUrl
                publish
                iconData
                teamId
                dataSourceId
                parameters
                __typename
              }
              nextToken
              __typename
            }
            queryTeamId
            iconUrl
            createdAt
            updatedAt
            dataSourceQueriesId
            __typename
          }
          nextToken
          __typename
        }
        preCacheRequests {
          items {
            id
            name
            nameLowercase
            fields
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            requestCount
            query
            TTL
            updatedAt
            createdAt
            dataSourcePreCacheRequestsId
            __typename
          }
          nextToken
          __typename
        }
        notificationActions {
          items {
            id
            owner
            groups
            name
            nameLowercase
            status
            notificationService
            template
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            schedule
            trigger
            notificationActionCredentialId
            createdAt
            updatedAt
            dataSourceNotificationActionsId
            __typename
          }
          nextToken
          __typename
        }
        integrations {
          items {
            id
            type
            dataProvider
            additionalSettings {
              name
              value
              __typename
            }
            query {
              id
              owner
              groups
              name
              nameLowercase
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              additionalFields {
                name
                value
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              feedTemplates {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  name
                  nameLowercase
                  root
                  default
                  conditions
                  queryId
                  iconUrl
                  publish
                  iconData
                  teamId
                  dataSourceId
                  parameters
                  __typename
                }
                nextToken
                __typename
              }
              queryTeamId
              iconUrl
              createdAt
              updatedAt
              dataSourceQueriesId
              __typename
            }
            integrationCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            integrationDataSourceId
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            createdAt
            updatedAt
            composerQueryIntegrationsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        dataFreshness
        filter {
          isActive
          dimensionsFilters {
            fieldName
            values
            type
            operator
            __typename
          }
          segment {
            id
            name
            __typename
          }
          __typename
        }
        version
        rateLimit {
          max
          interval
          __typename
        }
        dataSourceCredentialId
        credential {
          id
          name
          email
          picture
          status
          owners
          editors
          createdAt
          updatedAt
          credentialTeamId
          authType
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          dataProvider
          groups
          label {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          credentialLabelId
          authDataId
          clientId
          authData {
            accessToken
            refreshToken
            scope
            tokenType
            idToken
            expiryDate
            username
            password
            token
            key
            environment
            __typename
          }
          __typename
        }
        dataSourceTeamId
        team {
          id
          avatar {
            visibility
            identityId
            file {
              bucket
              key
              region
              fileName
              __typename
            }
            __typename
          }
          name
          owner
          members
          admins
          editors
          emailDomains
          autoInviteDomains
          teamMembers {
            items {
              id
              teamMembershipUserId
              user {
                id
                username
                avatar {
                  visibility
                  identityId
                  __typename
                }
                userType
                defaultTeamId
                userSettings {
                  onboardingCompleted
                  releaseNotes
                  __typename
                }
                teams {
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              teamMembershipTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              owner
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          credentials {
            items {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          dataSourceLabels {
            items {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultTemplateId
          workspaceType
          createdAt
          updatedAt
          __typename
        }
        labels {
          items {
            id
            dataSourceID
            labelID
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            dataSourceLabelDataSourcesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        groups
        __typename
      }
      label {
        id
        name
        nameLowercase
        value
        type
        dataSourceLabelTeamId
        team {
          id
          avatar {
            visibility
            identityId
            file {
              bucket
              key
              region
              fileName
              __typename
            }
            __typename
          }
          name
          owner
          members
          admins
          editors
          emailDomains
          autoInviteDomains
          teamMembers {
            items {
              id
              teamMembershipUserId
              user {
                id
                username
                avatar {
                  visibility
                  identityId
                  __typename
                }
                userType
                defaultTeamId
                userSettings {
                  onboardingCompleted
                  releaseNotes
                  __typename
                }
                teams {
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              teamMembershipTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              owner
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          credentials {
            items {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          dataSourceLabels {
            items {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultTemplateId
          workspaceType
          createdAt
          updatedAt
          __typename
        }
        createdAt
        dataProviders
        dataSources {
          items {
            id
            dataSourceID
            labelID
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            dataSourceLabelDataSourcesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      dataSourceLabelDataSourcesId
      owner
      __typename
    }
  }
`;
export const deleteLabelSource = /* GraphQL */ `
  mutation DeleteLabelSource(
    $input: DeleteLabelSourceInput!
    $condition: ModelLabelSourceConditionInput
  ) {
    deleteLabelSource(input: $input, condition: $condition) {
      id
      dataSourceID
      labelID
      dataSource {
        id
        owner
        editors
        admins
        createdAt
        updatedAt
        dataProvider
        displayName
        displayNameLowercase
        configParamsHash
        name
        nameLowercase
        tags
        tagsLowercase
        tagsLowercaseToString
        url
        hostname
        domain
        type
        view {
          viewId
          viewName
          propertyId
          websiteId
          loginCustomerId
          customerId
          site
          locationId
          channelId
          spreadsheetId
          accountId
          projectId
          dc
          connectorId
          dataSourceIds
          credentialIds
          merchantId
          isManager
          timeZone
          reportType
          url
          device
          type
          dataSourceId
          credentialId
          __typename
        }
        multipleViews {
          viewId
          viewName
          propertyId
          websiteId
          loginCustomerId
          customerId
          site
          locationId
          channelId
          spreadsheetId
          accountId
          projectId
          dc
          connectorId
          dataSourceIds
          credentialIds
          merchantId
          isManager
          timeZone
          reportType
          url
          device
          type
          dataSourceId
          credentialId
          __typename
        }
        dataUrls {
          items {
            id
            owner
            groups
            name
            nameLowercase
            status
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            createdAt
            updatedAt
            dataSourceDataUrlsId
            __typename
          }
          nextToken
          __typename
        }
        queries {
          items {
            id
            owner
            groups
            name
            nameLowercase
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            additionalFields {
              name
              value
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            feedTemplates {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                name
                nameLowercase
                root
                default
                conditions
                queryId
                iconUrl
                publish
                iconData
                teamId
                dataSourceId
                parameters
                __typename
              }
              nextToken
              __typename
            }
            queryTeamId
            iconUrl
            createdAt
            updatedAt
            dataSourceQueriesId
            __typename
          }
          nextToken
          __typename
        }
        preCacheRequests {
          items {
            id
            name
            nameLowercase
            fields
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            requestCount
            query
            TTL
            updatedAt
            createdAt
            dataSourcePreCacheRequestsId
            __typename
          }
          nextToken
          __typename
        }
        notificationActions {
          items {
            id
            owner
            groups
            name
            nameLowercase
            status
            notificationService
            template
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            schedule
            trigger
            notificationActionCredentialId
            createdAt
            updatedAt
            dataSourceNotificationActionsId
            __typename
          }
          nextToken
          __typename
        }
        integrations {
          items {
            id
            type
            dataProvider
            additionalSettings {
              name
              value
              __typename
            }
            query {
              id
              owner
              groups
              name
              nameLowercase
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              additionalFields {
                name
                value
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              feedTemplates {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  name
                  nameLowercase
                  root
                  default
                  conditions
                  queryId
                  iconUrl
                  publish
                  iconData
                  teamId
                  dataSourceId
                  parameters
                  __typename
                }
                nextToken
                __typename
              }
              queryTeamId
              iconUrl
              createdAt
              updatedAt
              dataSourceQueriesId
              __typename
            }
            integrationCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            integrationDataSourceId
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            createdAt
            updatedAt
            composerQueryIntegrationsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        dataFreshness
        filter {
          isActive
          dimensionsFilters {
            fieldName
            values
            type
            operator
            __typename
          }
          segment {
            id
            name
            __typename
          }
          __typename
        }
        version
        rateLimit {
          max
          interval
          __typename
        }
        dataSourceCredentialId
        credential {
          id
          name
          email
          picture
          status
          owners
          editors
          createdAt
          updatedAt
          credentialTeamId
          authType
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          dataProvider
          groups
          label {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          credentialLabelId
          authDataId
          clientId
          authData {
            accessToken
            refreshToken
            scope
            tokenType
            idToken
            expiryDate
            username
            password
            token
            key
            environment
            __typename
          }
          __typename
        }
        dataSourceTeamId
        team {
          id
          avatar {
            visibility
            identityId
            file {
              bucket
              key
              region
              fileName
              __typename
            }
            __typename
          }
          name
          owner
          members
          admins
          editors
          emailDomains
          autoInviteDomains
          teamMembers {
            items {
              id
              teamMembershipUserId
              user {
                id
                username
                avatar {
                  visibility
                  identityId
                  __typename
                }
                userType
                defaultTeamId
                userSettings {
                  onboardingCompleted
                  releaseNotes
                  __typename
                }
                teams {
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              teamMembershipTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              owner
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          credentials {
            items {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          dataSourceLabels {
            items {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultTemplateId
          workspaceType
          createdAt
          updatedAt
          __typename
        }
        labels {
          items {
            id
            dataSourceID
            labelID
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            dataSourceLabelDataSourcesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        groups
        __typename
      }
      label {
        id
        name
        nameLowercase
        value
        type
        dataSourceLabelTeamId
        team {
          id
          avatar {
            visibility
            identityId
            file {
              bucket
              key
              region
              fileName
              __typename
            }
            __typename
          }
          name
          owner
          members
          admins
          editors
          emailDomains
          autoInviteDomains
          teamMembers {
            items {
              id
              teamMembershipUserId
              user {
                id
                username
                avatar {
                  visibility
                  identityId
                  __typename
                }
                userType
                defaultTeamId
                userSettings {
                  onboardingCompleted
                  releaseNotes
                  __typename
                }
                teams {
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              teamMembershipTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              owner
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          credentials {
            items {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          dataSourceLabels {
            items {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultTemplateId
          workspaceType
          createdAt
          updatedAt
          __typename
        }
        createdAt
        dataProviders
        dataSources {
          items {
            id
            dataSourceID
            labelID
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            dataSourceLabelDataSourcesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      dataSourceLabelDataSourcesId
      owner
      __typename
    }
  }
`;
export const createTemplate = /* GraphQL */ `
  mutation CreateTemplate(
    $input: CreateTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    createTemplate(input: $input, condition: $condition) {
      id
      owner
      editors
      admins
      createdAt
      updatedAt
      name
      nameLowercase
      description
      image {
        bucket
        key
        region
        __typename
      }
      pages {
        id
        name
        datasourceAliases {
          alias
          dataProvider
          dataSourceId
          __typename
        }
        image {
          bucket
          key
          region
          __typename
        }
        __typename
      }
      type
      category
      tags
      tagsLowercase
      tagsLowercaseToString
      dataProviders
      groups
      editGroups
      view {
        lookerStudioId
        datasourceAliases {
          alias
          dataProvider
          dataSourceId
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateTemplate = /* GraphQL */ `
  mutation UpdateTemplate(
    $input: UpdateTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    updateTemplate(input: $input, condition: $condition) {
      id
      owner
      editors
      admins
      createdAt
      updatedAt
      name
      nameLowercase
      description
      image {
        bucket
        key
        region
        __typename
      }
      pages {
        id
        name
        datasourceAliases {
          alias
          dataProvider
          dataSourceId
          __typename
        }
        image {
          bucket
          key
          region
          __typename
        }
        __typename
      }
      type
      category
      tags
      tagsLowercase
      tagsLowercaseToString
      dataProviders
      groups
      editGroups
      view {
        lookerStudioId
        datasourceAliases {
          alias
          dataProvider
          dataSourceId
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deleteTemplate = /* GraphQL */ `
  mutation DeleteTemplate(
    $input: DeleteTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    deleteTemplate(input: $input, condition: $condition) {
      id
      owner
      editors
      admins
      createdAt
      updatedAt
      name
      nameLowercase
      description
      image {
        bucket
        key
        region
        __typename
      }
      pages {
        id
        name
        datasourceAliases {
          alias
          dataProvider
          dataSourceId
          __typename
        }
        image {
          bucket
          key
          region
          __typename
        }
        __typename
      }
      type
      category
      tags
      tagsLowercase
      tagsLowercaseToString
      dataProviders
      groups
      editGroups
      view {
        lookerStudioId
        datasourceAliases {
          alias
          dataProvider
          dataSourceId
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createFeedTemplate = /* GraphQL */ `
  mutation CreateFeedTemplate(
    $input: CreateFeedTemplateInput!
    $condition: ModelFeedTemplateConditionInput
  ) {
    createFeedTemplate(input: $input, condition: $condition) {
      id
      owner
      editors
      admins
      createdAt
      updatedAt
      name
      nameLowercase
      root
      default
      conditions
      queryId
      iconUrl
      publish
      iconData
      teamId
      dataSourceId
      parameters
      __typename
    }
  }
`;
export const updateFeedTemplate = /* GraphQL */ `
  mutation UpdateFeedTemplate(
    $input: UpdateFeedTemplateInput!
    $condition: ModelFeedTemplateConditionInput
  ) {
    updateFeedTemplate(input: $input, condition: $condition) {
      id
      owner
      editors
      admins
      createdAt
      updatedAt
      name
      nameLowercase
      root
      default
      conditions
      queryId
      iconUrl
      publish
      iconData
      teamId
      dataSourceId
      parameters
      __typename
    }
  }
`;
export const deleteFeedTemplate = /* GraphQL */ `
  mutation DeleteFeedTemplate(
    $input: DeleteFeedTemplateInput!
    $condition: ModelFeedTemplateConditionInput
  ) {
    deleteFeedTemplate(input: $input, condition: $condition) {
      id
      owner
      editors
      admins
      createdAt
      updatedAt
      name
      nameLowercase
      root
      default
      conditions
      queryId
      iconUrl
      publish
      iconData
      teamId
      dataSourceId
      parameters
      __typename
    }
  }
`;
export const createDataUrl = /* GraphQL */ `
  mutation CreateDataUrl(
    $input: CreateDataUrlInput!
    $condition: ModelDataUrlConditionInput
  ) {
    createDataUrl(input: $input, condition: $condition) {
      id
      owner
      groups
      name
      nameLowercase
      status
      dataSource {
        id
        owner
        editors
        admins
        createdAt
        updatedAt
        dataProvider
        displayName
        displayNameLowercase
        configParamsHash
        name
        nameLowercase
        tags
        tagsLowercase
        tagsLowercaseToString
        url
        hostname
        domain
        type
        view {
          viewId
          viewName
          propertyId
          websiteId
          loginCustomerId
          customerId
          site
          locationId
          channelId
          spreadsheetId
          accountId
          projectId
          dc
          connectorId
          dataSourceIds
          credentialIds
          merchantId
          isManager
          timeZone
          reportType
          url
          device
          type
          dataSourceId
          credentialId
          __typename
        }
        multipleViews {
          viewId
          viewName
          propertyId
          websiteId
          loginCustomerId
          customerId
          site
          locationId
          channelId
          spreadsheetId
          accountId
          projectId
          dc
          connectorId
          dataSourceIds
          credentialIds
          merchantId
          isManager
          timeZone
          reportType
          url
          device
          type
          dataSourceId
          credentialId
          __typename
        }
        dataUrls {
          items {
            id
            owner
            groups
            name
            nameLowercase
            status
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            createdAt
            updatedAt
            dataSourceDataUrlsId
            __typename
          }
          nextToken
          __typename
        }
        queries {
          items {
            id
            owner
            groups
            name
            nameLowercase
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            additionalFields {
              name
              value
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            feedTemplates {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                name
                nameLowercase
                root
                default
                conditions
                queryId
                iconUrl
                publish
                iconData
                teamId
                dataSourceId
                parameters
                __typename
              }
              nextToken
              __typename
            }
            queryTeamId
            iconUrl
            createdAt
            updatedAt
            dataSourceQueriesId
            __typename
          }
          nextToken
          __typename
        }
        preCacheRequests {
          items {
            id
            name
            nameLowercase
            fields
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            requestCount
            query
            TTL
            updatedAt
            createdAt
            dataSourcePreCacheRequestsId
            __typename
          }
          nextToken
          __typename
        }
        notificationActions {
          items {
            id
            owner
            groups
            name
            nameLowercase
            status
            notificationService
            template
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            schedule
            trigger
            notificationActionCredentialId
            createdAt
            updatedAt
            dataSourceNotificationActionsId
            __typename
          }
          nextToken
          __typename
        }
        integrations {
          items {
            id
            type
            dataProvider
            additionalSettings {
              name
              value
              __typename
            }
            query {
              id
              owner
              groups
              name
              nameLowercase
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              additionalFields {
                name
                value
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              feedTemplates {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  name
                  nameLowercase
                  root
                  default
                  conditions
                  queryId
                  iconUrl
                  publish
                  iconData
                  teamId
                  dataSourceId
                  parameters
                  __typename
                }
                nextToken
                __typename
              }
              queryTeamId
              iconUrl
              createdAt
              updatedAt
              dataSourceQueriesId
              __typename
            }
            integrationCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            integrationDataSourceId
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            createdAt
            updatedAt
            composerQueryIntegrationsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        dataFreshness
        filter {
          isActive
          dimensionsFilters {
            fieldName
            values
            type
            operator
            __typename
          }
          segment {
            id
            name
            __typename
          }
          __typename
        }
        version
        rateLimit {
          max
          interval
          __typename
        }
        dataSourceCredentialId
        credential {
          id
          name
          email
          picture
          status
          owners
          editors
          createdAt
          updatedAt
          credentialTeamId
          authType
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          dataProvider
          groups
          label {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          credentialLabelId
          authDataId
          clientId
          authData {
            accessToken
            refreshToken
            scope
            tokenType
            idToken
            expiryDate
            username
            password
            token
            key
            environment
            __typename
          }
          __typename
        }
        dataSourceTeamId
        team {
          id
          avatar {
            visibility
            identityId
            file {
              bucket
              key
              region
              fileName
              __typename
            }
            __typename
          }
          name
          owner
          members
          admins
          editors
          emailDomains
          autoInviteDomains
          teamMembers {
            items {
              id
              teamMembershipUserId
              user {
                id
                username
                avatar {
                  visibility
                  identityId
                  __typename
                }
                userType
                defaultTeamId
                userSettings {
                  onboardingCompleted
                  releaseNotes
                  __typename
                }
                teams {
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              teamMembershipTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              owner
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          credentials {
            items {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          dataSourceLabels {
            items {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultTemplateId
          workspaceType
          createdAt
          updatedAt
          __typename
        }
        labels {
          items {
            id
            dataSourceID
            labelID
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            dataSourceLabelDataSourcesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        groups
        __typename
      }
      limit
      fields
      sort {
        id
        sortDirection
        __typename
      }
      dimensionsFilters {
        orFilters {
          fieldName
          values
          type
          operator
          __typename
        }
        __typename
      }
      dateRangeType
      dateRange {
        startDate
        endDate
        __typename
      }
      createdAt
      updatedAt
      dataSourceDataUrlsId
      __typename
    }
  }
`;
export const updateDataUrl = /* GraphQL */ `
  mutation UpdateDataUrl(
    $input: UpdateDataUrlInput!
    $condition: ModelDataUrlConditionInput
  ) {
    updateDataUrl(input: $input, condition: $condition) {
      id
      owner
      groups
      name
      nameLowercase
      status
      dataSource {
        id
        owner
        editors
        admins
        createdAt
        updatedAt
        dataProvider
        displayName
        displayNameLowercase
        configParamsHash
        name
        nameLowercase
        tags
        tagsLowercase
        tagsLowercaseToString
        url
        hostname
        domain
        type
        view {
          viewId
          viewName
          propertyId
          websiteId
          loginCustomerId
          customerId
          site
          locationId
          channelId
          spreadsheetId
          accountId
          projectId
          dc
          connectorId
          dataSourceIds
          credentialIds
          merchantId
          isManager
          timeZone
          reportType
          url
          device
          type
          dataSourceId
          credentialId
          __typename
        }
        multipleViews {
          viewId
          viewName
          propertyId
          websiteId
          loginCustomerId
          customerId
          site
          locationId
          channelId
          spreadsheetId
          accountId
          projectId
          dc
          connectorId
          dataSourceIds
          credentialIds
          merchantId
          isManager
          timeZone
          reportType
          url
          device
          type
          dataSourceId
          credentialId
          __typename
        }
        dataUrls {
          items {
            id
            owner
            groups
            name
            nameLowercase
            status
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            createdAt
            updatedAt
            dataSourceDataUrlsId
            __typename
          }
          nextToken
          __typename
        }
        queries {
          items {
            id
            owner
            groups
            name
            nameLowercase
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            additionalFields {
              name
              value
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            feedTemplates {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                name
                nameLowercase
                root
                default
                conditions
                queryId
                iconUrl
                publish
                iconData
                teamId
                dataSourceId
                parameters
                __typename
              }
              nextToken
              __typename
            }
            queryTeamId
            iconUrl
            createdAt
            updatedAt
            dataSourceQueriesId
            __typename
          }
          nextToken
          __typename
        }
        preCacheRequests {
          items {
            id
            name
            nameLowercase
            fields
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            requestCount
            query
            TTL
            updatedAt
            createdAt
            dataSourcePreCacheRequestsId
            __typename
          }
          nextToken
          __typename
        }
        notificationActions {
          items {
            id
            owner
            groups
            name
            nameLowercase
            status
            notificationService
            template
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            schedule
            trigger
            notificationActionCredentialId
            createdAt
            updatedAt
            dataSourceNotificationActionsId
            __typename
          }
          nextToken
          __typename
        }
        integrations {
          items {
            id
            type
            dataProvider
            additionalSettings {
              name
              value
              __typename
            }
            query {
              id
              owner
              groups
              name
              nameLowercase
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              additionalFields {
                name
                value
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              feedTemplates {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  name
                  nameLowercase
                  root
                  default
                  conditions
                  queryId
                  iconUrl
                  publish
                  iconData
                  teamId
                  dataSourceId
                  parameters
                  __typename
                }
                nextToken
                __typename
              }
              queryTeamId
              iconUrl
              createdAt
              updatedAt
              dataSourceQueriesId
              __typename
            }
            integrationCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            integrationDataSourceId
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            createdAt
            updatedAt
            composerQueryIntegrationsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        dataFreshness
        filter {
          isActive
          dimensionsFilters {
            fieldName
            values
            type
            operator
            __typename
          }
          segment {
            id
            name
            __typename
          }
          __typename
        }
        version
        rateLimit {
          max
          interval
          __typename
        }
        dataSourceCredentialId
        credential {
          id
          name
          email
          picture
          status
          owners
          editors
          createdAt
          updatedAt
          credentialTeamId
          authType
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          dataProvider
          groups
          label {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          credentialLabelId
          authDataId
          clientId
          authData {
            accessToken
            refreshToken
            scope
            tokenType
            idToken
            expiryDate
            username
            password
            token
            key
            environment
            __typename
          }
          __typename
        }
        dataSourceTeamId
        team {
          id
          avatar {
            visibility
            identityId
            file {
              bucket
              key
              region
              fileName
              __typename
            }
            __typename
          }
          name
          owner
          members
          admins
          editors
          emailDomains
          autoInviteDomains
          teamMembers {
            items {
              id
              teamMembershipUserId
              user {
                id
                username
                avatar {
                  visibility
                  identityId
                  __typename
                }
                userType
                defaultTeamId
                userSettings {
                  onboardingCompleted
                  releaseNotes
                  __typename
                }
                teams {
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              teamMembershipTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              owner
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          credentials {
            items {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          dataSourceLabels {
            items {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultTemplateId
          workspaceType
          createdAt
          updatedAt
          __typename
        }
        labels {
          items {
            id
            dataSourceID
            labelID
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            dataSourceLabelDataSourcesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        groups
        __typename
      }
      limit
      fields
      sort {
        id
        sortDirection
        __typename
      }
      dimensionsFilters {
        orFilters {
          fieldName
          values
          type
          operator
          __typename
        }
        __typename
      }
      dateRangeType
      dateRange {
        startDate
        endDate
        __typename
      }
      createdAt
      updatedAt
      dataSourceDataUrlsId
      __typename
    }
  }
`;
export const deleteDataUrl = /* GraphQL */ `
  mutation DeleteDataUrl(
    $input: DeleteDataUrlInput!
    $condition: ModelDataUrlConditionInput
  ) {
    deleteDataUrl(input: $input, condition: $condition) {
      id
      owner
      groups
      name
      nameLowercase
      status
      dataSource {
        id
        owner
        editors
        admins
        createdAt
        updatedAt
        dataProvider
        displayName
        displayNameLowercase
        configParamsHash
        name
        nameLowercase
        tags
        tagsLowercase
        tagsLowercaseToString
        url
        hostname
        domain
        type
        view {
          viewId
          viewName
          propertyId
          websiteId
          loginCustomerId
          customerId
          site
          locationId
          channelId
          spreadsheetId
          accountId
          projectId
          dc
          connectorId
          dataSourceIds
          credentialIds
          merchantId
          isManager
          timeZone
          reportType
          url
          device
          type
          dataSourceId
          credentialId
          __typename
        }
        multipleViews {
          viewId
          viewName
          propertyId
          websiteId
          loginCustomerId
          customerId
          site
          locationId
          channelId
          spreadsheetId
          accountId
          projectId
          dc
          connectorId
          dataSourceIds
          credentialIds
          merchantId
          isManager
          timeZone
          reportType
          url
          device
          type
          dataSourceId
          credentialId
          __typename
        }
        dataUrls {
          items {
            id
            owner
            groups
            name
            nameLowercase
            status
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            createdAt
            updatedAt
            dataSourceDataUrlsId
            __typename
          }
          nextToken
          __typename
        }
        queries {
          items {
            id
            owner
            groups
            name
            nameLowercase
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            additionalFields {
              name
              value
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            feedTemplates {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                name
                nameLowercase
                root
                default
                conditions
                queryId
                iconUrl
                publish
                iconData
                teamId
                dataSourceId
                parameters
                __typename
              }
              nextToken
              __typename
            }
            queryTeamId
            iconUrl
            createdAt
            updatedAt
            dataSourceQueriesId
            __typename
          }
          nextToken
          __typename
        }
        preCacheRequests {
          items {
            id
            name
            nameLowercase
            fields
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            requestCount
            query
            TTL
            updatedAt
            createdAt
            dataSourcePreCacheRequestsId
            __typename
          }
          nextToken
          __typename
        }
        notificationActions {
          items {
            id
            owner
            groups
            name
            nameLowercase
            status
            notificationService
            template
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            schedule
            trigger
            notificationActionCredentialId
            createdAt
            updatedAt
            dataSourceNotificationActionsId
            __typename
          }
          nextToken
          __typename
        }
        integrations {
          items {
            id
            type
            dataProvider
            additionalSettings {
              name
              value
              __typename
            }
            query {
              id
              owner
              groups
              name
              nameLowercase
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              additionalFields {
                name
                value
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              feedTemplates {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  name
                  nameLowercase
                  root
                  default
                  conditions
                  queryId
                  iconUrl
                  publish
                  iconData
                  teamId
                  dataSourceId
                  parameters
                  __typename
                }
                nextToken
                __typename
              }
              queryTeamId
              iconUrl
              createdAt
              updatedAt
              dataSourceQueriesId
              __typename
            }
            integrationCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            integrationDataSourceId
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            createdAt
            updatedAt
            composerQueryIntegrationsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        dataFreshness
        filter {
          isActive
          dimensionsFilters {
            fieldName
            values
            type
            operator
            __typename
          }
          segment {
            id
            name
            __typename
          }
          __typename
        }
        version
        rateLimit {
          max
          interval
          __typename
        }
        dataSourceCredentialId
        credential {
          id
          name
          email
          picture
          status
          owners
          editors
          createdAt
          updatedAt
          credentialTeamId
          authType
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          dataProvider
          groups
          label {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          credentialLabelId
          authDataId
          clientId
          authData {
            accessToken
            refreshToken
            scope
            tokenType
            idToken
            expiryDate
            username
            password
            token
            key
            environment
            __typename
          }
          __typename
        }
        dataSourceTeamId
        team {
          id
          avatar {
            visibility
            identityId
            file {
              bucket
              key
              region
              fileName
              __typename
            }
            __typename
          }
          name
          owner
          members
          admins
          editors
          emailDomains
          autoInviteDomains
          teamMembers {
            items {
              id
              teamMembershipUserId
              user {
                id
                username
                avatar {
                  visibility
                  identityId
                  __typename
                }
                userType
                defaultTeamId
                userSettings {
                  onboardingCompleted
                  releaseNotes
                  __typename
                }
                teams {
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              teamMembershipTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              owner
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          credentials {
            items {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          dataSourceLabels {
            items {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultTemplateId
          workspaceType
          createdAt
          updatedAt
          __typename
        }
        labels {
          items {
            id
            dataSourceID
            labelID
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            dataSourceLabelDataSourcesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        groups
        __typename
      }
      limit
      fields
      sort {
        id
        sortDirection
        __typename
      }
      dimensionsFilters {
        orFilters {
          fieldName
          values
          type
          operator
          __typename
        }
        __typename
      }
      dateRangeType
      dateRange {
        startDate
        endDate
        __typename
      }
      createdAt
      updatedAt
      dataSourceDataUrlsId
      __typename
    }
  }
`;
export const createComposerQuery = /* GraphQL */ `
  mutation CreateComposerQuery(
    $input: CreateComposerQueryInput!
    $condition: ModelComposerQueryConditionInput
  ) {
    createComposerQuery(input: $input, condition: $condition) {
      id
      owner
      groups
      name
      nameLowercase
      dataSource {
        id
        owner
        editors
        admins
        createdAt
        updatedAt
        dataProvider
        displayName
        displayNameLowercase
        configParamsHash
        name
        nameLowercase
        tags
        tagsLowercase
        tagsLowercaseToString
        url
        hostname
        domain
        type
        view {
          viewId
          viewName
          propertyId
          websiteId
          loginCustomerId
          customerId
          site
          locationId
          channelId
          spreadsheetId
          accountId
          projectId
          dc
          connectorId
          dataSourceIds
          credentialIds
          merchantId
          isManager
          timeZone
          reportType
          url
          device
          type
          dataSourceId
          credentialId
          __typename
        }
        multipleViews {
          viewId
          viewName
          propertyId
          websiteId
          loginCustomerId
          customerId
          site
          locationId
          channelId
          spreadsheetId
          accountId
          projectId
          dc
          connectorId
          dataSourceIds
          credentialIds
          merchantId
          isManager
          timeZone
          reportType
          url
          device
          type
          dataSourceId
          credentialId
          __typename
        }
        dataUrls {
          items {
            id
            owner
            groups
            name
            nameLowercase
            status
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            createdAt
            updatedAt
            dataSourceDataUrlsId
            __typename
          }
          nextToken
          __typename
        }
        queries {
          items {
            id
            owner
            groups
            name
            nameLowercase
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            additionalFields {
              name
              value
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            feedTemplates {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                name
                nameLowercase
                root
                default
                conditions
                queryId
                iconUrl
                publish
                iconData
                teamId
                dataSourceId
                parameters
                __typename
              }
              nextToken
              __typename
            }
            queryTeamId
            iconUrl
            createdAt
            updatedAt
            dataSourceQueriesId
            __typename
          }
          nextToken
          __typename
        }
        preCacheRequests {
          items {
            id
            name
            nameLowercase
            fields
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            requestCount
            query
            TTL
            updatedAt
            createdAt
            dataSourcePreCacheRequestsId
            __typename
          }
          nextToken
          __typename
        }
        notificationActions {
          items {
            id
            owner
            groups
            name
            nameLowercase
            status
            notificationService
            template
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            schedule
            trigger
            notificationActionCredentialId
            createdAt
            updatedAt
            dataSourceNotificationActionsId
            __typename
          }
          nextToken
          __typename
        }
        integrations {
          items {
            id
            type
            dataProvider
            additionalSettings {
              name
              value
              __typename
            }
            query {
              id
              owner
              groups
              name
              nameLowercase
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              additionalFields {
                name
                value
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              feedTemplates {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  name
                  nameLowercase
                  root
                  default
                  conditions
                  queryId
                  iconUrl
                  publish
                  iconData
                  teamId
                  dataSourceId
                  parameters
                  __typename
                }
                nextToken
                __typename
              }
              queryTeamId
              iconUrl
              createdAt
              updatedAt
              dataSourceQueriesId
              __typename
            }
            integrationCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            integrationDataSourceId
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            createdAt
            updatedAt
            composerQueryIntegrationsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        dataFreshness
        filter {
          isActive
          dimensionsFilters {
            fieldName
            values
            type
            operator
            __typename
          }
          segment {
            id
            name
            __typename
          }
          __typename
        }
        version
        rateLimit {
          max
          interval
          __typename
        }
        dataSourceCredentialId
        credential {
          id
          name
          email
          picture
          status
          owners
          editors
          createdAt
          updatedAt
          credentialTeamId
          authType
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          dataProvider
          groups
          label {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          credentialLabelId
          authDataId
          clientId
          authData {
            accessToken
            refreshToken
            scope
            tokenType
            idToken
            expiryDate
            username
            password
            token
            key
            environment
            __typename
          }
          __typename
        }
        dataSourceTeamId
        team {
          id
          avatar {
            visibility
            identityId
            file {
              bucket
              key
              region
              fileName
              __typename
            }
            __typename
          }
          name
          owner
          members
          admins
          editors
          emailDomains
          autoInviteDomains
          teamMembers {
            items {
              id
              teamMembershipUserId
              user {
                id
                username
                avatar {
                  visibility
                  identityId
                  __typename
                }
                userType
                defaultTeamId
                userSettings {
                  onboardingCompleted
                  releaseNotes
                  __typename
                }
                teams {
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              teamMembershipTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              owner
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          credentials {
            items {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          dataSourceLabels {
            items {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultTemplateId
          workspaceType
          createdAt
          updatedAt
          __typename
        }
        labels {
          items {
            id
            dataSourceID
            labelID
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            dataSourceLabelDataSourcesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        groups
        __typename
      }
      limit
      fields
      sort {
        id
        sortDirection
        __typename
      }
      dimensionsFilters {
        orFilters {
          fieldName
          values
          type
          operator
          __typename
        }
        __typename
      }
      dateRangeType
      dateRange {
        startDate
        endDate
        __typename
      }
      additionalFields {
        name
        value
        __typename
      }
      integrations {
        items {
          id
          type
          dataProvider
          additionalSettings {
            name
            value
            __typename
          }
          query {
            id
            owner
            groups
            name
            nameLowercase
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            additionalFields {
              name
              value
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            feedTemplates {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                name
                nameLowercase
                root
                default
                conditions
                queryId
                iconUrl
                publish
                iconData
                teamId
                dataSourceId
                parameters
                __typename
              }
              nextToken
              __typename
            }
            queryTeamId
            iconUrl
            createdAt
            updatedAt
            dataSourceQueriesId
            __typename
          }
          integrationCredentialId
          credential {
            id
            name
            email
            picture
            status
            owners
            editors
            createdAt
            updatedAt
            credentialTeamId
            authType
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            dataProvider
            groups
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            credentialLabelId
            authDataId
            clientId
            authData {
              accessToken
              refreshToken
              scope
              tokenType
              idToken
              expiryDate
              username
              password
              token
              key
              environment
              __typename
            }
            __typename
          }
          integrationDataSourceId
          dataSource {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          createdAt
          updatedAt
          composerQueryIntegrationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      feedTemplates {
        items {
          id
          owner
          editors
          admins
          createdAt
          updatedAt
          name
          nameLowercase
          root
          default
          conditions
          queryId
          iconUrl
          publish
          iconData
          teamId
          dataSourceId
          parameters
          __typename
        }
        nextToken
        __typename
      }
      queryTeamId
      iconUrl
      createdAt
      updatedAt
      dataSourceQueriesId
      __typename
    }
  }
`;
export const updateComposerQuery = /* GraphQL */ `
  mutation UpdateComposerQuery(
    $input: UpdateComposerQueryInput!
    $condition: ModelComposerQueryConditionInput
  ) {
    updateComposerQuery(input: $input, condition: $condition) {
      id
      owner
      groups
      name
      nameLowercase
      dataSource {
        id
        owner
        editors
        admins
        createdAt
        updatedAt
        dataProvider
        displayName
        displayNameLowercase
        configParamsHash
        name
        nameLowercase
        tags
        tagsLowercase
        tagsLowercaseToString
        url
        hostname
        domain
        type
        view {
          viewId
          viewName
          propertyId
          websiteId
          loginCustomerId
          customerId
          site
          locationId
          channelId
          spreadsheetId
          accountId
          projectId
          dc
          connectorId
          dataSourceIds
          credentialIds
          merchantId
          isManager
          timeZone
          reportType
          url
          device
          type
          dataSourceId
          credentialId
          __typename
        }
        multipleViews {
          viewId
          viewName
          propertyId
          websiteId
          loginCustomerId
          customerId
          site
          locationId
          channelId
          spreadsheetId
          accountId
          projectId
          dc
          connectorId
          dataSourceIds
          credentialIds
          merchantId
          isManager
          timeZone
          reportType
          url
          device
          type
          dataSourceId
          credentialId
          __typename
        }
        dataUrls {
          items {
            id
            owner
            groups
            name
            nameLowercase
            status
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            createdAt
            updatedAt
            dataSourceDataUrlsId
            __typename
          }
          nextToken
          __typename
        }
        queries {
          items {
            id
            owner
            groups
            name
            nameLowercase
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            additionalFields {
              name
              value
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            feedTemplates {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                name
                nameLowercase
                root
                default
                conditions
                queryId
                iconUrl
                publish
                iconData
                teamId
                dataSourceId
                parameters
                __typename
              }
              nextToken
              __typename
            }
            queryTeamId
            iconUrl
            createdAt
            updatedAt
            dataSourceQueriesId
            __typename
          }
          nextToken
          __typename
        }
        preCacheRequests {
          items {
            id
            name
            nameLowercase
            fields
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            requestCount
            query
            TTL
            updatedAt
            createdAt
            dataSourcePreCacheRequestsId
            __typename
          }
          nextToken
          __typename
        }
        notificationActions {
          items {
            id
            owner
            groups
            name
            nameLowercase
            status
            notificationService
            template
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            schedule
            trigger
            notificationActionCredentialId
            createdAt
            updatedAt
            dataSourceNotificationActionsId
            __typename
          }
          nextToken
          __typename
        }
        integrations {
          items {
            id
            type
            dataProvider
            additionalSettings {
              name
              value
              __typename
            }
            query {
              id
              owner
              groups
              name
              nameLowercase
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              additionalFields {
                name
                value
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              feedTemplates {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  name
                  nameLowercase
                  root
                  default
                  conditions
                  queryId
                  iconUrl
                  publish
                  iconData
                  teamId
                  dataSourceId
                  parameters
                  __typename
                }
                nextToken
                __typename
              }
              queryTeamId
              iconUrl
              createdAt
              updatedAt
              dataSourceQueriesId
              __typename
            }
            integrationCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            integrationDataSourceId
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            createdAt
            updatedAt
            composerQueryIntegrationsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        dataFreshness
        filter {
          isActive
          dimensionsFilters {
            fieldName
            values
            type
            operator
            __typename
          }
          segment {
            id
            name
            __typename
          }
          __typename
        }
        version
        rateLimit {
          max
          interval
          __typename
        }
        dataSourceCredentialId
        credential {
          id
          name
          email
          picture
          status
          owners
          editors
          createdAt
          updatedAt
          credentialTeamId
          authType
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          dataProvider
          groups
          label {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          credentialLabelId
          authDataId
          clientId
          authData {
            accessToken
            refreshToken
            scope
            tokenType
            idToken
            expiryDate
            username
            password
            token
            key
            environment
            __typename
          }
          __typename
        }
        dataSourceTeamId
        team {
          id
          avatar {
            visibility
            identityId
            file {
              bucket
              key
              region
              fileName
              __typename
            }
            __typename
          }
          name
          owner
          members
          admins
          editors
          emailDomains
          autoInviteDomains
          teamMembers {
            items {
              id
              teamMembershipUserId
              user {
                id
                username
                avatar {
                  visibility
                  identityId
                  __typename
                }
                userType
                defaultTeamId
                userSettings {
                  onboardingCompleted
                  releaseNotes
                  __typename
                }
                teams {
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              teamMembershipTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              owner
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          credentials {
            items {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          dataSourceLabels {
            items {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultTemplateId
          workspaceType
          createdAt
          updatedAt
          __typename
        }
        labels {
          items {
            id
            dataSourceID
            labelID
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            dataSourceLabelDataSourcesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        groups
        __typename
      }
      limit
      fields
      sort {
        id
        sortDirection
        __typename
      }
      dimensionsFilters {
        orFilters {
          fieldName
          values
          type
          operator
          __typename
        }
        __typename
      }
      dateRangeType
      dateRange {
        startDate
        endDate
        __typename
      }
      additionalFields {
        name
        value
        __typename
      }
      integrations {
        items {
          id
          type
          dataProvider
          additionalSettings {
            name
            value
            __typename
          }
          query {
            id
            owner
            groups
            name
            nameLowercase
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            additionalFields {
              name
              value
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            feedTemplates {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                name
                nameLowercase
                root
                default
                conditions
                queryId
                iconUrl
                publish
                iconData
                teamId
                dataSourceId
                parameters
                __typename
              }
              nextToken
              __typename
            }
            queryTeamId
            iconUrl
            createdAt
            updatedAt
            dataSourceQueriesId
            __typename
          }
          integrationCredentialId
          credential {
            id
            name
            email
            picture
            status
            owners
            editors
            createdAt
            updatedAt
            credentialTeamId
            authType
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            dataProvider
            groups
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            credentialLabelId
            authDataId
            clientId
            authData {
              accessToken
              refreshToken
              scope
              tokenType
              idToken
              expiryDate
              username
              password
              token
              key
              environment
              __typename
            }
            __typename
          }
          integrationDataSourceId
          dataSource {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          createdAt
          updatedAt
          composerQueryIntegrationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      feedTemplates {
        items {
          id
          owner
          editors
          admins
          createdAt
          updatedAt
          name
          nameLowercase
          root
          default
          conditions
          queryId
          iconUrl
          publish
          iconData
          teamId
          dataSourceId
          parameters
          __typename
        }
        nextToken
        __typename
      }
      queryTeamId
      iconUrl
      createdAt
      updatedAt
      dataSourceQueriesId
      __typename
    }
  }
`;
export const deleteComposerQuery = /* GraphQL */ `
  mutation DeleteComposerQuery(
    $input: DeleteComposerQueryInput!
    $condition: ModelComposerQueryConditionInput
  ) {
    deleteComposerQuery(input: $input, condition: $condition) {
      id
      owner
      groups
      name
      nameLowercase
      dataSource {
        id
        owner
        editors
        admins
        createdAt
        updatedAt
        dataProvider
        displayName
        displayNameLowercase
        configParamsHash
        name
        nameLowercase
        tags
        tagsLowercase
        tagsLowercaseToString
        url
        hostname
        domain
        type
        view {
          viewId
          viewName
          propertyId
          websiteId
          loginCustomerId
          customerId
          site
          locationId
          channelId
          spreadsheetId
          accountId
          projectId
          dc
          connectorId
          dataSourceIds
          credentialIds
          merchantId
          isManager
          timeZone
          reportType
          url
          device
          type
          dataSourceId
          credentialId
          __typename
        }
        multipleViews {
          viewId
          viewName
          propertyId
          websiteId
          loginCustomerId
          customerId
          site
          locationId
          channelId
          spreadsheetId
          accountId
          projectId
          dc
          connectorId
          dataSourceIds
          credentialIds
          merchantId
          isManager
          timeZone
          reportType
          url
          device
          type
          dataSourceId
          credentialId
          __typename
        }
        dataUrls {
          items {
            id
            owner
            groups
            name
            nameLowercase
            status
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            createdAt
            updatedAt
            dataSourceDataUrlsId
            __typename
          }
          nextToken
          __typename
        }
        queries {
          items {
            id
            owner
            groups
            name
            nameLowercase
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            additionalFields {
              name
              value
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            feedTemplates {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                name
                nameLowercase
                root
                default
                conditions
                queryId
                iconUrl
                publish
                iconData
                teamId
                dataSourceId
                parameters
                __typename
              }
              nextToken
              __typename
            }
            queryTeamId
            iconUrl
            createdAt
            updatedAt
            dataSourceQueriesId
            __typename
          }
          nextToken
          __typename
        }
        preCacheRequests {
          items {
            id
            name
            nameLowercase
            fields
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            requestCount
            query
            TTL
            updatedAt
            createdAt
            dataSourcePreCacheRequestsId
            __typename
          }
          nextToken
          __typename
        }
        notificationActions {
          items {
            id
            owner
            groups
            name
            nameLowercase
            status
            notificationService
            template
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            schedule
            trigger
            notificationActionCredentialId
            createdAt
            updatedAt
            dataSourceNotificationActionsId
            __typename
          }
          nextToken
          __typename
        }
        integrations {
          items {
            id
            type
            dataProvider
            additionalSettings {
              name
              value
              __typename
            }
            query {
              id
              owner
              groups
              name
              nameLowercase
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              additionalFields {
                name
                value
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              feedTemplates {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  name
                  nameLowercase
                  root
                  default
                  conditions
                  queryId
                  iconUrl
                  publish
                  iconData
                  teamId
                  dataSourceId
                  parameters
                  __typename
                }
                nextToken
                __typename
              }
              queryTeamId
              iconUrl
              createdAt
              updatedAt
              dataSourceQueriesId
              __typename
            }
            integrationCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            integrationDataSourceId
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            createdAt
            updatedAt
            composerQueryIntegrationsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        dataFreshness
        filter {
          isActive
          dimensionsFilters {
            fieldName
            values
            type
            operator
            __typename
          }
          segment {
            id
            name
            __typename
          }
          __typename
        }
        version
        rateLimit {
          max
          interval
          __typename
        }
        dataSourceCredentialId
        credential {
          id
          name
          email
          picture
          status
          owners
          editors
          createdAt
          updatedAt
          credentialTeamId
          authType
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          dataProvider
          groups
          label {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          credentialLabelId
          authDataId
          clientId
          authData {
            accessToken
            refreshToken
            scope
            tokenType
            idToken
            expiryDate
            username
            password
            token
            key
            environment
            __typename
          }
          __typename
        }
        dataSourceTeamId
        team {
          id
          avatar {
            visibility
            identityId
            file {
              bucket
              key
              region
              fileName
              __typename
            }
            __typename
          }
          name
          owner
          members
          admins
          editors
          emailDomains
          autoInviteDomains
          teamMembers {
            items {
              id
              teamMembershipUserId
              user {
                id
                username
                avatar {
                  visibility
                  identityId
                  __typename
                }
                userType
                defaultTeamId
                userSettings {
                  onboardingCompleted
                  releaseNotes
                  __typename
                }
                teams {
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              teamMembershipTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              owner
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          credentials {
            items {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          dataSourceLabels {
            items {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultTemplateId
          workspaceType
          createdAt
          updatedAt
          __typename
        }
        labels {
          items {
            id
            dataSourceID
            labelID
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            dataSourceLabelDataSourcesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        groups
        __typename
      }
      limit
      fields
      sort {
        id
        sortDirection
        __typename
      }
      dimensionsFilters {
        orFilters {
          fieldName
          values
          type
          operator
          __typename
        }
        __typename
      }
      dateRangeType
      dateRange {
        startDate
        endDate
        __typename
      }
      additionalFields {
        name
        value
        __typename
      }
      integrations {
        items {
          id
          type
          dataProvider
          additionalSettings {
            name
            value
            __typename
          }
          query {
            id
            owner
            groups
            name
            nameLowercase
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            additionalFields {
              name
              value
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            feedTemplates {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                name
                nameLowercase
                root
                default
                conditions
                queryId
                iconUrl
                publish
                iconData
                teamId
                dataSourceId
                parameters
                __typename
              }
              nextToken
              __typename
            }
            queryTeamId
            iconUrl
            createdAt
            updatedAt
            dataSourceQueriesId
            __typename
          }
          integrationCredentialId
          credential {
            id
            name
            email
            picture
            status
            owners
            editors
            createdAt
            updatedAt
            credentialTeamId
            authType
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            dataProvider
            groups
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            credentialLabelId
            authDataId
            clientId
            authData {
              accessToken
              refreshToken
              scope
              tokenType
              idToken
              expiryDate
              username
              password
              token
              key
              environment
              __typename
            }
            __typename
          }
          integrationDataSourceId
          dataSource {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          createdAt
          updatedAt
          composerQueryIntegrationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      feedTemplates {
        items {
          id
          owner
          editors
          admins
          createdAt
          updatedAt
          name
          nameLowercase
          root
          default
          conditions
          queryId
          iconUrl
          publish
          iconData
          teamId
          dataSourceId
          parameters
          __typename
        }
        nextToken
        __typename
      }
      queryTeamId
      iconUrl
      createdAt
      updatedAt
      dataSourceQueriesId
      __typename
    }
  }
`;
export const createQueryIntegration = /* GraphQL */ `
  mutation CreateQueryIntegration(
    $input: CreateQueryIntegrationInput!
    $condition: ModelQueryIntegrationConditionInput
  ) {
    createQueryIntegration(input: $input, condition: $condition) {
      id
      type
      dataProvider
      additionalSettings {
        name
        value
        __typename
      }
      query {
        id
        owner
        groups
        name
        nameLowercase
        dataSource {
          id
          owner
          editors
          admins
          createdAt
          updatedAt
          dataProvider
          displayName
          displayNameLowercase
          configParamsHash
          name
          nameLowercase
          tags
          tagsLowercase
          tagsLowercaseToString
          url
          hostname
          domain
          type
          view {
            viewId
            viewName
            propertyId
            websiteId
            loginCustomerId
            customerId
            site
            locationId
            channelId
            spreadsheetId
            accountId
            projectId
            dc
            connectorId
            dataSourceIds
            credentialIds
            merchantId
            isManager
            timeZone
            reportType
            url
            device
            type
            dataSourceId
            credentialId
            __typename
          }
          multipleViews {
            viewId
            viewName
            propertyId
            websiteId
            loginCustomerId
            customerId
            site
            locationId
            channelId
            spreadsheetId
            accountId
            projectId
            dc
            connectorId
            dataSourceIds
            credentialIds
            merchantId
            isManager
            timeZone
            reportType
            url
            device
            type
            dataSourceId
            credentialId
            __typename
          }
          dataUrls {
            items {
              id
              owner
              groups
              name
              nameLowercase
              status
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              createdAt
              updatedAt
              dataSourceDataUrlsId
              __typename
            }
            nextToken
            __typename
          }
          queries {
            items {
              id
              owner
              groups
              name
              nameLowercase
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              additionalFields {
                name
                value
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              feedTemplates {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  name
                  nameLowercase
                  root
                  default
                  conditions
                  queryId
                  iconUrl
                  publish
                  iconData
                  teamId
                  dataSourceId
                  parameters
                  __typename
                }
                nextToken
                __typename
              }
              queryTeamId
              iconUrl
              createdAt
              updatedAt
              dataSourceQueriesId
              __typename
            }
            nextToken
            __typename
          }
          preCacheRequests {
            items {
              id
              name
              nameLowercase
              fields
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              requestCount
              query
              TTL
              updatedAt
              createdAt
              dataSourcePreCacheRequestsId
              __typename
            }
            nextToken
            __typename
          }
          notificationActions {
            items {
              id
              owner
              groups
              name
              nameLowercase
              status
              notificationService
              template
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              schedule
              trigger
              notificationActionCredentialId
              createdAt
              updatedAt
              dataSourceNotificationActionsId
              __typename
            }
            nextToken
            __typename
          }
          integrations {
            items {
              id
              type
              dataProvider
              additionalSettings {
                name
                value
                __typename
              }
              query {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              integrationCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              integrationDataSourceId
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              createdAt
              updatedAt
              composerQueryIntegrationsId
              owner
              __typename
            }
            nextToken
            __typename
          }
          dataFreshness
          filter {
            isActive
            dimensionsFilters {
              fieldName
              values
              type
              operator
              __typename
            }
            segment {
              id
              name
              __typename
            }
            __typename
          }
          version
          rateLimit {
            max
            interval
            __typename
          }
          dataSourceCredentialId
          credential {
            id
            name
            email
            picture
            status
            owners
            editors
            createdAt
            updatedAt
            credentialTeamId
            authType
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            dataProvider
            groups
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            credentialLabelId
            authDataId
            clientId
            authData {
              accessToken
              refreshToken
              scope
              tokenType
              idToken
              expiryDate
              username
              password
              token
              key
              environment
              __typename
            }
            __typename
          }
          dataSourceTeamId
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          labels {
            items {
              id
              dataSourceID
              labelID
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              dataSourceLabelDataSourcesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          groups
          __typename
        }
        limit
        fields
        sort {
          id
          sortDirection
          __typename
        }
        dimensionsFilters {
          orFilters {
            fieldName
            values
            type
            operator
            __typename
          }
          __typename
        }
        dateRangeType
        dateRange {
          startDate
          endDate
          __typename
        }
        additionalFields {
          name
          value
          __typename
        }
        integrations {
          items {
            id
            type
            dataProvider
            additionalSettings {
              name
              value
              __typename
            }
            query {
              id
              owner
              groups
              name
              nameLowercase
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              additionalFields {
                name
                value
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              feedTemplates {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  name
                  nameLowercase
                  root
                  default
                  conditions
                  queryId
                  iconUrl
                  publish
                  iconData
                  teamId
                  dataSourceId
                  parameters
                  __typename
                }
                nextToken
                __typename
              }
              queryTeamId
              iconUrl
              createdAt
              updatedAt
              dataSourceQueriesId
              __typename
            }
            integrationCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            integrationDataSourceId
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            createdAt
            updatedAt
            composerQueryIntegrationsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        feedTemplates {
          items {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            name
            nameLowercase
            root
            default
            conditions
            queryId
            iconUrl
            publish
            iconData
            teamId
            dataSourceId
            parameters
            __typename
          }
          nextToken
          __typename
        }
        queryTeamId
        iconUrl
        createdAt
        updatedAt
        dataSourceQueriesId
        __typename
      }
      integrationCredentialId
      credential {
        id
        name
        email
        picture
        status
        owners
        editors
        createdAt
        updatedAt
        credentialTeamId
        authType
        team {
          id
          avatar {
            visibility
            identityId
            file {
              bucket
              key
              region
              fileName
              __typename
            }
            __typename
          }
          name
          owner
          members
          admins
          editors
          emailDomains
          autoInviteDomains
          teamMembers {
            items {
              id
              teamMembershipUserId
              user {
                id
                username
                avatar {
                  visibility
                  identityId
                  __typename
                }
                userType
                defaultTeamId
                userSettings {
                  onboardingCompleted
                  releaseNotes
                  __typename
                }
                teams {
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              teamMembershipTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              owner
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          credentials {
            items {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          dataSourceLabels {
            items {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultTemplateId
          workspaceType
          createdAt
          updatedAt
          __typename
        }
        dataSources {
          items {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          nextToken
          __typename
        }
        dataProvider
        groups
        label {
          id
          name
          nameLowercase
          value
          type
          dataSourceLabelTeamId
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          createdAt
          dataProviders
          dataSources {
            items {
              id
              dataSourceID
              labelID
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              dataSourceLabelDataSourcesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        credentialLabelId
        authDataId
        clientId
        authData {
          accessToken
          refreshToken
          scope
          tokenType
          idToken
          expiryDate
          username
          password
          token
          key
          environment
          __typename
        }
        __typename
      }
      integrationDataSourceId
      dataSource {
        id
        owner
        editors
        admins
        createdAt
        updatedAt
        dataProvider
        displayName
        displayNameLowercase
        configParamsHash
        name
        nameLowercase
        tags
        tagsLowercase
        tagsLowercaseToString
        url
        hostname
        domain
        type
        view {
          viewId
          viewName
          propertyId
          websiteId
          loginCustomerId
          customerId
          site
          locationId
          channelId
          spreadsheetId
          accountId
          projectId
          dc
          connectorId
          dataSourceIds
          credentialIds
          merchantId
          isManager
          timeZone
          reportType
          url
          device
          type
          dataSourceId
          credentialId
          __typename
        }
        multipleViews {
          viewId
          viewName
          propertyId
          websiteId
          loginCustomerId
          customerId
          site
          locationId
          channelId
          spreadsheetId
          accountId
          projectId
          dc
          connectorId
          dataSourceIds
          credentialIds
          merchantId
          isManager
          timeZone
          reportType
          url
          device
          type
          dataSourceId
          credentialId
          __typename
        }
        dataUrls {
          items {
            id
            owner
            groups
            name
            nameLowercase
            status
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            createdAt
            updatedAt
            dataSourceDataUrlsId
            __typename
          }
          nextToken
          __typename
        }
        queries {
          items {
            id
            owner
            groups
            name
            nameLowercase
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            additionalFields {
              name
              value
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            feedTemplates {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                name
                nameLowercase
                root
                default
                conditions
                queryId
                iconUrl
                publish
                iconData
                teamId
                dataSourceId
                parameters
                __typename
              }
              nextToken
              __typename
            }
            queryTeamId
            iconUrl
            createdAt
            updatedAt
            dataSourceQueriesId
            __typename
          }
          nextToken
          __typename
        }
        preCacheRequests {
          items {
            id
            name
            nameLowercase
            fields
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            requestCount
            query
            TTL
            updatedAt
            createdAt
            dataSourcePreCacheRequestsId
            __typename
          }
          nextToken
          __typename
        }
        notificationActions {
          items {
            id
            owner
            groups
            name
            nameLowercase
            status
            notificationService
            template
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            schedule
            trigger
            notificationActionCredentialId
            createdAt
            updatedAt
            dataSourceNotificationActionsId
            __typename
          }
          nextToken
          __typename
        }
        integrations {
          items {
            id
            type
            dataProvider
            additionalSettings {
              name
              value
              __typename
            }
            query {
              id
              owner
              groups
              name
              nameLowercase
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              additionalFields {
                name
                value
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              feedTemplates {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  name
                  nameLowercase
                  root
                  default
                  conditions
                  queryId
                  iconUrl
                  publish
                  iconData
                  teamId
                  dataSourceId
                  parameters
                  __typename
                }
                nextToken
                __typename
              }
              queryTeamId
              iconUrl
              createdAt
              updatedAt
              dataSourceQueriesId
              __typename
            }
            integrationCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            integrationDataSourceId
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            createdAt
            updatedAt
            composerQueryIntegrationsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        dataFreshness
        filter {
          isActive
          dimensionsFilters {
            fieldName
            values
            type
            operator
            __typename
          }
          segment {
            id
            name
            __typename
          }
          __typename
        }
        version
        rateLimit {
          max
          interval
          __typename
        }
        dataSourceCredentialId
        credential {
          id
          name
          email
          picture
          status
          owners
          editors
          createdAt
          updatedAt
          credentialTeamId
          authType
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          dataProvider
          groups
          label {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          credentialLabelId
          authDataId
          clientId
          authData {
            accessToken
            refreshToken
            scope
            tokenType
            idToken
            expiryDate
            username
            password
            token
            key
            environment
            __typename
          }
          __typename
        }
        dataSourceTeamId
        team {
          id
          avatar {
            visibility
            identityId
            file {
              bucket
              key
              region
              fileName
              __typename
            }
            __typename
          }
          name
          owner
          members
          admins
          editors
          emailDomains
          autoInviteDomains
          teamMembers {
            items {
              id
              teamMembershipUserId
              user {
                id
                username
                avatar {
                  visibility
                  identityId
                  __typename
                }
                userType
                defaultTeamId
                userSettings {
                  onboardingCompleted
                  releaseNotes
                  __typename
                }
                teams {
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              teamMembershipTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              owner
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          credentials {
            items {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          dataSourceLabels {
            items {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultTemplateId
          workspaceType
          createdAt
          updatedAt
          __typename
        }
        labels {
          items {
            id
            dataSourceID
            labelID
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            dataSourceLabelDataSourcesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        groups
        __typename
      }
      createdAt
      updatedAt
      composerQueryIntegrationsId
      owner
      __typename
    }
  }
`;
export const updateQueryIntegration = /* GraphQL */ `
  mutation UpdateQueryIntegration(
    $input: UpdateQueryIntegrationInput!
    $condition: ModelQueryIntegrationConditionInput
  ) {
    updateQueryIntegration(input: $input, condition: $condition) {
      id
      type
      dataProvider
      additionalSettings {
        name
        value
        __typename
      }
      query {
        id
        owner
        groups
        name
        nameLowercase
        dataSource {
          id
          owner
          editors
          admins
          createdAt
          updatedAt
          dataProvider
          displayName
          displayNameLowercase
          configParamsHash
          name
          nameLowercase
          tags
          tagsLowercase
          tagsLowercaseToString
          url
          hostname
          domain
          type
          view {
            viewId
            viewName
            propertyId
            websiteId
            loginCustomerId
            customerId
            site
            locationId
            channelId
            spreadsheetId
            accountId
            projectId
            dc
            connectorId
            dataSourceIds
            credentialIds
            merchantId
            isManager
            timeZone
            reportType
            url
            device
            type
            dataSourceId
            credentialId
            __typename
          }
          multipleViews {
            viewId
            viewName
            propertyId
            websiteId
            loginCustomerId
            customerId
            site
            locationId
            channelId
            spreadsheetId
            accountId
            projectId
            dc
            connectorId
            dataSourceIds
            credentialIds
            merchantId
            isManager
            timeZone
            reportType
            url
            device
            type
            dataSourceId
            credentialId
            __typename
          }
          dataUrls {
            items {
              id
              owner
              groups
              name
              nameLowercase
              status
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              createdAt
              updatedAt
              dataSourceDataUrlsId
              __typename
            }
            nextToken
            __typename
          }
          queries {
            items {
              id
              owner
              groups
              name
              nameLowercase
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              additionalFields {
                name
                value
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              feedTemplates {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  name
                  nameLowercase
                  root
                  default
                  conditions
                  queryId
                  iconUrl
                  publish
                  iconData
                  teamId
                  dataSourceId
                  parameters
                  __typename
                }
                nextToken
                __typename
              }
              queryTeamId
              iconUrl
              createdAt
              updatedAt
              dataSourceQueriesId
              __typename
            }
            nextToken
            __typename
          }
          preCacheRequests {
            items {
              id
              name
              nameLowercase
              fields
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              requestCount
              query
              TTL
              updatedAt
              createdAt
              dataSourcePreCacheRequestsId
              __typename
            }
            nextToken
            __typename
          }
          notificationActions {
            items {
              id
              owner
              groups
              name
              nameLowercase
              status
              notificationService
              template
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              schedule
              trigger
              notificationActionCredentialId
              createdAt
              updatedAt
              dataSourceNotificationActionsId
              __typename
            }
            nextToken
            __typename
          }
          integrations {
            items {
              id
              type
              dataProvider
              additionalSettings {
                name
                value
                __typename
              }
              query {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              integrationCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              integrationDataSourceId
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              createdAt
              updatedAt
              composerQueryIntegrationsId
              owner
              __typename
            }
            nextToken
            __typename
          }
          dataFreshness
          filter {
            isActive
            dimensionsFilters {
              fieldName
              values
              type
              operator
              __typename
            }
            segment {
              id
              name
              __typename
            }
            __typename
          }
          version
          rateLimit {
            max
            interval
            __typename
          }
          dataSourceCredentialId
          credential {
            id
            name
            email
            picture
            status
            owners
            editors
            createdAt
            updatedAt
            credentialTeamId
            authType
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            dataProvider
            groups
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            credentialLabelId
            authDataId
            clientId
            authData {
              accessToken
              refreshToken
              scope
              tokenType
              idToken
              expiryDate
              username
              password
              token
              key
              environment
              __typename
            }
            __typename
          }
          dataSourceTeamId
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          labels {
            items {
              id
              dataSourceID
              labelID
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              dataSourceLabelDataSourcesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          groups
          __typename
        }
        limit
        fields
        sort {
          id
          sortDirection
          __typename
        }
        dimensionsFilters {
          orFilters {
            fieldName
            values
            type
            operator
            __typename
          }
          __typename
        }
        dateRangeType
        dateRange {
          startDate
          endDate
          __typename
        }
        additionalFields {
          name
          value
          __typename
        }
        integrations {
          items {
            id
            type
            dataProvider
            additionalSettings {
              name
              value
              __typename
            }
            query {
              id
              owner
              groups
              name
              nameLowercase
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              additionalFields {
                name
                value
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              feedTemplates {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  name
                  nameLowercase
                  root
                  default
                  conditions
                  queryId
                  iconUrl
                  publish
                  iconData
                  teamId
                  dataSourceId
                  parameters
                  __typename
                }
                nextToken
                __typename
              }
              queryTeamId
              iconUrl
              createdAt
              updatedAt
              dataSourceQueriesId
              __typename
            }
            integrationCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            integrationDataSourceId
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            createdAt
            updatedAt
            composerQueryIntegrationsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        feedTemplates {
          items {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            name
            nameLowercase
            root
            default
            conditions
            queryId
            iconUrl
            publish
            iconData
            teamId
            dataSourceId
            parameters
            __typename
          }
          nextToken
          __typename
        }
        queryTeamId
        iconUrl
        createdAt
        updatedAt
        dataSourceQueriesId
        __typename
      }
      integrationCredentialId
      credential {
        id
        name
        email
        picture
        status
        owners
        editors
        createdAt
        updatedAt
        credentialTeamId
        authType
        team {
          id
          avatar {
            visibility
            identityId
            file {
              bucket
              key
              region
              fileName
              __typename
            }
            __typename
          }
          name
          owner
          members
          admins
          editors
          emailDomains
          autoInviteDomains
          teamMembers {
            items {
              id
              teamMembershipUserId
              user {
                id
                username
                avatar {
                  visibility
                  identityId
                  __typename
                }
                userType
                defaultTeamId
                userSettings {
                  onboardingCompleted
                  releaseNotes
                  __typename
                }
                teams {
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              teamMembershipTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              owner
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          credentials {
            items {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          dataSourceLabels {
            items {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultTemplateId
          workspaceType
          createdAt
          updatedAt
          __typename
        }
        dataSources {
          items {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          nextToken
          __typename
        }
        dataProvider
        groups
        label {
          id
          name
          nameLowercase
          value
          type
          dataSourceLabelTeamId
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          createdAt
          dataProviders
          dataSources {
            items {
              id
              dataSourceID
              labelID
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              dataSourceLabelDataSourcesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        credentialLabelId
        authDataId
        clientId
        authData {
          accessToken
          refreshToken
          scope
          tokenType
          idToken
          expiryDate
          username
          password
          token
          key
          environment
          __typename
        }
        __typename
      }
      integrationDataSourceId
      dataSource {
        id
        owner
        editors
        admins
        createdAt
        updatedAt
        dataProvider
        displayName
        displayNameLowercase
        configParamsHash
        name
        nameLowercase
        tags
        tagsLowercase
        tagsLowercaseToString
        url
        hostname
        domain
        type
        view {
          viewId
          viewName
          propertyId
          websiteId
          loginCustomerId
          customerId
          site
          locationId
          channelId
          spreadsheetId
          accountId
          projectId
          dc
          connectorId
          dataSourceIds
          credentialIds
          merchantId
          isManager
          timeZone
          reportType
          url
          device
          type
          dataSourceId
          credentialId
          __typename
        }
        multipleViews {
          viewId
          viewName
          propertyId
          websiteId
          loginCustomerId
          customerId
          site
          locationId
          channelId
          spreadsheetId
          accountId
          projectId
          dc
          connectorId
          dataSourceIds
          credentialIds
          merchantId
          isManager
          timeZone
          reportType
          url
          device
          type
          dataSourceId
          credentialId
          __typename
        }
        dataUrls {
          items {
            id
            owner
            groups
            name
            nameLowercase
            status
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            createdAt
            updatedAt
            dataSourceDataUrlsId
            __typename
          }
          nextToken
          __typename
        }
        queries {
          items {
            id
            owner
            groups
            name
            nameLowercase
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            additionalFields {
              name
              value
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            feedTemplates {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                name
                nameLowercase
                root
                default
                conditions
                queryId
                iconUrl
                publish
                iconData
                teamId
                dataSourceId
                parameters
                __typename
              }
              nextToken
              __typename
            }
            queryTeamId
            iconUrl
            createdAt
            updatedAt
            dataSourceQueriesId
            __typename
          }
          nextToken
          __typename
        }
        preCacheRequests {
          items {
            id
            name
            nameLowercase
            fields
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            requestCount
            query
            TTL
            updatedAt
            createdAt
            dataSourcePreCacheRequestsId
            __typename
          }
          nextToken
          __typename
        }
        notificationActions {
          items {
            id
            owner
            groups
            name
            nameLowercase
            status
            notificationService
            template
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            schedule
            trigger
            notificationActionCredentialId
            createdAt
            updatedAt
            dataSourceNotificationActionsId
            __typename
          }
          nextToken
          __typename
        }
        integrations {
          items {
            id
            type
            dataProvider
            additionalSettings {
              name
              value
              __typename
            }
            query {
              id
              owner
              groups
              name
              nameLowercase
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              additionalFields {
                name
                value
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              feedTemplates {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  name
                  nameLowercase
                  root
                  default
                  conditions
                  queryId
                  iconUrl
                  publish
                  iconData
                  teamId
                  dataSourceId
                  parameters
                  __typename
                }
                nextToken
                __typename
              }
              queryTeamId
              iconUrl
              createdAt
              updatedAt
              dataSourceQueriesId
              __typename
            }
            integrationCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            integrationDataSourceId
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            createdAt
            updatedAt
            composerQueryIntegrationsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        dataFreshness
        filter {
          isActive
          dimensionsFilters {
            fieldName
            values
            type
            operator
            __typename
          }
          segment {
            id
            name
            __typename
          }
          __typename
        }
        version
        rateLimit {
          max
          interval
          __typename
        }
        dataSourceCredentialId
        credential {
          id
          name
          email
          picture
          status
          owners
          editors
          createdAt
          updatedAt
          credentialTeamId
          authType
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          dataProvider
          groups
          label {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          credentialLabelId
          authDataId
          clientId
          authData {
            accessToken
            refreshToken
            scope
            tokenType
            idToken
            expiryDate
            username
            password
            token
            key
            environment
            __typename
          }
          __typename
        }
        dataSourceTeamId
        team {
          id
          avatar {
            visibility
            identityId
            file {
              bucket
              key
              region
              fileName
              __typename
            }
            __typename
          }
          name
          owner
          members
          admins
          editors
          emailDomains
          autoInviteDomains
          teamMembers {
            items {
              id
              teamMembershipUserId
              user {
                id
                username
                avatar {
                  visibility
                  identityId
                  __typename
                }
                userType
                defaultTeamId
                userSettings {
                  onboardingCompleted
                  releaseNotes
                  __typename
                }
                teams {
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              teamMembershipTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              owner
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          credentials {
            items {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          dataSourceLabels {
            items {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultTemplateId
          workspaceType
          createdAt
          updatedAt
          __typename
        }
        labels {
          items {
            id
            dataSourceID
            labelID
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            dataSourceLabelDataSourcesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        groups
        __typename
      }
      createdAt
      updatedAt
      composerQueryIntegrationsId
      owner
      __typename
    }
  }
`;
export const deleteQueryIntegration = /* GraphQL */ `
  mutation DeleteQueryIntegration(
    $input: DeleteQueryIntegrationInput!
    $condition: ModelQueryIntegrationConditionInput
  ) {
    deleteQueryIntegration(input: $input, condition: $condition) {
      id
      type
      dataProvider
      additionalSettings {
        name
        value
        __typename
      }
      query {
        id
        owner
        groups
        name
        nameLowercase
        dataSource {
          id
          owner
          editors
          admins
          createdAt
          updatedAt
          dataProvider
          displayName
          displayNameLowercase
          configParamsHash
          name
          nameLowercase
          tags
          tagsLowercase
          tagsLowercaseToString
          url
          hostname
          domain
          type
          view {
            viewId
            viewName
            propertyId
            websiteId
            loginCustomerId
            customerId
            site
            locationId
            channelId
            spreadsheetId
            accountId
            projectId
            dc
            connectorId
            dataSourceIds
            credentialIds
            merchantId
            isManager
            timeZone
            reportType
            url
            device
            type
            dataSourceId
            credentialId
            __typename
          }
          multipleViews {
            viewId
            viewName
            propertyId
            websiteId
            loginCustomerId
            customerId
            site
            locationId
            channelId
            spreadsheetId
            accountId
            projectId
            dc
            connectorId
            dataSourceIds
            credentialIds
            merchantId
            isManager
            timeZone
            reportType
            url
            device
            type
            dataSourceId
            credentialId
            __typename
          }
          dataUrls {
            items {
              id
              owner
              groups
              name
              nameLowercase
              status
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              createdAt
              updatedAt
              dataSourceDataUrlsId
              __typename
            }
            nextToken
            __typename
          }
          queries {
            items {
              id
              owner
              groups
              name
              nameLowercase
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              additionalFields {
                name
                value
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              feedTemplates {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  name
                  nameLowercase
                  root
                  default
                  conditions
                  queryId
                  iconUrl
                  publish
                  iconData
                  teamId
                  dataSourceId
                  parameters
                  __typename
                }
                nextToken
                __typename
              }
              queryTeamId
              iconUrl
              createdAt
              updatedAt
              dataSourceQueriesId
              __typename
            }
            nextToken
            __typename
          }
          preCacheRequests {
            items {
              id
              name
              nameLowercase
              fields
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              requestCount
              query
              TTL
              updatedAt
              createdAt
              dataSourcePreCacheRequestsId
              __typename
            }
            nextToken
            __typename
          }
          notificationActions {
            items {
              id
              owner
              groups
              name
              nameLowercase
              status
              notificationService
              template
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              schedule
              trigger
              notificationActionCredentialId
              createdAt
              updatedAt
              dataSourceNotificationActionsId
              __typename
            }
            nextToken
            __typename
          }
          integrations {
            items {
              id
              type
              dataProvider
              additionalSettings {
                name
                value
                __typename
              }
              query {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              integrationCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              integrationDataSourceId
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              createdAt
              updatedAt
              composerQueryIntegrationsId
              owner
              __typename
            }
            nextToken
            __typename
          }
          dataFreshness
          filter {
            isActive
            dimensionsFilters {
              fieldName
              values
              type
              operator
              __typename
            }
            segment {
              id
              name
              __typename
            }
            __typename
          }
          version
          rateLimit {
            max
            interval
            __typename
          }
          dataSourceCredentialId
          credential {
            id
            name
            email
            picture
            status
            owners
            editors
            createdAt
            updatedAt
            credentialTeamId
            authType
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            dataProvider
            groups
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            credentialLabelId
            authDataId
            clientId
            authData {
              accessToken
              refreshToken
              scope
              tokenType
              idToken
              expiryDate
              username
              password
              token
              key
              environment
              __typename
            }
            __typename
          }
          dataSourceTeamId
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          labels {
            items {
              id
              dataSourceID
              labelID
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              dataSourceLabelDataSourcesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          groups
          __typename
        }
        limit
        fields
        sort {
          id
          sortDirection
          __typename
        }
        dimensionsFilters {
          orFilters {
            fieldName
            values
            type
            operator
            __typename
          }
          __typename
        }
        dateRangeType
        dateRange {
          startDate
          endDate
          __typename
        }
        additionalFields {
          name
          value
          __typename
        }
        integrations {
          items {
            id
            type
            dataProvider
            additionalSettings {
              name
              value
              __typename
            }
            query {
              id
              owner
              groups
              name
              nameLowercase
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              additionalFields {
                name
                value
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              feedTemplates {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  name
                  nameLowercase
                  root
                  default
                  conditions
                  queryId
                  iconUrl
                  publish
                  iconData
                  teamId
                  dataSourceId
                  parameters
                  __typename
                }
                nextToken
                __typename
              }
              queryTeamId
              iconUrl
              createdAt
              updatedAt
              dataSourceQueriesId
              __typename
            }
            integrationCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            integrationDataSourceId
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            createdAt
            updatedAt
            composerQueryIntegrationsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        feedTemplates {
          items {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            name
            nameLowercase
            root
            default
            conditions
            queryId
            iconUrl
            publish
            iconData
            teamId
            dataSourceId
            parameters
            __typename
          }
          nextToken
          __typename
        }
        queryTeamId
        iconUrl
        createdAt
        updatedAt
        dataSourceQueriesId
        __typename
      }
      integrationCredentialId
      credential {
        id
        name
        email
        picture
        status
        owners
        editors
        createdAt
        updatedAt
        credentialTeamId
        authType
        team {
          id
          avatar {
            visibility
            identityId
            file {
              bucket
              key
              region
              fileName
              __typename
            }
            __typename
          }
          name
          owner
          members
          admins
          editors
          emailDomains
          autoInviteDomains
          teamMembers {
            items {
              id
              teamMembershipUserId
              user {
                id
                username
                avatar {
                  visibility
                  identityId
                  __typename
                }
                userType
                defaultTeamId
                userSettings {
                  onboardingCompleted
                  releaseNotes
                  __typename
                }
                teams {
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              teamMembershipTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              owner
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          credentials {
            items {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          dataSourceLabels {
            items {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultTemplateId
          workspaceType
          createdAt
          updatedAt
          __typename
        }
        dataSources {
          items {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          nextToken
          __typename
        }
        dataProvider
        groups
        label {
          id
          name
          nameLowercase
          value
          type
          dataSourceLabelTeamId
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          createdAt
          dataProviders
          dataSources {
            items {
              id
              dataSourceID
              labelID
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              dataSourceLabelDataSourcesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        credentialLabelId
        authDataId
        clientId
        authData {
          accessToken
          refreshToken
          scope
          tokenType
          idToken
          expiryDate
          username
          password
          token
          key
          environment
          __typename
        }
        __typename
      }
      integrationDataSourceId
      dataSource {
        id
        owner
        editors
        admins
        createdAt
        updatedAt
        dataProvider
        displayName
        displayNameLowercase
        configParamsHash
        name
        nameLowercase
        tags
        tagsLowercase
        tagsLowercaseToString
        url
        hostname
        domain
        type
        view {
          viewId
          viewName
          propertyId
          websiteId
          loginCustomerId
          customerId
          site
          locationId
          channelId
          spreadsheetId
          accountId
          projectId
          dc
          connectorId
          dataSourceIds
          credentialIds
          merchantId
          isManager
          timeZone
          reportType
          url
          device
          type
          dataSourceId
          credentialId
          __typename
        }
        multipleViews {
          viewId
          viewName
          propertyId
          websiteId
          loginCustomerId
          customerId
          site
          locationId
          channelId
          spreadsheetId
          accountId
          projectId
          dc
          connectorId
          dataSourceIds
          credentialIds
          merchantId
          isManager
          timeZone
          reportType
          url
          device
          type
          dataSourceId
          credentialId
          __typename
        }
        dataUrls {
          items {
            id
            owner
            groups
            name
            nameLowercase
            status
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            createdAt
            updatedAt
            dataSourceDataUrlsId
            __typename
          }
          nextToken
          __typename
        }
        queries {
          items {
            id
            owner
            groups
            name
            nameLowercase
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            additionalFields {
              name
              value
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            feedTemplates {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                name
                nameLowercase
                root
                default
                conditions
                queryId
                iconUrl
                publish
                iconData
                teamId
                dataSourceId
                parameters
                __typename
              }
              nextToken
              __typename
            }
            queryTeamId
            iconUrl
            createdAt
            updatedAt
            dataSourceQueriesId
            __typename
          }
          nextToken
          __typename
        }
        preCacheRequests {
          items {
            id
            name
            nameLowercase
            fields
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            requestCount
            query
            TTL
            updatedAt
            createdAt
            dataSourcePreCacheRequestsId
            __typename
          }
          nextToken
          __typename
        }
        notificationActions {
          items {
            id
            owner
            groups
            name
            nameLowercase
            status
            notificationService
            template
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            schedule
            trigger
            notificationActionCredentialId
            createdAt
            updatedAt
            dataSourceNotificationActionsId
            __typename
          }
          nextToken
          __typename
        }
        integrations {
          items {
            id
            type
            dataProvider
            additionalSettings {
              name
              value
              __typename
            }
            query {
              id
              owner
              groups
              name
              nameLowercase
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              additionalFields {
                name
                value
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              feedTemplates {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  name
                  nameLowercase
                  root
                  default
                  conditions
                  queryId
                  iconUrl
                  publish
                  iconData
                  teamId
                  dataSourceId
                  parameters
                  __typename
                }
                nextToken
                __typename
              }
              queryTeamId
              iconUrl
              createdAt
              updatedAt
              dataSourceQueriesId
              __typename
            }
            integrationCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            integrationDataSourceId
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            createdAt
            updatedAt
            composerQueryIntegrationsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        dataFreshness
        filter {
          isActive
          dimensionsFilters {
            fieldName
            values
            type
            operator
            __typename
          }
          segment {
            id
            name
            __typename
          }
          __typename
        }
        version
        rateLimit {
          max
          interval
          __typename
        }
        dataSourceCredentialId
        credential {
          id
          name
          email
          picture
          status
          owners
          editors
          createdAt
          updatedAt
          credentialTeamId
          authType
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          dataProvider
          groups
          label {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          credentialLabelId
          authDataId
          clientId
          authData {
            accessToken
            refreshToken
            scope
            tokenType
            idToken
            expiryDate
            username
            password
            token
            key
            environment
            __typename
          }
          __typename
        }
        dataSourceTeamId
        team {
          id
          avatar {
            visibility
            identityId
            file {
              bucket
              key
              region
              fileName
              __typename
            }
            __typename
          }
          name
          owner
          members
          admins
          editors
          emailDomains
          autoInviteDomains
          teamMembers {
            items {
              id
              teamMembershipUserId
              user {
                id
                username
                avatar {
                  visibility
                  identityId
                  __typename
                }
                userType
                defaultTeamId
                userSettings {
                  onboardingCompleted
                  releaseNotes
                  __typename
                }
                teams {
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              teamMembershipTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              owner
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          credentials {
            items {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          dataSourceLabels {
            items {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultTemplateId
          workspaceType
          createdAt
          updatedAt
          __typename
        }
        labels {
          items {
            id
            dataSourceID
            labelID
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            dataSourceLabelDataSourcesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        groups
        __typename
      }
      createdAt
      updatedAt
      composerQueryIntegrationsId
      owner
      __typename
    }
  }
`;
export const createPreCacheRequest = /* GraphQL */ `
  mutation CreatePreCacheRequest(
    $input: CreatePreCacheRequestInput!
    $condition: ModelPreCacheRequestConditionInput
  ) {
    createPreCacheRequest(input: $input, condition: $condition) {
      id
      name
      nameLowercase
      fields
      dimensionsFilters {
        orFilters {
          fieldName
          values
          type
          operator
          __typename
        }
        __typename
      }
      dateRangeType
      dateRange {
        startDate
        endDate
        __typename
      }
      requestCount
      query
      TTL
      updatedAt
      createdAt
      dataSourcePreCacheRequestsId
      __typename
    }
  }
`;
export const updatePreCacheRequest = /* GraphQL */ `
  mutation UpdatePreCacheRequest(
    $input: UpdatePreCacheRequestInput!
    $condition: ModelPreCacheRequestConditionInput
  ) {
    updatePreCacheRequest(input: $input, condition: $condition) {
      id
      name
      nameLowercase
      fields
      dimensionsFilters {
        orFilters {
          fieldName
          values
          type
          operator
          __typename
        }
        __typename
      }
      dateRangeType
      dateRange {
        startDate
        endDate
        __typename
      }
      requestCount
      query
      TTL
      updatedAt
      createdAt
      dataSourcePreCacheRequestsId
      __typename
    }
  }
`;
export const deletePreCacheRequest = /* GraphQL */ `
  mutation DeletePreCacheRequest(
    $input: DeletePreCacheRequestInput!
    $condition: ModelPreCacheRequestConditionInput
  ) {
    deletePreCacheRequest(input: $input, condition: $condition) {
      id
      name
      nameLowercase
      fields
      dimensionsFilters {
        orFilters {
          fieldName
          values
          type
          operator
          __typename
        }
        __typename
      }
      dateRangeType
      dateRange {
        startDate
        endDate
        __typename
      }
      requestCount
      query
      TTL
      updatedAt
      createdAt
      dataSourcePreCacheRequestsId
      __typename
    }
  }
`;
export const createNotificationAction = /* GraphQL */ `
  mutation CreateNotificationAction(
    $input: CreateNotificationActionInput!
    $condition: ModelNotificationActionConditionInput
  ) {
    createNotificationAction(input: $input, condition: $condition) {
      id
      owner
      groups
      name
      nameLowercase
      status
      notificationService
      template
      dataSource {
        id
        owner
        editors
        admins
        createdAt
        updatedAt
        dataProvider
        displayName
        displayNameLowercase
        configParamsHash
        name
        nameLowercase
        tags
        tagsLowercase
        tagsLowercaseToString
        url
        hostname
        domain
        type
        view {
          viewId
          viewName
          propertyId
          websiteId
          loginCustomerId
          customerId
          site
          locationId
          channelId
          spreadsheetId
          accountId
          projectId
          dc
          connectorId
          dataSourceIds
          credentialIds
          merchantId
          isManager
          timeZone
          reportType
          url
          device
          type
          dataSourceId
          credentialId
          __typename
        }
        multipleViews {
          viewId
          viewName
          propertyId
          websiteId
          loginCustomerId
          customerId
          site
          locationId
          channelId
          spreadsheetId
          accountId
          projectId
          dc
          connectorId
          dataSourceIds
          credentialIds
          merchantId
          isManager
          timeZone
          reportType
          url
          device
          type
          dataSourceId
          credentialId
          __typename
        }
        dataUrls {
          items {
            id
            owner
            groups
            name
            nameLowercase
            status
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            createdAt
            updatedAt
            dataSourceDataUrlsId
            __typename
          }
          nextToken
          __typename
        }
        queries {
          items {
            id
            owner
            groups
            name
            nameLowercase
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            additionalFields {
              name
              value
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            feedTemplates {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                name
                nameLowercase
                root
                default
                conditions
                queryId
                iconUrl
                publish
                iconData
                teamId
                dataSourceId
                parameters
                __typename
              }
              nextToken
              __typename
            }
            queryTeamId
            iconUrl
            createdAt
            updatedAt
            dataSourceQueriesId
            __typename
          }
          nextToken
          __typename
        }
        preCacheRequests {
          items {
            id
            name
            nameLowercase
            fields
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            requestCount
            query
            TTL
            updatedAt
            createdAt
            dataSourcePreCacheRequestsId
            __typename
          }
          nextToken
          __typename
        }
        notificationActions {
          items {
            id
            owner
            groups
            name
            nameLowercase
            status
            notificationService
            template
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            schedule
            trigger
            notificationActionCredentialId
            createdAt
            updatedAt
            dataSourceNotificationActionsId
            __typename
          }
          nextToken
          __typename
        }
        integrations {
          items {
            id
            type
            dataProvider
            additionalSettings {
              name
              value
              __typename
            }
            query {
              id
              owner
              groups
              name
              nameLowercase
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              additionalFields {
                name
                value
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              feedTemplates {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  name
                  nameLowercase
                  root
                  default
                  conditions
                  queryId
                  iconUrl
                  publish
                  iconData
                  teamId
                  dataSourceId
                  parameters
                  __typename
                }
                nextToken
                __typename
              }
              queryTeamId
              iconUrl
              createdAt
              updatedAt
              dataSourceQueriesId
              __typename
            }
            integrationCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            integrationDataSourceId
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            createdAt
            updatedAt
            composerQueryIntegrationsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        dataFreshness
        filter {
          isActive
          dimensionsFilters {
            fieldName
            values
            type
            operator
            __typename
          }
          segment {
            id
            name
            __typename
          }
          __typename
        }
        version
        rateLimit {
          max
          interval
          __typename
        }
        dataSourceCredentialId
        credential {
          id
          name
          email
          picture
          status
          owners
          editors
          createdAt
          updatedAt
          credentialTeamId
          authType
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          dataProvider
          groups
          label {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          credentialLabelId
          authDataId
          clientId
          authData {
            accessToken
            refreshToken
            scope
            tokenType
            idToken
            expiryDate
            username
            password
            token
            key
            environment
            __typename
          }
          __typename
        }
        dataSourceTeamId
        team {
          id
          avatar {
            visibility
            identityId
            file {
              bucket
              key
              region
              fileName
              __typename
            }
            __typename
          }
          name
          owner
          members
          admins
          editors
          emailDomains
          autoInviteDomains
          teamMembers {
            items {
              id
              teamMembershipUserId
              user {
                id
                username
                avatar {
                  visibility
                  identityId
                  __typename
                }
                userType
                defaultTeamId
                userSettings {
                  onboardingCompleted
                  releaseNotes
                  __typename
                }
                teams {
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              teamMembershipTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              owner
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          credentials {
            items {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          dataSourceLabels {
            items {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultTemplateId
          workspaceType
          createdAt
          updatedAt
          __typename
        }
        labels {
          items {
            id
            dataSourceID
            labelID
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            dataSourceLabelDataSourcesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        groups
        __typename
      }
      limit
      fields
      sort {
        id
        sortDirection
        __typename
      }
      dimensionsFilters {
        orFilters {
          fieldName
          values
          type
          operator
          __typename
        }
        __typename
      }
      dateRangeType
      dateRange {
        startDate
        endDate
        __typename
      }
      schedule
      trigger
      notificationActionCredentialId
      createdAt
      updatedAt
      dataSourceNotificationActionsId
      __typename
    }
  }
`;
export const updateNotificationAction = /* GraphQL */ `
  mutation UpdateNotificationAction(
    $input: UpdateNotificationActionInput!
    $condition: ModelNotificationActionConditionInput
  ) {
    updateNotificationAction(input: $input, condition: $condition) {
      id
      owner
      groups
      name
      nameLowercase
      status
      notificationService
      template
      dataSource {
        id
        owner
        editors
        admins
        createdAt
        updatedAt
        dataProvider
        displayName
        displayNameLowercase
        configParamsHash
        name
        nameLowercase
        tags
        tagsLowercase
        tagsLowercaseToString
        url
        hostname
        domain
        type
        view {
          viewId
          viewName
          propertyId
          websiteId
          loginCustomerId
          customerId
          site
          locationId
          channelId
          spreadsheetId
          accountId
          projectId
          dc
          connectorId
          dataSourceIds
          credentialIds
          merchantId
          isManager
          timeZone
          reportType
          url
          device
          type
          dataSourceId
          credentialId
          __typename
        }
        multipleViews {
          viewId
          viewName
          propertyId
          websiteId
          loginCustomerId
          customerId
          site
          locationId
          channelId
          spreadsheetId
          accountId
          projectId
          dc
          connectorId
          dataSourceIds
          credentialIds
          merchantId
          isManager
          timeZone
          reportType
          url
          device
          type
          dataSourceId
          credentialId
          __typename
        }
        dataUrls {
          items {
            id
            owner
            groups
            name
            nameLowercase
            status
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            createdAt
            updatedAt
            dataSourceDataUrlsId
            __typename
          }
          nextToken
          __typename
        }
        queries {
          items {
            id
            owner
            groups
            name
            nameLowercase
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            additionalFields {
              name
              value
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            feedTemplates {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                name
                nameLowercase
                root
                default
                conditions
                queryId
                iconUrl
                publish
                iconData
                teamId
                dataSourceId
                parameters
                __typename
              }
              nextToken
              __typename
            }
            queryTeamId
            iconUrl
            createdAt
            updatedAt
            dataSourceQueriesId
            __typename
          }
          nextToken
          __typename
        }
        preCacheRequests {
          items {
            id
            name
            nameLowercase
            fields
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            requestCount
            query
            TTL
            updatedAt
            createdAt
            dataSourcePreCacheRequestsId
            __typename
          }
          nextToken
          __typename
        }
        notificationActions {
          items {
            id
            owner
            groups
            name
            nameLowercase
            status
            notificationService
            template
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            schedule
            trigger
            notificationActionCredentialId
            createdAt
            updatedAt
            dataSourceNotificationActionsId
            __typename
          }
          nextToken
          __typename
        }
        integrations {
          items {
            id
            type
            dataProvider
            additionalSettings {
              name
              value
              __typename
            }
            query {
              id
              owner
              groups
              name
              nameLowercase
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              additionalFields {
                name
                value
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              feedTemplates {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  name
                  nameLowercase
                  root
                  default
                  conditions
                  queryId
                  iconUrl
                  publish
                  iconData
                  teamId
                  dataSourceId
                  parameters
                  __typename
                }
                nextToken
                __typename
              }
              queryTeamId
              iconUrl
              createdAt
              updatedAt
              dataSourceQueriesId
              __typename
            }
            integrationCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            integrationDataSourceId
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            createdAt
            updatedAt
            composerQueryIntegrationsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        dataFreshness
        filter {
          isActive
          dimensionsFilters {
            fieldName
            values
            type
            operator
            __typename
          }
          segment {
            id
            name
            __typename
          }
          __typename
        }
        version
        rateLimit {
          max
          interval
          __typename
        }
        dataSourceCredentialId
        credential {
          id
          name
          email
          picture
          status
          owners
          editors
          createdAt
          updatedAt
          credentialTeamId
          authType
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          dataProvider
          groups
          label {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          credentialLabelId
          authDataId
          clientId
          authData {
            accessToken
            refreshToken
            scope
            tokenType
            idToken
            expiryDate
            username
            password
            token
            key
            environment
            __typename
          }
          __typename
        }
        dataSourceTeamId
        team {
          id
          avatar {
            visibility
            identityId
            file {
              bucket
              key
              region
              fileName
              __typename
            }
            __typename
          }
          name
          owner
          members
          admins
          editors
          emailDomains
          autoInviteDomains
          teamMembers {
            items {
              id
              teamMembershipUserId
              user {
                id
                username
                avatar {
                  visibility
                  identityId
                  __typename
                }
                userType
                defaultTeamId
                userSettings {
                  onboardingCompleted
                  releaseNotes
                  __typename
                }
                teams {
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              teamMembershipTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              owner
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          credentials {
            items {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          dataSourceLabels {
            items {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultTemplateId
          workspaceType
          createdAt
          updatedAt
          __typename
        }
        labels {
          items {
            id
            dataSourceID
            labelID
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            dataSourceLabelDataSourcesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        groups
        __typename
      }
      limit
      fields
      sort {
        id
        sortDirection
        __typename
      }
      dimensionsFilters {
        orFilters {
          fieldName
          values
          type
          operator
          __typename
        }
        __typename
      }
      dateRangeType
      dateRange {
        startDate
        endDate
        __typename
      }
      schedule
      trigger
      notificationActionCredentialId
      createdAt
      updatedAt
      dataSourceNotificationActionsId
      __typename
    }
  }
`;
export const deleteNotificationAction = /* GraphQL */ `
  mutation DeleteNotificationAction(
    $input: DeleteNotificationActionInput!
    $condition: ModelNotificationActionConditionInput
  ) {
    deleteNotificationAction(input: $input, condition: $condition) {
      id
      owner
      groups
      name
      nameLowercase
      status
      notificationService
      template
      dataSource {
        id
        owner
        editors
        admins
        createdAt
        updatedAt
        dataProvider
        displayName
        displayNameLowercase
        configParamsHash
        name
        nameLowercase
        tags
        tagsLowercase
        tagsLowercaseToString
        url
        hostname
        domain
        type
        view {
          viewId
          viewName
          propertyId
          websiteId
          loginCustomerId
          customerId
          site
          locationId
          channelId
          spreadsheetId
          accountId
          projectId
          dc
          connectorId
          dataSourceIds
          credentialIds
          merchantId
          isManager
          timeZone
          reportType
          url
          device
          type
          dataSourceId
          credentialId
          __typename
        }
        multipleViews {
          viewId
          viewName
          propertyId
          websiteId
          loginCustomerId
          customerId
          site
          locationId
          channelId
          spreadsheetId
          accountId
          projectId
          dc
          connectorId
          dataSourceIds
          credentialIds
          merchantId
          isManager
          timeZone
          reportType
          url
          device
          type
          dataSourceId
          credentialId
          __typename
        }
        dataUrls {
          items {
            id
            owner
            groups
            name
            nameLowercase
            status
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            createdAt
            updatedAt
            dataSourceDataUrlsId
            __typename
          }
          nextToken
          __typename
        }
        queries {
          items {
            id
            owner
            groups
            name
            nameLowercase
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            additionalFields {
              name
              value
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            feedTemplates {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                name
                nameLowercase
                root
                default
                conditions
                queryId
                iconUrl
                publish
                iconData
                teamId
                dataSourceId
                parameters
                __typename
              }
              nextToken
              __typename
            }
            queryTeamId
            iconUrl
            createdAt
            updatedAt
            dataSourceQueriesId
            __typename
          }
          nextToken
          __typename
        }
        preCacheRequests {
          items {
            id
            name
            nameLowercase
            fields
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            requestCount
            query
            TTL
            updatedAt
            createdAt
            dataSourcePreCacheRequestsId
            __typename
          }
          nextToken
          __typename
        }
        notificationActions {
          items {
            id
            owner
            groups
            name
            nameLowercase
            status
            notificationService
            template
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            schedule
            trigger
            notificationActionCredentialId
            createdAt
            updatedAt
            dataSourceNotificationActionsId
            __typename
          }
          nextToken
          __typename
        }
        integrations {
          items {
            id
            type
            dataProvider
            additionalSettings {
              name
              value
              __typename
            }
            query {
              id
              owner
              groups
              name
              nameLowercase
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              additionalFields {
                name
                value
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              feedTemplates {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  name
                  nameLowercase
                  root
                  default
                  conditions
                  queryId
                  iconUrl
                  publish
                  iconData
                  teamId
                  dataSourceId
                  parameters
                  __typename
                }
                nextToken
                __typename
              }
              queryTeamId
              iconUrl
              createdAt
              updatedAt
              dataSourceQueriesId
              __typename
            }
            integrationCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            integrationDataSourceId
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            createdAt
            updatedAt
            composerQueryIntegrationsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        dataFreshness
        filter {
          isActive
          dimensionsFilters {
            fieldName
            values
            type
            operator
            __typename
          }
          segment {
            id
            name
            __typename
          }
          __typename
        }
        version
        rateLimit {
          max
          interval
          __typename
        }
        dataSourceCredentialId
        credential {
          id
          name
          email
          picture
          status
          owners
          editors
          createdAt
          updatedAt
          credentialTeamId
          authType
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          dataProvider
          groups
          label {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          credentialLabelId
          authDataId
          clientId
          authData {
            accessToken
            refreshToken
            scope
            tokenType
            idToken
            expiryDate
            username
            password
            token
            key
            environment
            __typename
          }
          __typename
        }
        dataSourceTeamId
        team {
          id
          avatar {
            visibility
            identityId
            file {
              bucket
              key
              region
              fileName
              __typename
            }
            __typename
          }
          name
          owner
          members
          admins
          editors
          emailDomains
          autoInviteDomains
          teamMembers {
            items {
              id
              teamMembershipUserId
              user {
                id
                username
                avatar {
                  visibility
                  identityId
                  __typename
                }
                userType
                defaultTeamId
                userSettings {
                  onboardingCompleted
                  releaseNotes
                  __typename
                }
                teams {
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              teamMembershipTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              owner
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          credentials {
            items {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          dataSourceLabels {
            items {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultTemplateId
          workspaceType
          createdAt
          updatedAt
          __typename
        }
        labels {
          items {
            id
            dataSourceID
            labelID
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            dataSourceLabelDataSourcesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        groups
        __typename
      }
      limit
      fields
      sort {
        id
        sortDirection
        __typename
      }
      dimensionsFilters {
        orFilters {
          fieldName
          values
          type
          operator
          __typename
        }
        __typename
      }
      dateRangeType
      dateRange {
        startDate
        endDate
        __typename
      }
      schedule
      trigger
      notificationActionCredentialId
      createdAt
      updatedAt
      dataSourceNotificationActionsId
      __typename
    }
  }
`;
export const createDataSource = /* GraphQL */ `
  mutation CreateDataSource(
    $input: CreateDataSourceInput!
    $condition: ModelDataSourceConditionInput
  ) {
    createDataSource(input: $input, condition: $condition) {
      id
      owner
      editors
      admins
      createdAt
      updatedAt
      dataProvider
      displayName
      displayNameLowercase
      configParamsHash
      name
      nameLowercase
      tags
      tagsLowercase
      tagsLowercaseToString
      url
      hostname
      domain
      type
      view {
        viewId
        viewName
        propertyId
        websiteId
        loginCustomerId
        customerId
        site
        locationId
        channelId
        spreadsheetId
        accountId
        projectId
        dc
        connectorId
        dataSourceIds
        credentialIds
        merchantId
        isManager
        timeZone
        reportType
        url
        device
        type
        dataSourceId
        credentialId
        __typename
      }
      multipleViews {
        viewId
        viewName
        propertyId
        websiteId
        loginCustomerId
        customerId
        site
        locationId
        channelId
        spreadsheetId
        accountId
        projectId
        dc
        connectorId
        dataSourceIds
        credentialIds
        merchantId
        isManager
        timeZone
        reportType
        url
        device
        type
        dataSourceId
        credentialId
        __typename
      }
      dataUrls {
        items {
          id
          owner
          groups
          name
          nameLowercase
          status
          dataSource {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          limit
          fields
          sort {
            id
            sortDirection
            __typename
          }
          dimensionsFilters {
            orFilters {
              fieldName
              values
              type
              operator
              __typename
            }
            __typename
          }
          dateRangeType
          dateRange {
            startDate
            endDate
            __typename
          }
          createdAt
          updatedAt
          dataSourceDataUrlsId
          __typename
        }
        nextToken
        __typename
      }
      queries {
        items {
          id
          owner
          groups
          name
          nameLowercase
          dataSource {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          limit
          fields
          sort {
            id
            sortDirection
            __typename
          }
          dimensionsFilters {
            orFilters {
              fieldName
              values
              type
              operator
              __typename
            }
            __typename
          }
          dateRangeType
          dateRange {
            startDate
            endDate
            __typename
          }
          additionalFields {
            name
            value
            __typename
          }
          integrations {
            items {
              id
              type
              dataProvider
              additionalSettings {
                name
                value
                __typename
              }
              query {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              integrationCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              integrationDataSourceId
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              createdAt
              updatedAt
              composerQueryIntegrationsId
              owner
              __typename
            }
            nextToken
            __typename
          }
          feedTemplates {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              name
              nameLowercase
              root
              default
              conditions
              queryId
              iconUrl
              publish
              iconData
              teamId
              dataSourceId
              parameters
              __typename
            }
            nextToken
            __typename
          }
          queryTeamId
          iconUrl
          createdAt
          updatedAt
          dataSourceQueriesId
          __typename
        }
        nextToken
        __typename
      }
      preCacheRequests {
        items {
          id
          name
          nameLowercase
          fields
          dimensionsFilters {
            orFilters {
              fieldName
              values
              type
              operator
              __typename
            }
            __typename
          }
          dateRangeType
          dateRange {
            startDate
            endDate
            __typename
          }
          requestCount
          query
          TTL
          updatedAt
          createdAt
          dataSourcePreCacheRequestsId
          __typename
        }
        nextToken
        __typename
      }
      notificationActions {
        items {
          id
          owner
          groups
          name
          nameLowercase
          status
          notificationService
          template
          dataSource {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          limit
          fields
          sort {
            id
            sortDirection
            __typename
          }
          dimensionsFilters {
            orFilters {
              fieldName
              values
              type
              operator
              __typename
            }
            __typename
          }
          dateRangeType
          dateRange {
            startDate
            endDate
            __typename
          }
          schedule
          trigger
          notificationActionCredentialId
          createdAt
          updatedAt
          dataSourceNotificationActionsId
          __typename
        }
        nextToken
        __typename
      }
      integrations {
        items {
          id
          type
          dataProvider
          additionalSettings {
            name
            value
            __typename
          }
          query {
            id
            owner
            groups
            name
            nameLowercase
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            additionalFields {
              name
              value
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            feedTemplates {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                name
                nameLowercase
                root
                default
                conditions
                queryId
                iconUrl
                publish
                iconData
                teamId
                dataSourceId
                parameters
                __typename
              }
              nextToken
              __typename
            }
            queryTeamId
            iconUrl
            createdAt
            updatedAt
            dataSourceQueriesId
            __typename
          }
          integrationCredentialId
          credential {
            id
            name
            email
            picture
            status
            owners
            editors
            createdAt
            updatedAt
            credentialTeamId
            authType
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            dataProvider
            groups
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            credentialLabelId
            authDataId
            clientId
            authData {
              accessToken
              refreshToken
              scope
              tokenType
              idToken
              expiryDate
              username
              password
              token
              key
              environment
              __typename
            }
            __typename
          }
          integrationDataSourceId
          dataSource {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          createdAt
          updatedAt
          composerQueryIntegrationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      dataFreshness
      filter {
        isActive
        dimensionsFilters {
          fieldName
          values
          type
          operator
          __typename
        }
        segment {
          id
          name
          __typename
        }
        __typename
      }
      version
      rateLimit {
        max
        interval
        __typename
      }
      dataSourceCredentialId
      credential {
        id
        name
        email
        picture
        status
        owners
        editors
        createdAt
        updatedAt
        credentialTeamId
        authType
        team {
          id
          avatar {
            visibility
            identityId
            file {
              bucket
              key
              region
              fileName
              __typename
            }
            __typename
          }
          name
          owner
          members
          admins
          editors
          emailDomains
          autoInviteDomains
          teamMembers {
            items {
              id
              teamMembershipUserId
              user {
                id
                username
                avatar {
                  visibility
                  identityId
                  __typename
                }
                userType
                defaultTeamId
                userSettings {
                  onboardingCompleted
                  releaseNotes
                  __typename
                }
                teams {
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              teamMembershipTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              owner
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          credentials {
            items {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          dataSourceLabels {
            items {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultTemplateId
          workspaceType
          createdAt
          updatedAt
          __typename
        }
        dataSources {
          items {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          nextToken
          __typename
        }
        dataProvider
        groups
        label {
          id
          name
          nameLowercase
          value
          type
          dataSourceLabelTeamId
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          createdAt
          dataProviders
          dataSources {
            items {
              id
              dataSourceID
              labelID
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              dataSourceLabelDataSourcesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        credentialLabelId
        authDataId
        clientId
        authData {
          accessToken
          refreshToken
          scope
          tokenType
          idToken
          expiryDate
          username
          password
          token
          key
          environment
          __typename
        }
        __typename
      }
      dataSourceTeamId
      team {
        id
        avatar {
          visibility
          identityId
          file {
            bucket
            key
            region
            fileName
            __typename
          }
          __typename
        }
        name
        owner
        members
        admins
        editors
        emailDomains
        autoInviteDomains
        teamMembers {
          items {
            id
            teamMembershipUserId
            user {
              id
              username
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              userType
              defaultTeamId
              userSettings {
                onboardingCompleted
                releaseNotes
                __typename
              }
              teams {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            teamMembershipTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            owner
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dataSources {
          items {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          nextToken
          __typename
        }
        credentials {
          items {
            id
            name
            email
            picture
            status
            owners
            editors
            createdAt
            updatedAt
            credentialTeamId
            authType
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            dataProvider
            groups
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            credentialLabelId
            authDataId
            clientId
            authData {
              accessToken
              refreshToken
              scope
              tokenType
              idToken
              expiryDate
              username
              password
              token
              key
              environment
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        dataSourceLabels {
          items {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        defaultTemplateId
        workspaceType
        createdAt
        updatedAt
        __typename
      }
      labels {
        items {
          id
          dataSourceID
          labelID
          dataSource {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          label {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          dataSourceLabelDataSourcesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      groups
      __typename
    }
  }
`;
export const updateDataSource = /* GraphQL */ `
  mutation UpdateDataSource(
    $input: UpdateDataSourceInput!
    $condition: ModelDataSourceConditionInput
  ) {
    updateDataSource(input: $input, condition: $condition) {
      id
      owner
      editors
      admins
      createdAt
      updatedAt
      dataProvider
      displayName
      displayNameLowercase
      configParamsHash
      name
      nameLowercase
      tags
      tagsLowercase
      tagsLowercaseToString
      url
      hostname
      domain
      type
      view {
        viewId
        viewName
        propertyId
        websiteId
        loginCustomerId
        customerId
        site
        locationId
        channelId
        spreadsheetId
        accountId
        projectId
        dc
        connectorId
        dataSourceIds
        credentialIds
        merchantId
        isManager
        timeZone
        reportType
        url
        device
        type
        dataSourceId
        credentialId
        __typename
      }
      multipleViews {
        viewId
        viewName
        propertyId
        websiteId
        loginCustomerId
        customerId
        site
        locationId
        channelId
        spreadsheetId
        accountId
        projectId
        dc
        connectorId
        dataSourceIds
        credentialIds
        merchantId
        isManager
        timeZone
        reportType
        url
        device
        type
        dataSourceId
        credentialId
        __typename
      }
      dataUrls {
        items {
          id
          owner
          groups
          name
          nameLowercase
          status
          dataSource {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          limit
          fields
          sort {
            id
            sortDirection
            __typename
          }
          dimensionsFilters {
            orFilters {
              fieldName
              values
              type
              operator
              __typename
            }
            __typename
          }
          dateRangeType
          dateRange {
            startDate
            endDate
            __typename
          }
          createdAt
          updatedAt
          dataSourceDataUrlsId
          __typename
        }
        nextToken
        __typename
      }
      queries {
        items {
          id
          owner
          groups
          name
          nameLowercase
          dataSource {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          limit
          fields
          sort {
            id
            sortDirection
            __typename
          }
          dimensionsFilters {
            orFilters {
              fieldName
              values
              type
              operator
              __typename
            }
            __typename
          }
          dateRangeType
          dateRange {
            startDate
            endDate
            __typename
          }
          additionalFields {
            name
            value
            __typename
          }
          integrations {
            items {
              id
              type
              dataProvider
              additionalSettings {
                name
                value
                __typename
              }
              query {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              integrationCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              integrationDataSourceId
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              createdAt
              updatedAt
              composerQueryIntegrationsId
              owner
              __typename
            }
            nextToken
            __typename
          }
          feedTemplates {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              name
              nameLowercase
              root
              default
              conditions
              queryId
              iconUrl
              publish
              iconData
              teamId
              dataSourceId
              parameters
              __typename
            }
            nextToken
            __typename
          }
          queryTeamId
          iconUrl
          createdAt
          updatedAt
          dataSourceQueriesId
          __typename
        }
        nextToken
        __typename
      }
      preCacheRequests {
        items {
          id
          name
          nameLowercase
          fields
          dimensionsFilters {
            orFilters {
              fieldName
              values
              type
              operator
              __typename
            }
            __typename
          }
          dateRangeType
          dateRange {
            startDate
            endDate
            __typename
          }
          requestCount
          query
          TTL
          updatedAt
          createdAt
          dataSourcePreCacheRequestsId
          __typename
        }
        nextToken
        __typename
      }
      notificationActions {
        items {
          id
          owner
          groups
          name
          nameLowercase
          status
          notificationService
          template
          dataSource {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          limit
          fields
          sort {
            id
            sortDirection
            __typename
          }
          dimensionsFilters {
            orFilters {
              fieldName
              values
              type
              operator
              __typename
            }
            __typename
          }
          dateRangeType
          dateRange {
            startDate
            endDate
            __typename
          }
          schedule
          trigger
          notificationActionCredentialId
          createdAt
          updatedAt
          dataSourceNotificationActionsId
          __typename
        }
        nextToken
        __typename
      }
      integrations {
        items {
          id
          type
          dataProvider
          additionalSettings {
            name
            value
            __typename
          }
          query {
            id
            owner
            groups
            name
            nameLowercase
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            additionalFields {
              name
              value
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            feedTemplates {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                name
                nameLowercase
                root
                default
                conditions
                queryId
                iconUrl
                publish
                iconData
                teamId
                dataSourceId
                parameters
                __typename
              }
              nextToken
              __typename
            }
            queryTeamId
            iconUrl
            createdAt
            updatedAt
            dataSourceQueriesId
            __typename
          }
          integrationCredentialId
          credential {
            id
            name
            email
            picture
            status
            owners
            editors
            createdAt
            updatedAt
            credentialTeamId
            authType
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            dataProvider
            groups
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            credentialLabelId
            authDataId
            clientId
            authData {
              accessToken
              refreshToken
              scope
              tokenType
              idToken
              expiryDate
              username
              password
              token
              key
              environment
              __typename
            }
            __typename
          }
          integrationDataSourceId
          dataSource {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          createdAt
          updatedAt
          composerQueryIntegrationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      dataFreshness
      filter {
        isActive
        dimensionsFilters {
          fieldName
          values
          type
          operator
          __typename
        }
        segment {
          id
          name
          __typename
        }
        __typename
      }
      version
      rateLimit {
        max
        interval
        __typename
      }
      dataSourceCredentialId
      credential {
        id
        name
        email
        picture
        status
        owners
        editors
        createdAt
        updatedAt
        credentialTeamId
        authType
        team {
          id
          avatar {
            visibility
            identityId
            file {
              bucket
              key
              region
              fileName
              __typename
            }
            __typename
          }
          name
          owner
          members
          admins
          editors
          emailDomains
          autoInviteDomains
          teamMembers {
            items {
              id
              teamMembershipUserId
              user {
                id
                username
                avatar {
                  visibility
                  identityId
                  __typename
                }
                userType
                defaultTeamId
                userSettings {
                  onboardingCompleted
                  releaseNotes
                  __typename
                }
                teams {
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              teamMembershipTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              owner
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          credentials {
            items {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          dataSourceLabels {
            items {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultTemplateId
          workspaceType
          createdAt
          updatedAt
          __typename
        }
        dataSources {
          items {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          nextToken
          __typename
        }
        dataProvider
        groups
        label {
          id
          name
          nameLowercase
          value
          type
          dataSourceLabelTeamId
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          createdAt
          dataProviders
          dataSources {
            items {
              id
              dataSourceID
              labelID
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              dataSourceLabelDataSourcesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        credentialLabelId
        authDataId
        clientId
        authData {
          accessToken
          refreshToken
          scope
          tokenType
          idToken
          expiryDate
          username
          password
          token
          key
          environment
          __typename
        }
        __typename
      }
      dataSourceTeamId
      team {
        id
        avatar {
          visibility
          identityId
          file {
            bucket
            key
            region
            fileName
            __typename
          }
          __typename
        }
        name
        owner
        members
        admins
        editors
        emailDomains
        autoInviteDomains
        teamMembers {
          items {
            id
            teamMembershipUserId
            user {
              id
              username
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              userType
              defaultTeamId
              userSettings {
                onboardingCompleted
                releaseNotes
                __typename
              }
              teams {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            teamMembershipTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            owner
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dataSources {
          items {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          nextToken
          __typename
        }
        credentials {
          items {
            id
            name
            email
            picture
            status
            owners
            editors
            createdAt
            updatedAt
            credentialTeamId
            authType
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            dataProvider
            groups
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            credentialLabelId
            authDataId
            clientId
            authData {
              accessToken
              refreshToken
              scope
              tokenType
              idToken
              expiryDate
              username
              password
              token
              key
              environment
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        dataSourceLabels {
          items {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        defaultTemplateId
        workspaceType
        createdAt
        updatedAt
        __typename
      }
      labels {
        items {
          id
          dataSourceID
          labelID
          dataSource {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          label {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          dataSourceLabelDataSourcesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      groups
      __typename
    }
  }
`;
export const deleteDataSource = /* GraphQL */ `
  mutation DeleteDataSource(
    $input: DeleteDataSourceInput!
    $condition: ModelDataSourceConditionInput
  ) {
    deleteDataSource(input: $input, condition: $condition) {
      id
      owner
      editors
      admins
      createdAt
      updatedAt
      dataProvider
      displayName
      displayNameLowercase
      configParamsHash
      name
      nameLowercase
      tags
      tagsLowercase
      tagsLowercaseToString
      url
      hostname
      domain
      type
      view {
        viewId
        viewName
        propertyId
        websiteId
        loginCustomerId
        customerId
        site
        locationId
        channelId
        spreadsheetId
        accountId
        projectId
        dc
        connectorId
        dataSourceIds
        credentialIds
        merchantId
        isManager
        timeZone
        reportType
        url
        device
        type
        dataSourceId
        credentialId
        __typename
      }
      multipleViews {
        viewId
        viewName
        propertyId
        websiteId
        loginCustomerId
        customerId
        site
        locationId
        channelId
        spreadsheetId
        accountId
        projectId
        dc
        connectorId
        dataSourceIds
        credentialIds
        merchantId
        isManager
        timeZone
        reportType
        url
        device
        type
        dataSourceId
        credentialId
        __typename
      }
      dataUrls {
        items {
          id
          owner
          groups
          name
          nameLowercase
          status
          dataSource {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          limit
          fields
          sort {
            id
            sortDirection
            __typename
          }
          dimensionsFilters {
            orFilters {
              fieldName
              values
              type
              operator
              __typename
            }
            __typename
          }
          dateRangeType
          dateRange {
            startDate
            endDate
            __typename
          }
          createdAt
          updatedAt
          dataSourceDataUrlsId
          __typename
        }
        nextToken
        __typename
      }
      queries {
        items {
          id
          owner
          groups
          name
          nameLowercase
          dataSource {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          limit
          fields
          sort {
            id
            sortDirection
            __typename
          }
          dimensionsFilters {
            orFilters {
              fieldName
              values
              type
              operator
              __typename
            }
            __typename
          }
          dateRangeType
          dateRange {
            startDate
            endDate
            __typename
          }
          additionalFields {
            name
            value
            __typename
          }
          integrations {
            items {
              id
              type
              dataProvider
              additionalSettings {
                name
                value
                __typename
              }
              query {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              integrationCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              integrationDataSourceId
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              createdAt
              updatedAt
              composerQueryIntegrationsId
              owner
              __typename
            }
            nextToken
            __typename
          }
          feedTemplates {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              name
              nameLowercase
              root
              default
              conditions
              queryId
              iconUrl
              publish
              iconData
              teamId
              dataSourceId
              parameters
              __typename
            }
            nextToken
            __typename
          }
          queryTeamId
          iconUrl
          createdAt
          updatedAt
          dataSourceQueriesId
          __typename
        }
        nextToken
        __typename
      }
      preCacheRequests {
        items {
          id
          name
          nameLowercase
          fields
          dimensionsFilters {
            orFilters {
              fieldName
              values
              type
              operator
              __typename
            }
            __typename
          }
          dateRangeType
          dateRange {
            startDate
            endDate
            __typename
          }
          requestCount
          query
          TTL
          updatedAt
          createdAt
          dataSourcePreCacheRequestsId
          __typename
        }
        nextToken
        __typename
      }
      notificationActions {
        items {
          id
          owner
          groups
          name
          nameLowercase
          status
          notificationService
          template
          dataSource {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          limit
          fields
          sort {
            id
            sortDirection
            __typename
          }
          dimensionsFilters {
            orFilters {
              fieldName
              values
              type
              operator
              __typename
            }
            __typename
          }
          dateRangeType
          dateRange {
            startDate
            endDate
            __typename
          }
          schedule
          trigger
          notificationActionCredentialId
          createdAt
          updatedAt
          dataSourceNotificationActionsId
          __typename
        }
        nextToken
        __typename
      }
      integrations {
        items {
          id
          type
          dataProvider
          additionalSettings {
            name
            value
            __typename
          }
          query {
            id
            owner
            groups
            name
            nameLowercase
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            additionalFields {
              name
              value
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            feedTemplates {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                name
                nameLowercase
                root
                default
                conditions
                queryId
                iconUrl
                publish
                iconData
                teamId
                dataSourceId
                parameters
                __typename
              }
              nextToken
              __typename
            }
            queryTeamId
            iconUrl
            createdAt
            updatedAt
            dataSourceQueriesId
            __typename
          }
          integrationCredentialId
          credential {
            id
            name
            email
            picture
            status
            owners
            editors
            createdAt
            updatedAt
            credentialTeamId
            authType
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            dataProvider
            groups
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            credentialLabelId
            authDataId
            clientId
            authData {
              accessToken
              refreshToken
              scope
              tokenType
              idToken
              expiryDate
              username
              password
              token
              key
              environment
              __typename
            }
            __typename
          }
          integrationDataSourceId
          dataSource {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          createdAt
          updatedAt
          composerQueryIntegrationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      dataFreshness
      filter {
        isActive
        dimensionsFilters {
          fieldName
          values
          type
          operator
          __typename
        }
        segment {
          id
          name
          __typename
        }
        __typename
      }
      version
      rateLimit {
        max
        interval
        __typename
      }
      dataSourceCredentialId
      credential {
        id
        name
        email
        picture
        status
        owners
        editors
        createdAt
        updatedAt
        credentialTeamId
        authType
        team {
          id
          avatar {
            visibility
            identityId
            file {
              bucket
              key
              region
              fileName
              __typename
            }
            __typename
          }
          name
          owner
          members
          admins
          editors
          emailDomains
          autoInviteDomains
          teamMembers {
            items {
              id
              teamMembershipUserId
              user {
                id
                username
                avatar {
                  visibility
                  identityId
                  __typename
                }
                userType
                defaultTeamId
                userSettings {
                  onboardingCompleted
                  releaseNotes
                  __typename
                }
                teams {
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              teamMembershipTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              owner
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          credentials {
            items {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          dataSourceLabels {
            items {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultTemplateId
          workspaceType
          createdAt
          updatedAt
          __typename
        }
        dataSources {
          items {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          nextToken
          __typename
        }
        dataProvider
        groups
        label {
          id
          name
          nameLowercase
          value
          type
          dataSourceLabelTeamId
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          createdAt
          dataProviders
          dataSources {
            items {
              id
              dataSourceID
              labelID
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              dataSourceLabelDataSourcesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        credentialLabelId
        authDataId
        clientId
        authData {
          accessToken
          refreshToken
          scope
          tokenType
          idToken
          expiryDate
          username
          password
          token
          key
          environment
          __typename
        }
        __typename
      }
      dataSourceTeamId
      team {
        id
        avatar {
          visibility
          identityId
          file {
            bucket
            key
            region
            fileName
            __typename
          }
          __typename
        }
        name
        owner
        members
        admins
        editors
        emailDomains
        autoInviteDomains
        teamMembers {
          items {
            id
            teamMembershipUserId
            user {
              id
              username
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              userType
              defaultTeamId
              userSettings {
                onboardingCompleted
                releaseNotes
                __typename
              }
              teams {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            teamMembershipTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            owner
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dataSources {
          items {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          nextToken
          __typename
        }
        credentials {
          items {
            id
            name
            email
            picture
            status
            owners
            editors
            createdAt
            updatedAt
            credentialTeamId
            authType
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            dataProvider
            groups
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            credentialLabelId
            authDataId
            clientId
            authData {
              accessToken
              refreshToken
              scope
              tokenType
              idToken
              expiryDate
              username
              password
              token
              key
              environment
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        dataSourceLabels {
          items {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        defaultTemplateId
        workspaceType
        createdAt
        updatedAt
        __typename
      }
      labels {
        items {
          id
          dataSourceID
          labelID
          dataSource {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                feedTemplates {
                  nextToken
                  __typename
                }
                queryTeamId
                iconUrl
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  iconUrl
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          label {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          dataSourceLabelDataSourcesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      groups
      __typename
    }
  }
`;
