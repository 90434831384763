/* eslint-disable camelcase */
import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import common_en from './translations/en/common.json';
import common_nl from './translations/nl/common.json';

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    interpolation: { escapeValue: false }, // React already does escaping
    fallbackLng: 'en',

    resources: {
      nl: {
        common: common_nl,
      },
      en: {
        common: common_en,
      },
    },
  });

export default i18n;
export const locale = i18n.language;
