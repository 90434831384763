import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { API, Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

async function getTemplateFeeds(dataProviderId) {
  return API.get('BackendApi', `/template-feeds/${dataProviderId}`);
}

export default function CreateJobFeedDialog({ open, setOpen }) {
  const selectedDataSource = useSelector(
    (reduxState) => reduxState.selectedDataSource
  );
  const { t } = useTranslation('common');
  const [templateFeeds, setTemplateFeeds] = useState(null);
  const [selectedTemplateFeed, setSelectedTemplateFeed] = useState(null);
  const selectedFeed = templateFeeds?.find(
    (f) => f.id === selectedTemplateFeed
  );
  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState('feed');
  useEffect(() => {
    async function fetchTemplateFeeds() {
      if (selectedDataSource?.dataProvider) {
        const feeds = await getTemplateFeeds(selectedDataSource.dataProvider);
        setTemplateFeeds(feeds);
      } else {
        setTemplateFeeds(null);
      }
    }
    fetchTemplateFeeds();
  }, [selectedDataSource?.dataProvider]);

  async function createTemplateQuery(templateId, dataSourceId, parameters) {
    const tokens = await Auth.currentSession();
    return API.post('BackendApi', `/copy-template-feed`, {
      headers: {
        Authorization: `${tokens.getIdToken().getJwtToken()}`,
      },
      body: {
        dataSourceId,
        templateId,
        parameters,
      },
      response: true,
    });
  }
  return (
    <Dialog
      onClose={() => {
        setOpen(false);
      }}
      open={open}
    >
      <DialogTitle>{t('connectJobBoard')}</DialogTitle>
      <DialogContent
        sx={{
          minWidth: '600px',
        }}
      >
        {page === 'feed' && (
          <>
            {!templateFeeds && <CircularProgress></CircularProgress>}
            {templateFeeds && templateFeeds.length > 0 && (
              <>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="subtitle2"
                >
                  Job boards
                </Typography>
                <Select
                  displayEmpty
                  fullWidth
                  onChange={(event) => {
                    setSelectedTemplateFeed(event.target.value);
                  }}
                  placeholder="Selecteer een job board"
                  renderValue={(value) => {
                    if (!value) {
                      return (
                        <Typography sx={{ opacity: '0.85' }}>
                          {t('selectJobBoard')}
                        </Typography>
                      );
                    }
                    const feed = templateFeeds.find((f) => f.id === value);
                    return (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {feed.iconUrl && (
                          <img
                            alt="icon"
                            src={feed.iconUrl}
                            style={{
                              width: '40px',
                              height: '40px',
                              objectFit: 'contain',
                              marginRight: '10px',
                            }}
                          ></img>
                        )}
                        {feed.name}
                      </Box>
                    );
                  }}
                >
                  {templateFeeds?.map((feed) => (
                    <MenuItem key={feed.id} value={feed.id}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {feed.iconUrl && (
                          <img
                            alt="icon"
                            src={feed.iconUrl}
                            style={{
                              width: '40px',
                              height: '40px',
                              objectFit: 'contain',
                              marginRight: '10px',
                            }}
                          ></img>
                        )}
                        {feed.name}
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
            {templateFeeds && templateFeeds.length === 0 && (
              <Typography>{t('noJobBoards')}</Typography>
            )}
          </>
        )}
        {page === 'parameters' && (
          <>
            <Typography color="textSecondary" gutterBottom variant="subtitle2">
              {t('additionalInformation')} - {selectedFeed.name}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              {selectedFeed.parameters.map((v) => (
                <TextField
                  key={v.id}
                  fullWidth
                  label={v.name}
                  onChange={(event) => {
                    const val = v;
                    val.value = event.target.value;
                    setTemplateFeeds((tv) => {
                      const newTv = [...tv];
                      const feed = newTv.find(
                        (f) => f.id === selectedTemplateFeed
                      );
                      const param = feed.parameters.find((p) => p.id === v.id);
                      param.value = event.target.value;
                      return newTv;
                    });
                  }}
                  sx={{
                    my: 1,
                  }}
                  value={v.value}
                ></TextField>
              ))}
              {selectedFeed.parameters.length === 0 && (
                <Typography>{t('noParameters')}</Typography>
              )}
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setSelectedTemplateFeed(null);
            if (page === 'feed') {
              setOpen(false);
            } else {
              setPage('feed');
            }
          }}
        >
          {page === 'feed' ? t('Cancel') : t('Back')}
        </Button>
        <LoadingButton
          color="info"
          disabled={!selectedTemplateFeed}
          loading={isLoading}
          onClick={() => {
            if (page === 'feed') {
              setPage('parameters');
            } else {
              setLoading(true);
              createTemplateQuery(
                selectedTemplateFeed,
                selectedDataSource.id,
                selectedFeed.parameters
              )
                .then(() => {
                  setLoading(false);
                  setOpen(false);
                })
                .catch(() => {
                  setLoading(false);
                });
            }
          }}
          variant="contained"
        >
          {page === 'feed' ? t('Next') : t('Create')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
