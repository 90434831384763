import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import getUserObject from '../api/getUserObject';
import * as types from '../constants/ActionTypes';

function Root() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const currentAUser = useSelector((state) => state.currentAuthenticatedUser);

  useEffect(() => {
    async function getCurrentAuthenticatedUser() {
      try {
        const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();

        if (currentAuthenticatedUser) {
          setIsLoggedIn(true);
        }
      } catch (e) {
        setIsLoggedIn(false);
        navigate('/auth');
      }
    }

    getCurrentAuthenticatedUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchGraphQL() {
      const { userObject, currentAuthenticatedUser: currentAuthUser } =
        await getUserObject();
      batch(() => {
        dispatch({
          type: types.SET_CURRENT_AUTHENTICATED_USER,
          currentAuthenticatedUser: currentAuthUser,
        });
        dispatch({
          type: types.SET_USER_OBJECT,
          userObject,
        });
      });
    }
    if (isLoggedIn) {
      fetchGraphQL();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);
  if (!isLoggedIn || currentAUser.username === undefined) {
    return <></>;
  }

  return <Outlet />;
}

export default Root;
