import { Add, Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { API, Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useSWRMutation from 'swr/mutation';
import DraggableDrawer from '../../../feedTemplates/DraggableDrawer';
import MessageEditor from '../textEditor/messageEditor';
import FeedUploadAvatarDialog from './FeedUploadAvatarDialog';
import FilterBox from './FilterBox';

async function sendRequest(url, { arg }) {
  const tokens = await Auth.currentSession();
  return API.post('BackendApi', url, {
    body: {
      ...arg,
    },
    headers: {
      Authorization: `${tokens.getIdToken().getJwtToken()}`,
    },
    response: true,
  });
}

export default function FeedEditor({
  open,
  close,
  queryObj,
  initialState,
  dataSourceId,
}) {
  const [conditions, setConditions] = useState([]);
  const [root, setRoot] = useState(null);
  const [def, setDefault] = useState(null);
  const [dbValue, setDbValue] = useState(null);
  const [initialConditions, setInitialConditions] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [name, setName] = useState([]);
  const [publish, setPublish] = useState(false);
  const [iconUrl, setIconUrl] = useState(null);
  const [iconData, setIconData] = useState(null);
  const [parameters, setParameters] = useState([]);
  const { trigger, isMutating } = useSWRMutation('/feed-template', sendRequest);

  const currentAuthenticatedUser = useSelector(
    (state) => state.currentAuthenticatedUser
  );

  const groups =
    currentAuthenticatedUser.signInUserSession.accessToken.payload[
      'cognito:groups'
    ];
  function addCondition() {
    setConditions([
      ...conditions,
      { id: Math.random().toString(36).substring(7) },
    ]);
  }

  useEffect(() => {
    const val = {
      root: JSON.stringify(root),
      conditions: JSON.stringify(
        conditions.map((c) => {
          const newC = { ...c };
          delete newC.id;
          return newC;
        })
      ),
      default: JSON.stringify(def),
      queryId: queryObj.id,
      name,
      id: initialState?.id,
      publish,
      iconUrl,
      iconData,
      parameters: JSON.stringify(parameters),
      dataSourceId,
    };
    setDbValue(val);
  }, [conditions, root, def, name, iconData, publish, iconUrl, parameters]);

  useEffect(() => {
    if (initialState) {
      const ic = JSON.parse(initialState.conditions).map((c) => ({
        ...c,
        id: Math.random().toString(36).substring(7),
      }));
      setConditions(JSON.parse(JSON.stringify(ic)));
      setInitialConditions(ic);

      setRoot(JSON.parse(initialState.root));
      setDefault(JSON.parse(initialState.default));
      setName(initialState.name);
      setIconUrl(initialState.iconUrl);
      setIconData(initialState.iconData);
      setPublish(initialState.publish);
      setParameters(JSON.parse(initialState?.parameters ?? '[]'));
    } else {
      setConditions([]);
      setInitialConditions([]);
      setRoot(null);
      setDefault(null);
      setName('');
      setIconUrl(null);
      setIconData(null);
      setPublish(false);
      setParameters([]);
    }
  }, [JSON.stringify(initialState)]);

  async function save() {
    await trigger(dbValue);
    close();
  }

  return (
    <DraggableDrawer close={close} open={open}>
      {openDialog === true && (
        <FeedUploadAvatarDialog
          closeDialog={() => {
            setOpenDialog(false);
          }}
          handleClose={() => {
            setOpenDialog(false);
          }}
          open={openDialog}
          selectedFeed={dbValue}
          setImageData={(data) => {
            setIconData(data);
            setIconUrl(data.url);
          }}
        />
      )}
      <Box
        sx={{
          maxWidth: '1000px',
          minWidth: 'fit-content',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="h6">Feed editor</Typography>
          <TextField
            label="Feed name"
            onChange={(e) => setName(e.target.value)}
            placeholder="Feed name"
            sx={{
              my: 2,
            }}
            value={name}
          ></TextField>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
          >
            {iconUrl && (
              <Avatar
                sx={{ width: 76, height: 76, backgroundColor: 'transparent' }}
              >
                <img
                  alt="icon"
                  src={iconUrl}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                ></img>
              </Avatar>
            )}
            <Button
              onClick={() => {
                setOpenDialog(true);
              }}
              variant="outlined"
            >
              Upload new icon
            </Button>
          </Box>
          <Typography
            color="textSecondary"
            gutterBottom
            sx={{
              mt: 1,
            }}
            variant="subtitle2"
          >
            Parameters
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            {parameters.map((parameter, i) => (
              <Box>
                <Box
                  key={parameter.id}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 1,
                    my: 1,
                  }}
                >
                  <TextField
                    fullWidth
                    label="Parameter name"
                    onChange={(e) => {
                      setParameters((p) => {
                        const newP = [...p];
                        newP[i].name = e.target.value;
                        return newP;
                      });
                    }}
                    value={parameter.name}
                  ></TextField>

                  <IconButton
                    onClick={() => {
                      setParameters(
                        parameters.filter((__, index) => index !== i)
                      );
                    }}
                  >
                    <Delete color="error"></Delete>
                  </IconButton>
                </Box>
                <TextField
                  fullWidth
                  label="Parameter value"
                  onChange={(e) => {
                    setParameters((p) => {
                      const newP = [...p];
                      newP[i].value = e.target.value;
                      return newP;
                    });
                  }}
                  value={parameter.value}
                ></TextField>
              </Box>
            ))}
          </Box>
          <Button
            color="secondary"
            onClick={() => {
              setParameters([
                ...parameters,
                {
                  id: Math.random().toString(36).substring(7),
                  name: '',
                  conceptType: 'PARAMETER',
                },
              ]);
            }}
            startIcon={<Add></Add>}
            sx={{
              width: 'fit-content',
              my: 1,
            }}
            variant="outlined"
          >
            Add parameter
          </Button>
          <Typography
            color="textSecondary"
            gutterBottom
            sx={{
              mb: -1,
            }}
            variant="subtitle2"
          >
            Root
          </Typography>
          <MessageEditor
            fields={[
              {
                id: 'text',
                name: 'Inner elements',
                conceptType: 'DIMENSION',
                type: 'TEXT',
                group: 'default',
                description: 'Text',
              },
              ...parameters.map((v) => ({
                ...v,
                conceptType: 'PARAMETER',
              })),
            ]}
            initialMessage={JSON.stringify(root)}
            lines={3}
            onChange={(value) => {
              setRoot(value);
            }}
            small={true}
          ></MessageEditor>
          <Typography
            color="textSecondary"
            gutterBottom
            sx={{
              mb: -1,
            }}
            variant="subtitle2"
          >
            Nodes
          </Typography>
          <Box
            sx={{
              ml: 1,
            }}
          >
            {conditions.map((condition, i) => (
              <Box key={condition.id}>
                <br></br>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    width: '100%',
                  }}
                >
                  <FilterBox
                    fields={[
                      ...queryObj.selectedDimensions,
                      ...queryObj.selectedMetrics,
                    ]}
                    initialValue={initialConditions?.[i]?.filter}
                    onChange={(value) => {
                      setConditions((cond) => {
                        const newCond = [...cond];
                        if (!newCond[i]) {
                          return cond;
                        }
                        newCond[i].filter = value;
                        return newCond;
                      });
                    }}
                  ></FilterBox>
                  <IconButton
                    onClick={() => {
                      setConditions(
                        conditions.filter((__, index) => index !== i)
                      );
                    }}
                    sx={{
                      mt: '4px',
                      ml: '4px',
                    }}
                  >
                    <Delete color="error"></Delete>
                  </IconButton>
                </Box>

                <MessageEditor
                  fields={[
                    ...queryObj.selectedDimensions,
                    ...queryObj.selectedMetrics,
                    ...parameters.map((v) => ({
                      ...v,
                      conceptType: 'PARAMETER',
                    })),
                  ]}
                  initialMessage={condition.value}
                  key={i}
                  lines={5}
                  onChange={(value) => {
                    setConditions((cond) => {
                      const newCond = [...cond];
                      newCond[i].value = value;
                      return newCond;
                    });
                  }}
                  small={true}
                ></MessageEditor>
              </Box>
            ))}
            <Button
              color="secondary"
              onClick={addCondition}
              startIcon={<Add></Add>}
              sx={{
                width: 'fit-content',
                my: 2,
              }}
              variant="outlined"
            >
              Add condition
            </Button>
            <Typography
              color="textSecondary"
              gutterBottom
              sx={{
                mb: -1,
              }}
              variant="subtitle2"
            >
              Default
            </Typography>

            <MessageEditor
              fields={[
                ...queryObj.selectedDimensions,
                ...queryObj.selectedMetrics,
                ...parameters.map((v) => ({
                  ...v,
                  conceptType: 'PARAMETER',
                })),
              ]}
              initialMessage={JSON.stringify(def)}
              lines={5}
              onChange={(value) => {
                setDefault(value);
              }}
              small={true}
            ></MessageEditor>
          </Box>
          {groups.includes('gladior') && (
            <Box>
              Publish
              <Switch
                checked={publish}
                color="secondary"
                onChange={(event) => {
                  setPublish(event.target.checked);
                }}
              ></Switch>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            pb: 2,
          }}
        >
          <LoadingButton
            color="info"
            loading={isMutating}
            onClick={save}
            sx={{
              mr: 1,
            }}
            variant="contained"
          >
            {initialState ? 'Update' : 'Create'}
          </LoadingButton>
          <Button onClick={close} variant="text">
            Cancel
          </Button>
        </Box>
      </Box>
    </DraggableDrawer>
  );
}
